import { remindMeScreenTranslations } from '../../translation/commonTranslations';

const MONTH_LIST = [
  { id: '01', itemName: remindMeScreenTranslations.january },
  { id: '02', itemName: remindMeScreenTranslations.february },
  { id: '03', itemName: remindMeScreenTranslations.march },
  { id: '04', itemName: remindMeScreenTranslations.april },
  { id: '05', itemName: remindMeScreenTranslations.may },
  { id: '06', itemName: remindMeScreenTranslations.june },
  { id: '07', itemName: remindMeScreenTranslations.july },
  { id: '08', itemName: remindMeScreenTranslations.august },
  { id: '09', itemName: remindMeScreenTranslations.september },
  { id: '10', itemName: remindMeScreenTranslations.october },
  { id: '11', itemName: remindMeScreenTranslations.november },
  { id: '12', itemName: remindMeScreenTranslations.december }
];

export default MONTH_LIST;
