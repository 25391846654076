import React from 'react';

import PropTypes from 'prop-types';

import { remindMeScreenTranslations } from '../../translation/commonTranslations';
import Select from '../select/select';

import MONTH_LIST from './constants';

import './styles.scss';

const DateSelector = (props) => {
  const { selectedDate, onListItemClick } = props;

  const { year = {}, date = {}, month = {} } = selectedDate || {};
  const today = new Date();
  const currentYear = today.getFullYear();
  const yearsList = Array.from(Array(100), (x, index) => ({
    id: index,
    itemName: currentYear - index
  }));

  const numberOfDates = new Date(
    year && Object.keys(year).length !== 0 && year.itemName,
    month && Object.keys(month).length !== 0 && month.id,
    0
  ).getDate();

  const formatSingleDigits = (digit) => {
    const number = digit && digit.toString();
    if (digit) {
      return number.padStart(2, 0);
    }
    return number;
  };

  const dateList = Array.from(Array(numberOfDates), (x, index) => ({
    id: index,
    itemName: formatSingleDigits(index + 1)
  }));

  return (
    <div className="date-selector-wrapper">
      <div className="date-selector-select">
        <Select
          selectOptions={dateList}
          isSelectNewUI={true}
          imageSrcDownChevron={`${process.env.PUBLIC_URL}/images/drop-down-arrow-thin.svg`}
          defaultValue={remindMeScreenTranslations.date}
          selectedItem={date}
          onListItemClick={(item) => onListItemClick(item, 'date')}
        />
      </div>
      <div className="date-selector-select">
        <Select
          selectOptions={MONTH_LIST}
          isSelectNewUI={true}
          imageSrcDownChevron={`${process.env.PUBLIC_URL}/images/drop-down-arrow-thin.svg`}
          defaultValue={remindMeScreenTranslations.month}
          selectedItem={month}
          onListItemClick={(item) => onListItemClick(item, 'month')}
        />
      </div>
      <div className="date-selector-select">
        <Select
          selectOptions={yearsList}
          isSelectNewUI={true}
          imageSrcDownChevron={`${process.env.PUBLIC_URL}/images/drop-down-arrow-thin.svg`}
          defaultValue={remindMeScreenTranslations.year}
          selectedItem={year}
          onListItemClick={(item) => onListItemClick(item, 'year')}
        />
      </div>
    </div>
  );
};

DateSelector.propTypes = {
  onListItemClick: PropTypes.func.isRequired,
  selectedDate: PropTypes.shape({
    year: PropTypes.shape({
      itemName: PropTypes.number
    }),
    month: PropTypes.shape({
      id: PropTypes.string
    }),
    date: PropTypes.shape({})
  })
};

DateSelector.defaultProps = {
  selectedDate: {
    year: {},
    month: {},
    date: {}
  }
};

export default DateSelector;
