/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import history from '../history';
import { store } from '../store';
import { getLocale } from '../utils';
import { DotLoader } from '../components';

const DefaultLayout = (props) => {
  const countryList = ['vn', 'hk', 'id', 'my', 'sg', 'th', 'tw', 'mo', 'jp', 'kr'];
  const [isCountryCode, setIsCountryCode] = useState(false);
  const [url, setUrl] = useState({});
  const getCountry = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const { data } = response;
        const country = data.country_code.toLowerCase();
        const isValid = countryList.indexOf(country);
        if (isValid === -1) {
          store.dispatch({
            type: 'SET:COUNTRY_CODE',
            data: {
              countryCode: 'sg',
              languageCode: getLocale('sg')
            }
          });
          setUrl({
            countryCode: 'sg',
            languageCode: getLocale('sg')
          });
          setIsCountryCode(true);
          return;
        }
        store.dispatch({
          type: 'SET:COUNTRY_CODE',
          data: {
            countryCode: country,
            languageCode: getLocale(country)
          }
        });
        setUrl({
          countryCode: country,
          languageCode: getLocale(country)
        });
        setIsCountryCode(true);
      })
      .catch(() => {});
  };

  useEffect(() => {
    const { countryCode, languageCode } = url;
    if (
      history.location.pathname === '/'
      || history.location.pathname === '/obt'
    ) {
      getCountry();
      countryCode
        && languageCode
        && props.history.push(`/${countryCode}-${languageCode}/launch`);
    }
  }, [isCountryCode]);

  return (
    <>
      <DotLoader isLoading={true} />
    </>
  );
};

const mapStateToProps = (state) => ({
  status: state.app.metaData.status
});

const mapDispatchToProps = () => ({});

DefaultLayout.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
