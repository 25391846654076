import React, { useEffect, Suspense } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import registry from 'app-registry';
import { IntlProvider } from 'react-intl-redux';
import defaultLayout from './layouts/defaultLayout';

import * as actions from './actions';
import { store } from './store';
import Routes from './routes';
import storage from './services/storage';
import logger from './services/logger';
import Notifier from './containers/notifier';
import ErrorBoundary from './pages/errorBoundary';
import history from './history';

import './rootStyles.scss';

registry.register('store', store);
registry.register('storage', storage);
registry.register('logger', logger);

/* eslint-disable no-undef */
if (typeof appConfig !== 'undefined') {
  const config = appConfig || {};
  registry.register('config', config);
  if (config.logger && config.logger.level) {
    logger.setLevel(config.logger.level);
  }
} else {
  registry.get('logger').warning('WARNING: the config is not defined');
}

function App() {
  const languageRoutes = [
    'sg-en',
    'my-en',
    'th-th',
    'hk-zh',
    'hk-en',
    'id-id',
    'vn-vi',
    'vi-vn',
    'tw-zh',
    'sg-zh',
    'id-en',
    'vn-en',
    'th-en',
    'my-ms',
    'mo-zh',
    'my-zh',
    'kr-en',
    'kr-ko',
    'jp-ja',
    'jp-en'
  ];
  useEffect(() => {
    store.dispatch({
      type: actions.APP_INIT,
      payload: {
        metaData: JSON.parse(storage.getStorage('obt-zenyum-metaData')),
        form1: JSON.parse(storage.getStorage('obt-zenyum-form1')),
        form2: JSON.parse(storage.getStorage('obt-zenyum-form2')),
        form3: JSON.parse(storage.getStorage('obt-zenyum-form3'))
      }
    });
  }, []);
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="App">
      <Provider store={store}>
        <Notifier />
        <IntlProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <Router history={history}>
              <ErrorBoundary>
                <Switch>
                  <Route path="/" component={defaultLayout} exact={true} />
                  <Route path="/obt" component={defaultLayout} exact={true} />
                  {languageRoutes.map((route) => (
                    <Route path={`/${route}`} component={Routes} key={route} />
                  ))}
                </Switch>
              </ErrorBoundary>
            </Router>
          </Suspense>
        </IntlProvider>
      </Provider>
    </div>
  );
}

export default App;
