import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const Button = (props) => {
  const {
    children,
    className,
    disabled,
    id,
    label,
    languageCode,
    onClick,
    style,
    type
  } = props;

  return (
    <button
      type="submit"
      onClick={onClick}
      className={`btn ${disabled} ${type} ${className}`}
      disabled={disabled === 'disabled'}
      style={style}
      lang={languageCode}
      id={id}
    >
      {label || children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.objectOf(PropTypes.any).isRequired,
  languageCode: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string
};

Button.defaultProps = {
  children: '',
  className: '',
  disabled: '',
  id: '',
  languageCode: 'en',
  onClick: () => {},
  style: {},
  type: 'submit'
};

export default Button;
