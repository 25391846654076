import { addLocaleData as addLocaleDataIntl } from 'react-intl';

import id from 'react-intl/locale-data/id';
import en from 'react-intl/locale-data/en';
import th from 'react-intl/locale-data/th';
import vi from 'react-intl/locale-data/vi';
import zh from 'react-intl/locale-data/zh';
import ms from 'react-intl/locale-data/ms';
import ko from 'react-intl/locale-data/ko';
import ja from 'react-intl/locale-data/ja';

import idMessages from './locales/id.json';
import enMessages from './locales/en.json';
import thMessages from './locales/th.json';
import viMessages from './locales/vi.json';
import zhHKMessages from './locales/zh_HK.json';
import zhTWMessages from './locales/zh-TW.json';
import zhSGMessages from './locales/zh_SG.json';
import msMessages from './locales/ms.json';
import zhMOMessages from './locales/zh_Hans_MO.json';
import enHKMessages from './locales/en_HK.json';
import enJPMessages from './locales/en_JP.json';
import enMYMessages from './locales/en_MY.json';
import enVNMessages from './locales/en_VN.json';
import zhMYMessages from './locales/zh_MY.json';
import koMessages from './locales/ko.json';
import jaMessage from './locales/ja_JP.json';

const localeData = { id, en, th, vi, zh, ms, ko, ja };

const getZhMessage = (country) => {
  switch (country) {
    case 'sg':
      return zhSGMessages;
    case 'tw':
      return zhTWMessages;
    case 'hk':
      return zhHKMessages;
    case 'mo':
      return zhMOMessages;
    case 'my':
      return zhMYMessages;
    default:
      return zhTWMessages;
  }
};

const getEnMessages = (country) => {
  switch (country) {
    case 'hk':
      return enHKMessages;
    case 'my':
      return enMYMessages;
    case 'vn':
      return enVNMessages;
    case 'jp':
      return enJPMessages;
    default:
      return enMessages;
  }
};


export function getLocaleData(locale) {
  return localeData[locale];
}

export function getLocaleMessages(country, locale) {
  const localeMessages = {
    id: idMessages,
    en: getEnMessages(country),
    th: thMessages,
    vi: viMessages,
    zh: getZhMessage(country),
    ms: msMessages,
    ko: koMessages,
    ja: jaMessage
  };
  return localeMessages[locale];
}

export function addLocaleData(locale) {
  addLocaleDataIntl(getLocaleData(locale));
}
