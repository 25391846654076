/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import registry from 'app-registry';
import PropTypes from 'prop-types';

import { store } from '../../store';
import { commonTranslations,
  disclaimerMainlayoutTranslations,
  isoTranslations,
  translationMapperFooter } from '../../translation/commonTranslations';
import fetchTranslation from '../../translation/sagas';
import { isMobileBrowser } from '../../utils';
import { CountryConfig, OBT_CONFIG } from './config';

import './styles.scss';
import storage from '../../services/storage';

const getLayoutClass = (type) => {
  switch (type) {
    case 'primary': return 'main-layout-primary';
    case 'secondary': return 'main-layout-secondary';
    case 'tertiary': return 'main-layout-tertiary-header';
    case 'error-page': return 'main-layout-error-page';
    case 'under-age': return 'main-layout-error-page';
    default: return 'main-layout-default';
  }
};

const getHeaderClass = (type) => {
  switch (type) {
    case 'primary': return 'main-layout-primary-header';
    case 'secondary': return 'main-layout-secondary-header';
    case 'tertiary': return 'main-layout-tertiary-header';
    case 'error-page': return 'main-layout-error-header';
    case 'under-age': return 'main-layout-under-age-header';
    default: return 'main-layout-default';
  }
};

const getLayoutbg = (bg) => {
  switch (bg) {
    case 'photo-error': return 'main-layout-photo-error';
    case 'under18': return 'main-layout-under18';
    case 'under15': return 'main-layout-under15';
    case 'under16': return 'main-layout-under16';
    case 'remindme-thanks': return 'main-layout-remindme-thanks';
    case 'thanks-screen': return 'main-layout-thanks';
    default: return '';
  }
};

const MainLayout = (props) => {
  const { popUpBooth, hasHeader, hasISO, history, enableLangSwitch, enableBackArrow, shoowFooterIcons, hasDisclaimer } = props;
  const location = useLocation();
  const { intl } = props;
  const { formatMessage } = intl;
  const obtVersion = process.env.REACT_APP_OBT_VERSION;
  const obtMetaData = JSON.parse(registry.get('storage').getStorage('obt-zenyum-metaData'));
  const { countryCode, languageCode } = { ...obtMetaData, ...(obtMetaData?.metaData) };
  const teensOBT = JSON.parse(storage.getStorage('teens_obt'));

  const changeLanguage = (item) => {
    let path = (location.pathname).slice(6);
    const searchQuery = location.search;
    const pathLength = path.length;
    if (path[(pathLength - 1)] === '/') {
      path = path.slice(0, (pathLength - 1));
    }
    path = path.concat(searchQuery);
    store.dispatch({
      type: 'SET:COUNTRY_CODE',
      data: {
        countryCode: `${item?.countryCode}`,
        languageCode: `${item?.languageCode}`
      }
    });
    fetchTranslation(item?.countryCode, item?.languageCode);
    props.history.push(`/${item.countryCode}-${item.languageCode}${path}`);
  };

  const zenyumLogoClick = () => { window.location.href = `https://www.zenyum.com/${CountryConfig.zenyumLogoClickSwitch[`${countryCode}-${languageCode}`]}/invisible-braces`; };

  const createMarkup = () => ({ __html: formatMessage(commonTranslations.contactInfo?.props) });

  const createMarkupCommercialLaw = () => ({ __html: formatMessage(commonTranslations.contactInfo?.props) });

  return (
    <div className={`${getLayoutClass(props.type)} main-layout`} lang={languageCode}>
      {hasHeader && (
        <div className={`main-layout-header ${getHeaderClass(props.type)} ${teensOBT && 'teens-obt-header'} ${obtVersion}`}>
          {popUpBooth && (
            enableBackArrow
              ? <img
                className="back-arrow"
                src={`${process.env.PUBLIC_URL}/images/layout/back-arrow.png`}
                alt="back-arrow"
                height="24"
                width="24"
                onClick={() => { history.goBack(); }}
              /> : <div />
          )}
          <img
            className={`main-layout-logo ${`${getHeaderClass(props.type)}-logo`} ${teensOBT && 'teens-obt-header-logo'} ${obtVersion} ${countryCode}`}
            src={`${process.env.PUBLIC_URL}/${props.type === 'primary' ? CountryConfig.zenyumWhiteLogo[countryCode] : CountryConfig.zenyumBlueLogo[countryCode]}`}
            alt="logo"
            height="25"
            width={countryCode === 'tw' ? '155' : '125'}
            onClick={(props.errorFooter || obtVersion === 'OBT4') && zenyumLogoClick}
          />
          {(enableLangSwitch && CountryConfig.showLanguageSwitch[countryCode]) && (
            <div className={`language-selector ${obtVersion}`}>
              {CountryConfig.supportedLanguages[countryCode].map((step, index) => (
                <>
                  <span className={`lang-item ${(countryCode === step.countryCode && languageCode === step.languageCode) && 'underline'}`} onClick={() => changeLanguage(step)} role="presentation">
                    {step.title}
                  </span>
                  {index < ((CountryConfig?.supportedLanguages[countryCode]).length - 1) && <span className="languageDivider">|</span>}
                </>
              ))}
            </div>)}
          {popUpBooth && !enableLangSwitch && <></>}
        </div>)}
      <div className={`main-layout-content ${getLayoutbg(props.bg)} ${popUpBooth && 'tablet-view'}`}>
        <div className={`main-layout-children ${props.errorFooter && 'error-footer-child'} ${props.hasISO && OBT_CONFIG.showIsoLogo[obtVersion] && 'IS0-child-height'}`}>
          {props.children}
        </div>
        {hasISO && OBT_CONFIG.showIsoLogo[obtVersion] && (
          <div className="main-layout-ISO">
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/layout/${isMobileBrowser() ? 'isoIconMobile.svg' : 'isoIconDesktop.svg'}`}
                alt="isoIcon"
              />
            </div>
            <div className="main-layout-iso-text">{isoTranslations.content}</div>
          </div>
        )}
        {hasDisclaimer && (
          <div className={`outer-disclaimer ${obtVersion}-${countryCode}`}>
            <div className={`${props.type === 'tertiary' ? 'disclaimer-line alter-disclaimer-line' : 'disclaimer-line'} ${obtVersion}-${countryCode}`} />
            <div className="disclaimer-container">
              <div className="disclaimer-header">{disclaimerMainlayoutTranslations.title}</div>
              <div className="disclaimer-content">{disclaimerMainlayoutTranslations.content}</div>
            </div>
          </div>
        )}
        {props.hasFooter && (
          <div
            className={
              `main-layout-footer ${obtVersion}-${countryCode} ${props.type === 'primary'
                ? 'main-layout-primary-footer' : 'main-layout-secondary-footer'}`
            }
          >
            <div id={`${obtVersion === 'OBT4' ? 'labelOBT4' : 'label'}`}>
              <div>© {translationMapperFooter(countryCode)}</div>
              <div className="footer-text">
                <span>
                  <a
                    href={`https://www.zenyum.com/${CountryConfig.countryLocaleSwitch[`${countryCode}-${languageCode}`]}/website-terms-and-conditions/`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="mail-text"
                  >
                    {commonTranslations.termsAndConditions}
                  </a>
                </span>
                <span className="text-divider"> | </span>
                <span>
                  <a
                    href={`https://www.zenyum.com/${CountryConfig.countryLocaleSwitch[`${countryCode}-${languageCode}`]}/privacy/`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="mail-text"
                  >
                    {commonTranslations.privacyPolicy}
                  </a>
                </span>
                {CountryConfig.showContactDetails[countryCode] && (
                  <>
                    <span className="text-divider"> | </span>
                    <div className="check-txt" dangerouslySetInnerHTML={createMarkup()} />
                  </>
                )}
                {CountryConfig.commercialLaws[countryCode] && (
                  <>
                    <span className="text-divider"> | </span>
                    <a
                      href={`${process.env.REACT_APP_ZENYUM_WEBSITE_URL}/${countryCode}-${languageCode}/legal/specified-commercial-transactions`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="mail-text"
                    >
                      {commonTranslations.commersialLaw}
                    </a>
                  </>
                )}
              </div>
            </div>
            {shoowFooterIcons && CountryConfig.showFooterIcons[countryCode] && (
              <div className="icons-link">
                {Object.keys(CountryConfig.showFooterIcons[countryCode]).map((icon) => (
                  <a
                    href={CountryConfig.showFooterIcons[countryCode][icon]}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="footer-icons"
                  >
                    <img
                      className="footer-icon"
                      src={`${process.env.PUBLIC_URL}/images/layout/${icon}.png`}
                      alt="Line"
                      height="30"
                      width="30"
                    />
                  </a>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {props.errorFooter && (
        <div className="error-footer">
          <div className="country-wrapper" role="presentation">
            <img src={`${process.env.PUBLIC_URL}/country.png`} alt="" data-gumlet="false" className="location-icon" />
            {CountryConfig.countryLanguageList[`${countryCode}-${languageCode}`]
              ? (
                <>
                  <span>{CountryConfig.countryLanguageList[`${countryCode}-${languageCode}`].country}</span>
                  {CountryConfig.countryLanguageList[`${countryCode}-${languageCode}`].language}
                </>)
              : (
                <>
                  <span>Singapore</span>
                  English
                </>)}
          </div>
          <div className="footer-bottom-right">
            <span className="footer-bottom-right-text">
              <a
                href={`https://www.zenyum.com/${CountryConfig.countryLocaleSwitch[`${countryCode}-${languageCode}`]}/refund-policy`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {commonTranslations.refundPolicy}
              </a>
            </span>
            <span className="footer-bottom-right-text">
              <a
                href={`https://www.zenyum.com/${CountryConfig.countryLocaleSwitch[`${countryCode}-${languageCode}`]}/privacy/`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {commonTranslations.privacyPolicy}
              </a>
            </span>
            <span className="footer-bottom-right-text">
              <a
                href={`https://www.zenyum.com/${CountryConfig.countryLocaleSwitch[`${countryCode}-${languageCode}`]}/refund-policy`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {commonTranslations.disclaimer}
              </a>
            </span>
            <span className="footer-bottom-right-text">
              <a
                href={`https://www.zenyum.com/${CountryConfig.countryLocaleSwitch[`${countryCode}-${languageCode}`]}/website-terms-and-conditions/`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {commonTranslations.termsAndConditions}
              </a>
            </span>
            <span className="footer-bottom-right-text">© {translationMapperFooter(countryCode)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

MainLayout.propTypes = {
  bg: PropTypes.string,
  children: PropTypes.node.isRequired,
  hasFooter: PropTypes.bool,
  hasISO: PropTypes.bool,
  errorFooter: PropTypes.bool,
  hasDisclaimer: PropTypes.bool,
  type: PropTypes.string,
  history: PropTypes.shape().isRequired,
  enableLangSwitch: PropTypes.bool.isRequired,
  popUpBooth: PropTypes.bool,
  hasHeader: PropTypes.bool,
  enableBackArrow: PropTypes.bool
};

MainLayout.defaultProps = {
  bg: null,
  hasFooter: true,
  hasISO: false,
  errorFooter: false,
  hasDisclaimer: false,
  type: '',
  popUpBooth: false,
  hasHeader: true,
  enableBackArrow: true
};

export default injectIntl(MainLayout);
