import registry from 'app-registry';
import Immutable from 'seamless-immutable';

import {
  CASE_ACCEPTANCE_SUBMIT_SUCCESS,
  HELLO_SCREEN_EMAIL_FETCH_FAIL,
  HELLO_SCREEN_EMAIL_FETCH_REQUEST,
  HELLO_SCREEN_EMAIL_FETCH_SUCCESS,
  TEENS_CASE_ACCEPTANCE_SUBMIT_SUCCESS
} from '../../actions';
import storageUtils from '../../services/storage';


import {
  APP_EMAIL_SET,
  HELLO_SCREEN_DETAILS_UPDATE,
  HELLO_FORM_SUBMIT_INIT,
  HELLO_FORM_SUBMIT_SUCCESS,
  HELLO_FORM_SUBMIT_FAIL,
  HELLO_SCREEN_GET_DETAILS_REQUEST,
  HELLO_SCREEN_GET_DETAILS_SUCCESS,
  HELLO_SCREEN_GET_DETAILS_FAIL,
  HELLO_SCREEN_TEENS_OBT_CHECK
} from './actions';

const defaultState = Immutable({
  details: {
    name: null,
    email: null,
    phone: {
      number: '',
      country: ''
    },
    dateOfBirth: {
      date: null,
      month: null,
      year: null
    },
    isTeensOBT: false
  },
  pending: 0,
  opportunityDetails: {},
  age: ''
});

export default (state = defaultState, action) => {
  let newState;
  switch (action.type) {
    case 'APP:INIT': {
      const data = action.payload.form1 ? { ...action.payload.form1 } : {};
      return Immutable.merge(
        state,
        { details: { ...data } }
      );
    }
    case HELLO_SCREEN_DETAILS_UPDATE:
      return Immutable.merge(state, { details: { ...state.details, [action.name]: action.value } });

    case HELLO_SCREEN_TEENS_OBT_CHECK:
      return Immutable.merge(state, { details: { ...state.details, isTeensOBT: action.isTeensOBT } });

    case APP_EMAIL_SET: {
      registry
        .get('storage')
        .setStorage(
          'obt-zenyum-form1',
          JSON.stringify({
            name: state.details.name,
            email: action.email,
            phone: `${state.details.phone.country}${state.details.phone.number}`
          })
        );
      return Immutable.merge(
        state,
        { details: { email: action.email } },
        { deep: true }
      );
    }

    case HELLO_FORM_SUBMIT_INIT:
      return Immutable.merge(state, { pending: state.pending + 1 });

    case HELLO_FORM_SUBMIT_SUCCESS:
      return Immutable.merge(state, { pending: state.pending - 1, age: action.data.data.age });

    case HELLO_FORM_SUBMIT_FAIL:
      return Immutable.merge(state, { pending: state.pending - 1 });

    case HELLO_SCREEN_GET_DETAILS_REQUEST:
      return Immutable.merge(state, { pending: state.pending + 1 });

    case HELLO_SCREEN_GET_DETAILS_SUCCESS:
      return Immutable.merge(state, { pending: state.pending - 1, opportunityDetails: action.data });

    case HELLO_SCREEN_GET_DETAILS_FAIL:
      return Immutable.merge(state, { pending: state.pending - 1 });

    case HELLO_SCREEN_EMAIL_FETCH_REQUEST:
      return Immutable.merge(state, { pending: state.pending + 1 });

    case HELLO_SCREEN_EMAIL_FETCH_SUCCESS:
      newState = Immutable.merge(
        state, { details: { ...state.details, email: action.data.data } }
      );
      storageUtils.setStorage('obt-zenyum-form1',
        JSON.stringify({ email: newState.details?.email }));
      return newState;

    case HELLO_SCREEN_EMAIL_FETCH_FAIL:
      return Immutable.merge(state, { pending: state.pending - 1 });

    case CASE_ACCEPTANCE_SUBMIT_SUCCESS:
      return Immutable.merge(
        state, {
          details: {
            ...state.details,
            name: action.data.data.userDetails.name,
            phone: {
              number: action.data.data?.userDetails?.phone,
              dialCode: action.data.data?.userDetails?.isdCode
            }
          }
        }
      );

    case TEENS_CASE_ACCEPTANCE_SUBMIT_SUCCESS:
      return Immutable.merge(state, { details: { ...state.details, name: action.data.data?.userDetails?.name } });

    default:
      return state;
  }
};
