/* eslint-disable max-len */
import React from 'react';

import { FormattedMessage } from 'react-intl';

export const launchScreenTranslations = {
  hello:
  <FormattedMessage
    id="launch.hello"
    description="Hello!"
    defaultMessage="Hello!"
  />,
  subtitle:
  <FormattedMessage
    id="launch.subtitle"
    description="Let’s assess your smile."
    defaultMessage="Let’s assess your smile."
  />,
  metaTitle:
  <FormattedMessage
    id="OBT_Meta_Title"
    description="Zenyum - Invisible Braces"
    defaultMessage="Zenyum - Invisible Braces"
  />,
  metaDescription:
  <FormattedMessage
    id="OBT_Meta_Description"
    description="Start your journey now!"
    defaultMessage="Start your journey now!"
  />
};

export const initialScreenTranslations = {
  header:
  <FormattedMessage
    id="initial.header"
    description="Transform your smile in 5 minutes"
    defaultMessage="Transform your smile in 5 minutes"
  />,
  firstTaskTitle:
  <FormattedMessage
    id="initial.firstTaskTitle"
    description="Take our free assessment "
    defaultMessage="Take our free assessment "
  />,
  secondTaskTitle:
  <FormattedMessage
    id="initial.secondTaskTitle"
    description="Answer two quick questions"
    defaultMessage="Answer two quick questions"
  />,
  thirdTaskTitle:
  <FormattedMessage
    id="initial.thirdTaskTitle"
    description="Show us your smile"
    defaultMessage="Show us your smile"
  />,
  firstTaskTDetails:
  <FormattedMessage
    id="initial.firstTaskTDetails"
    description="Take our free assessment to find out if Zenyum Invisible Braces can help you achieve the smile you want. It only takes five minutes!"
    defaultMessage="Take our free assessment to find out if Zenyum Invisible Braces can help you achieve the smile you want. It only takes five minutes!"
  />,
  secondTaskDetails:
  <FormattedMessage
    id="initial.secondTaskDetails"
    description="Answer two quick questions to help us better understand your concerns."
    defaultMessage="Answer two quick questions to help us better understand your concerns."
  />,
  thirdTaskDetails:
  <FormattedMessage
    id="initial.thirdTaskDetails"
    description="Then you'll need to share some pictures of your current smile for our AI Generator to accurately assess."
    defaultMessage="Then you'll need to share some pictures of your current smile for our AI Generator to accurately assess."
  />,
  startButton:
  <FormattedMessage
    id="initial.startButton"
    description="Get started"
    defaultMessage="Get started"
  />
};

export const onboardingScreenTranslations = {
  header:
  <FormattedMessage
    id="OBTAI_Onboarding_header"
    description="What’s next?"
    defaultMessage="What’s next?"
  />,
  subHeader:
  <FormattedMessage
    id="OBTAI_Onboarding_subHeader"
    description="Total time: 5 mins"
    defaultMessage="Total time: 5 mins"
  />,
  title1:
  <FormattedMessage
    id="OBTAI_Onboarding_title1"
    description="Tell us about yourself"
    defaultMessage="Tell us about yourself"
  />,
  content1:
  <FormattedMessage
    id="OBTAI_Onboarding_content1"
    description="Fill in your personal details, then answer 4 quick questions about your smile."
    defaultMessage="Fill in your personal details, then answer 4 quick questions about your smile."
  />,
  title2:
  <FormattedMessage
    id="OBTAI_Onboarding_title2"
    description="Show us your teeth. "
    defaultMessage="Show us your teeth."
  />,
  content2:
  <FormattedMessage
    id="OBTAI_Onboarding_content2"
    description="Snap 4 photos for an expert assessment. "
    defaultMessage="Snap 4 photos for an expert assessment. "
  />,
  tip2:
  <FormattedMessage
    id="OBTAI_Onboarding_tip2"
    description="TIP: Find a comfortable environment for this step."
    defaultMessage="TIP: Find a comfortable environment for this step."
  />,
  title3:
  <FormattedMessage
    id="OBTAI_Onboarding_title3"
    description="Get your assessment result."
    defaultMessage="Get your assessment result."
  />,
  content3:
  <FormattedMessage
    id="OBTAI_Onboarding_content3"
    description="We'll let you know if we're a match! All data you submit is secure with us."
    defaultMessage="We'll let you know if we're a match! All data you submit is secure with us."
  />,
  buttonLabel:
  <FormattedMessage
    id="OBTAI_Onboarding_buttonLabel"
    description="Start Assesment"
    defaultMessage="Start Assessment"
  >
    {(txt) => (
      <span className="start-smile-assessment">
        {txt}
      </span>
    )}
  </FormattedMessage>
};


export const introScreenTranslations = {
  header:
  <FormattedMessage
    id="intro.header"
    description="What you need to do:"
    defaultMessage="What you need to do:"
  />,
  subTitle:
  <FormattedMessage
    id="intro.subTitle"
    description="Take this simple 3-step assessment for free.<br />
    Start your smile journey today for as low as $2,590."
    defaultMessage="Take this simple 3-step assessment for free.<br />
    Start your smile journey today for as low as $2,590."
  />,
  promotionHeader:
  <FormattedMessage
    id="intro.promotionHeader"
    description="FREE Zenyum Sterilisation Box (worth $150 / RM 500) with your aligner treatment!*
    *T&Cs apply"
    defaultMessage="FREE Zenyum Sterilisation Box (worth $150 / RM 500) with your aligner treatment!*
    *T&Cs apply"
  />,
  subHeader:
  <FormattedMessage
    id="intro.subHeader"
    description="It takes just 5min."
    defaultMessage="It takes just 5mins."
  />,
  title1:
  <FormattedMessage
    id="intro.title1"
    description="Tell us about yourself"
    defaultMessage="Tell us about yourself"
  />,
  content1:
  <FormattedMessage
    id="intro.content1"
    description="So we can get to know you."
    defaultMessage="So we can get to know you."
  />,
  title2:
  <FormattedMessage
    id="intro.title2"
    description="Answer 4 quick questions"
    defaultMessage="Answer 4 quick questions"
  />,
  content2:
  <FormattedMessage
    id="intro.content2"
    description="So we can understand your concerns."
    defaultMessage="So we can understand your concerns."
  />,
  title3:
  <FormattedMessage
    id="intro.title3"
    description="Show us your teeth"
    defaultMessage="Show us your teeth"
  />,
  content3:
  <FormattedMessage
    id="intro.content3"
    description="Take pictures so our dental experts can get a good look at your smile."
    defaultMessage="Take pictures so our dental experts can get a good look at your smile."
  />,
  riskTitle:
  <FormattedMessage
    id="OBT_IntroScreen_Risks_Title"
    description="矯正歯科治療にともなうリスクについて"
    defaultMessage="矯正歯科治療にともなうリスクについて"
  />,
  risksInTreatment:
  <FormattedMessage
    id="OBT_IntroScreen_Risks"
    description="・保険適用外の自由診療となります。
    ・1日20時間以上の装着をお願いしております。装着状況によって、歯の移動量や効果に影響があります。
    ・お客様の理想の歯並びになるよう綿密に治療計画を立てていますが、歯の変化には個人差がございます。必ずしも治療計画の通りに歯が動くわけではございません。
    ・全ての歯科矯正に共通することですが、効果や感じ方や、歯がどのくらい動くかには個人差があり、どの矯正方法を選んだ場合でも、満足のいく治療結果が得られない可能性がございます。懸念事項やご不明な点がございましたら、治療前後いつでも専任カウンセラーにLINEでお問い合わせください。"
    defaultMessage="・保険適用外の自由診療となります。
    ・1日20時間以上の装着をお願いしております。装着状況によって、歯の移動量や効果に影響があります。
    ・お客様の理想の歯並びになるよう綿密に治療計画を立てていますが、歯の変化には個人差がございます。必ずしも治療計画の通りに歯が動くわけではございません。
    ・全ての歯科矯正に共通することですが、効果や感じ方や、歯がどのくらい動くかには個人差があり、どの矯正方法を選んだ場合でも、満足のいく治療結果が得られない可能性がございます。懸念事項やご不明な点がございましたら、治療前後いつでも専任カウンセラーにLINEでお問い合わせください。"
  />,
  alt1:
  <FormattedMessage
    id="intro.alt1"
    description="A female person smiling wide while holding up clear aligners."
    defaultMessage="A female person smiling wide while holding up clear aligners."
  />,
  alt2:
  <FormattedMessage
    id="intro.alt2"
    description="A close-up of a smartphone displaying a questionnaire about teeth."
    defaultMessage="A close-up of a smartphone displaying a questionnaire about teeth."
  />,
  alt3:
  <FormattedMessage
    id="intro.alt3"
    description="A close-up of a smartphone displaying the uploading screen for smile assessment photos."
    defaultMessage="A close-up of a smartphone displaying the uploading screen for smile assessment photos."
  />,

  buttonLabel:
  <FormattedMessage
    id="intro.buttonLabel"
    description="Start Assesment"
    defaultMessage="Start Assessment"
  >
    {(txt) => (
      <span className="start-smile-assessment">
        {txt}
      </span>
    )}
  </FormattedMessage>,
  goToLangMessage: (value) => (
    <FormattedMessage
      id="intro.goToLangMessage"
      description="Go to English website"
      defaultMessage="Go to {value} website"
      values={{ value }}
    />
  )
};

export const remindMeScreenTranslations = {
  oops:
  <FormattedMessage
    id="remindMe.oops"
    description="Oops!"
    defaultMessage="Oops!"
  />,
  subtitle: (value) => (
    <FormattedMessage
      id="remindMe.subtitle"
      description="We’re unable to treat people under 18."
      defaultMessage="We’re unable to treat people under {value}."
      values={{ value }}
    />
  ),
  remindMeScreenContent1: (value) => (
    <FormattedMessage
      id="remindMe.remindMeScreenContent1"
      description="The mouth and teeth of those below 18 are still developing.
      So treatment is best done after you’ve turned 18 when the body stops growing.
      Let’s connect again when you’re 18!"
      defaultMessage="The mouth and teeth of those below {value} are still developing.
      So treatment is best done after you’ve turned {value} when the body stops growing.
      Let’s connect again when you’re {value}!"
      values={{ value }}
    />
  ),
  content2:
  <FormattedMessage
    id="remindMe.content2"
    description="Tell us your birthday and we’ll send you a reminder to do your smile assessment."
    defaultMessage="Tell us your birthday and we’ll send you a reminder to do your smile assessment."
  />,
  year:
  <FormattedMessage
    id="remindMe.year"
    description="Year"
    defaultMessage="Year"
  />,
  month:
  <FormattedMessage
    id="remindMe.month"
    description="Month"
    defaultMessage="Month"
  />,
  date:
  <FormattedMessage
    id="remindMe.date"
    description="Date"
    defaultMessage="Date"
  />,
  january:
  <FormattedMessage
    id="remindMe.january"
    description="January"
    defaultMessage="January"
  />,
  february:
  <FormattedMessage
    id="remindMe.february"
    description="February"
    defaultMessage="February"
  />,
  march:
  <FormattedMessage
    id="remindMe.march"
    description="March"
    defaultMessage="March"
  />,
  april:
  <FormattedMessage
    id="remindMe.april"
    description="April"
    defaultMessage="April"
  />,
  may:
  <FormattedMessage
    id="remindMe.may"
    description="May"
    defaultMessage="May"
  />,
  june:
  <FormattedMessage
    id="remindMe.june"
    description="June"
    defaultMessage="June"
  />,
  july:
  <FormattedMessage
    id="remindMe.july"
    description="July"
    defaultMessage="July"
  />,
  august:
  <FormattedMessage
    id="remindMe.august"
    description="August"
    defaultMessage="August"
  />,
  september:
  <FormattedMessage
    id="remindMe.september"
    description="September"
    defaultMessage="September"
  />,
  october:
  <FormattedMessage
    id="remindMe.october"
    description="October"
    defaultMessage="October"
  />,
  november:
  <FormattedMessage
    id="remindMe.november"
    description="November"
    defaultMessage="November"
  />,
  december:
  <FormattedMessage
    id="remindMe.december"
    description="December"
    defaultMessage="December"
  />,
  buttonLabel:
  <FormattedMessage
    id="remindMe.buttonLabel"
    description="Remind Me"
    defaultMessage="Remind Me"
  />,
  validationMessage:
  <FormattedMessage
    id="remindMe.validationMessage"
    description="Please select all fields"
    defaultMessage="Please select all fields"
  />,
  stayInTouch:
  <FormattedMessage
    id="remindMe.stayInTouch"
    description="Let’s stay in touch"
    defaultMessage="Let’s stay in touch"
  />,
  remindMeScreenContentNew: (age, ageInWords) => (
    <FormattedMessage
      id="remindMe.remindMeScreenContentNew"
      description="Invisible braces treatment might prove challenging as your teeth continue to grow when you’re under 16. We’ll reach out when you’ve turned sweet sixteen! Until then, stay awesome 🙌"
      defaultMessage="Invisible braces treatment might prove challenging as your teeth continue to grow when you’re under {age}. We’ll reach out when you’ve turned sweet {ageInWords}! Until then, stay awesome 🙌"
      values={{ age, ageInWords }}
    />),
  viewOtherProducts:
  <FormattedMessage
    id="remindMe.viewOtherProducts"
    description="View our other products"
    defaultMessage="View our other products"
  />,
  thirteen:
  <FormattedMessage
    id="remindMe.thirteen"
    description="thirteen"
    defaultMessage="thirteen"
  />,
  sixteen:
  <FormattedMessage
    id="remindMe.sixteen"
    description="sixteen"
    defaultMessage="sixteen"
  />,
  eighteen:
  <FormattedMessage
    id="remindMe.eighteen"
    description="eighteen"
    defaultMessage="eighteen"
  />,
  underThirteenThanksHeader:
  <FormattedMessage
    id="remindMe.underThirteenThanksHeader"
    description="Let’s stay in touch"
    defaultMessage="Let’s stay in touch"
  />,
  underThirteenThanksContent: (age, ageInWords) => (
    <FormattedMessage
      id="remindMe.underThirteenThanksContent"
      description="Invisible braces treatment might prove challenging as your teeth continue to grow when you’re under {age}. We’ll reach out when you’ve turned {ageInWords}! Until then, stay awesome 🙌"
      defaultMessage="Invisible braces treatment might prove challenging as your teeth continue to grow when you’re under {age}. We’ll reach out when you’ve turned {ageInWords}! Until then, stay awesome 🙌"
      values={{ age, ageInWords }}
    />),
  underThirteenThanksContentTeens: (age, ageInWords) => (
    <FormattedMessage
      id="remindMe.underThirteenThanksContentTeens"
      description="Invisible braces treatment might prove challenging as your child’s teeth continue to grow when they’re under {age}. We’ll reach out when they’ve turned {ageInWords}! Until then, stay awesome 🙌"
      defaultMessage="Invisible braces treatment might prove challenging as your child’s teeth continue to grow when they’re under {age}. We’ll reach out when they’ve turned {ageInWords}! Until then, stay awesome 🙌"
      values={{ age, ageInWords }}
    />)
};

export const thanksScreenTranslations = {
  thanks:
  <FormattedMessage
    id="thanks.thanks"
    description="Thanks!"
    defaultMessage="Thanks!"
  />,
  subtitle:
  <FormattedMessage
    id="thanks.subtitle"
    description="We can’t wait to see you again."
    defaultMessage="We can’t wait to see you again."
  />,
  content:
  <FormattedMessage
    id="thanks.content"
    description="Meanwhile, drop by our blog to learn more about invisible braces.
    And be sure to follow us on social media to get your dose of smile content!"
    defaultMessage="Meanwhile, drop by our blog to learn more about invisible braces.
    And be sure to follow us on social media to get your dose of smile content!"
  />,
  checkoutProducts:
  <FormattedMessage
    id="thanks.checkoutProducts"
    description="Feel free to check out our other products at: "
    defaultMessage="Feel free to check out our other products at: "
  />
};

// TO BE DELETED FROM LOKALISE TOO RUN PREP AND CHECK
export const finalThanksScreenTranslations = {
  thanks: (value) => (
    <FormattedMessage
      id="finalThanks.thanks"
      description="Thanks"
      defaultMessage="Thanks, {value}."
      values={{ value }}
    />
  ),
  title:
  <FormattedMessage
    id="finalThanks.title"
    description="Your smile assessment is complete!"
    defaultMessage="Your smile assessment is complete!"
  />,
  content:
  <FormattedMessage
    id="finalThanks.content"
    description="We’re excited to help you get a new smile!
    Our dental experts will review your pictures to see if treatment is suitable.
    Look forward to hearing from us within 2 – 3 working days."
    defaultMessage="We’re excited to help you get a new smile!
    Our dental experts will review your pictures to see if treatment is suitable.
    Look forward to hearing from us within 2 – 3 working days."
  />,
  buttonLabel:
  <FormattedMessage
    id="finalThanks.buttonLabel"
    description="Shop other products"
    defaultMessage="Shop other products"
  />,
  content1:
  <FormattedMessage
    id="finalThanks.content1"
    description="second-para"
    defaultMessage="在你通過初步評估，確認適合使用Zenyum後，下一步你會需要到訪牙醫診所進行詳細諮詢，包括3D掃描、X-光檢查和口腔檢查。"
  />,
  contactContent:
  <FormattedMessage
    id="finalThanks.contactContent"
    description="Contact Paragraph"
    defaultMessage="We will contact you via email or phone call at +603-92126421"
  />
};
// TO BE DELETED FROM LOKALISE TOO RUN PREP AND CHECK

export const helloScreenTranslations = {
  header:
  <FormattedMessage
    id="hello.header"
    description="Let’s get to know you first!"
    defaultMessage="Let’s get to know you first!"
  />,
  popoverInstructionHeader:
  <FormattedMessage
    id="hello.popoverInstructionHeader"
    description="It takes just a few minutes to share your details and four smile pictures."
    defaultMessage="It takes just a few minutes to share your details and four smile pictures."
  />,
  popoverInstructionHeaderPhotoSubmissionDisabled:
  <FormattedMessage
    id="hello.popoverInstructionHeaderPhotoSubmissionDisabled"
    description="It only takes a minute to share your details!"
    defaultMessage="It only takes a minute to share your details!"
  />,
  popoverQuestionOne:
  <FormattedMessage
    id="hello.popoverQuestionOne"
    description="Why do I need to submit teeth photos?"
    defaultMessage="Why do I need to submit teeth photos?"
  />,
  popoverAnswerOne:
  <FormattedMessage
    id="hello.popoverAnswerOne"
    description="So our dentists can assess if you’re suitable for Zenyum Invisible Braces, as it might not be for everyone. Your photos will help us provide the best treatment advice for your case.?"
    defaultMessage="So our dentists can assess if you’re suitable for Zenyum Invisible Braces, as it might not be for everyone. Your photos will help us provide the best treatment advice for your case.?"
  />,
  safeToProvideInfoToZenyumQuestion:
  <FormattedMessage
    id="hello.safeToProvideInfoToZenyumQuestion"
    description="Is it safe to provide information to Zenyum?"
    defaultMessage="Is it safe to provide information to Zenyum?"
  />,
  safeToProvideInfoToZenyumAnswer:
  <FormattedMessage
    id="hello.safeToProvideInfoToZenyumAnswer"
    description="We will only use your information for subsequent treatment-related purposes and will never use it for any other purpose!"
    defaultMessage="We will only use your information for subsequent treatment-related purposes and will never use it for any other purpose!"
  />,
  teensInstruction:
  <FormattedMessage
    id="hello.teensInstruction"
    description="For Junior-High and High School students (13yr - 17yrs) interested in treatment, please enter your parent's details below"
    defaultMessage="For Junior-High and High School students (13yr - 17yrs) interested in treatment, please enter your parent's details below"
  />,
  question1:
  <FormattedMessage
    id="hello.question1"
    description="What’s your name?"
    defaultMessage="What’s your name?"
  />,
  question2:
  <FormattedMessage
    id="hello.question2"
    description="Where should we send your smile summary to?"
    defaultMessage="Where should we send your smile summary to?"
  />,
  question3:
  <FormattedMessage
    id="hello.question3"
    description="How can we contact you to follow up on treatment?"
    defaultMessage="How can we contact you to follow up on treatment?"
  />,
  katakanaNamequestion:
  <FormattedMessage
    id="hello.katkanaNamequestion"
    description="Katakana name"
    defaultMessage="Katakana name"
  />,
  katakanaNamePlaceHolder:
  <FormattedMessage
    id="hello.katakanaNamePlaceHolder"
    description="Katakana name"
    defaultMessage="Katakana name"
  />,
  checkboxLabel:
  <FormattedMessage
    id="hello.checkboxLabel"
    description="By clicking next, you agree to be contacted for further information."
    defaultMessage="By clicking next, you agree to be contacted for further information."
  />,
  name:
  <FormattedMessage
    id="hello.name"
    description="Name"
    defaultMessage="Name"
  />,
  email:
  <FormattedMessage
    id="hello.email"
    description="Email"
    defaultMessage="Email"
  />,
  mobile:
  <FormattedMessage
    id="hello.mobile"
    description="Mobile Number"
    defaultMessage="Mobile Number"
  />,
  submittingForChild:
  <FormattedMessage
    id="helloScreen.submittingForChild"
    description="Are you submitting on behalf of your child?"
    defaultMessage="Are you submitting on behalf of your child?"
  />,
  parentsName:
  <FormattedMessage
    id="helloScreen.parentsName"
    description="Your name"
    defaultMessage="Your name"
  />,
  parentsEmail:
  <FormattedMessage
    id="helloScreen.parentsEmail"
    description="Your email"
    defaultMessage="Your email"
  />,
  parentsPhoneNumber:
  <FormattedMessage
    id="helloScreen.parentsPhoneNumber"
    description="Your phone number"
    defaultMessage="Your phone number"
  />,
  parentsWhatsAppNumber:
  <FormattedMessage
    id="helloScreen.parentsWhatsAppNumber"
    description="Your whatsApp number"
    defaultMessage="Your whatsApp number"
  />,
  question4:
  <FormattedMessage
    id="hello.question4"
    description="Line ID"
    defaultMessage="Line ID"
  />,
  question4Placeholder:
  <FormattedMessage
    id="hello.question4Placeholder"
    description="Line ID (Optional)"
    defaultMessage="Line ID (Optional)"
  />,
  stateQuestion:
  <FormattedMessage
    id="hello.stateQuestion"
    description="State"
    defaultMessage="State"
  />,
  statePlaceholder:
  <FormattedMessage
    id="hello.statePlaceholder"
    description="Choose state (Optional)"
    defaultMessage="Choose state (Optional)"
  />,
  districtQuestion:
  <FormattedMessage
    id="hello.districtQuestion"
    description="Choose district (Optional)"
    defaultMessage="Choose district (Optional)"
  />,
  professionQuestion:
  <FormattedMessage
    id="hello.professionQuestion"
    description="What is your occupation?"
    defaultMessage="What is your occupation?"
  />,
  prefectureQuestion:
  <FormattedMessage
    id="hello.prefectureQuestion"
    description="Which prefecture do you live in?"
    defaultMessage="Which prefecture do you live in?"
  />,
  districtPlaceholder:
  <FormattedMessage
    id="hello.districtPlaceholder"
    description="Choose district (Optional)"
    defaultMessage="Choose district (Optional)"
  />,
  whatsappMsg:
  <FormattedMessage
    id="hello.whatsappMsg"
    description="I agree to be contacted by Zenyum via WhatsApp"
    defaultMessage="I agree to be contacted by Zenyum via WhatsApp"
  />,
  whatsappCheckbox:
  <FormattedMessage
    id="hello.whatsappCheckbox"
    description="My WhatsApp number is different from my local mobile number"
    defaultMessage="My WhatsApp number is different from my local mobile number"
  />,
  weChatappCheckbox:
  <FormattedMessage
    id="hello.weChatappCheckbox"
    description="My WeChat number is different from my local mobile number"
    defaultMessage="My WeChat number is different from my local mobile number"
  />,
  whatsappPhonenumber:
  <FormattedMessage
    id="hello.whatsappPhonenumber"
    description="WhatsApp Number"
    defaultMessage="WhatsApp Number"
  />,
  phoneExtraDetails:
  <FormattedMessage
    id="hello.phoneExtraDetails"
    description="We will contact you by this phone number: 02-506-1027 / 02-171-2416"
    defaultMessage="We will contact you by this phone number: 02-506-1027 / 02-171-2416"
  />,
  residenceTitle:
  <FormattedMessage
    id="hello.residenceTitle"
    description="Are you currently residing in Singapore?"
    defaultMessage="Are you currently residing in Singapore?"
  />,
  select:
  <FormattedMessage
    id="hello.select"
    description="Select"
    defaultMessage="Select"
  />,
  dateOfBirth:
  <FormattedMessage
    id="hello.dateOfBirth"
    description="Date of Birth"
    defaultMessage="Date of Birth"
  />,
  agreementText:
  <FormattedMessage
    id="helloScreen.agreementText"
    description="I agree to all of the following:"
    defaultMessage="I agree to all of the following:"
  />,
  termsAndPrivacyAgreementText:
  <FormattedMessage
    id="helloScreen.termsAndPrivacyAgreementText"
    description="By clicking here you agree to accept our Terms & Conditions and Privacy Policy."
    defaultMessage="By clicking here you agree to accept our Terms & Conditions and Privacy Policy."
  />,
  contactAgreementText:
  <FormattedMessage
    id="helloScreen.contactAgreementText"
    description="I agree to be contacted by Zenyum via WhatsApp, SMS, and other channels for the purposes described in our Privacy Policy. (Optional) "
    defaultMessage="I agree to be contacted by Zenyum via WhatsApp, SMS, and other channels for the purposes described in our Privacy Policy. (Optional) "
  />
};

export const helloScreenNewTranslations = {
  header:
  <FormattedMessage
    id="helloScreenNew.header"
    description="Please share your contact details"
    defaultMessage="Please share your contact details"
  />,
  name:
  <FormattedMessage
    id="helloScreenNew.name"
    description="Name"
    defaultMessage="Name"
  />,
  childsName:
  <FormattedMessage
    id="helloScreenOBT4.childsName"
    description="Child’s name"
    defaultMessage="Child’s name"
  />,
  email:
  <FormattedMessage
    id="helloScreenNew.email"
    description="Email"
    defaultMessage="Email"
  />,
  parentsEmail:
  <FormattedMessage
    id="helloScreenOBT4.parentsEmail"
    description="Parent’s email"
    defaultMessage="Parent’s email"
  />,
  phoneNumber:
  <FormattedMessage
    id="helloScreenNew.phoneNumber"
    description="Phone number"
    defaultMessage="Phone number"
  />,
  parentsNumber:
  <FormattedMessage
    id="helloScreenOBT4.parentsNumber"
    description="Parent’s number"
    defaultMessage="Parent’s number"
  />,
  whatsAppNumber:
  <FormattedMessage
    id="helloScreenNew.whatsAppNumber"
    description="WhatsApp number"
    defaultMessage="WhatsApp number"
  />,
  parentsWhatsAppNumber:
  <FormattedMessage
    id="helloScreenOBT4.parentsWhatsAppNumber"
    description="Parent’s Whatsapp number"
    defaultMessage="Parent’s Whatsapp number"
  />,
  instructionHeader:
  <FormattedMessage
    id="helloScreenNew.instructionHeader"
    description="Just 3 easy steps to get started"
    defaultMessage="Just 3 easy steps to get started"
  />,
  instructionContent1:
  <FormattedMessage
    id="helloScreenNew.instructionContent1"
    description="Share your contact details and we’ll be in touch. Don’t worry, your data is safe with us. "
    defaultMessage="Share your contact details and we’ll be in touch. Don’t worry, your data is safe with us. "
  />,
  teensInstructionContent1:
  <FormattedMessage
    id="helloScreenOBT4.teensInstructionContent1"
    description="Share your child’s name and your contact details to get started. Don’t worry, your data is safe with us.  "
    defaultMessage="Share your child’s name and your contact details to get started. Don’t worry, your data is safe with us.  "
  />,
  instructionContent2:
  <FormattedMessage
    id="helloScreenNew.instructionContent2"
    description="Answer 4 quick questions to help us understand your case better. "
    defaultMessage="Answer 4 quick questions to help us understand your case better. "
  />,
  teensInstructionContent2:
  <FormattedMessage
    id="helloScreenOBT4.teensInstructionContent2"
    description="Answer 4 quick questions to help us understand your child’s case better.   "
    defaultMessage="Answer 4 quick questions to help us understand your child’s case better.   "
  />,
  instructionContent3:
  <FormattedMessage
    id="helloScreenNew.instructionContent3"
    description="For a detailed analysis of your smile, snap 4 photos of your teeth and submit your assessment."
    defaultMessage="For a detailed analysis of your smile, snap 4 photos of your teeth and submit your assessment."
  />,
  teensInstructionContent3:
  <FormattedMessage
    id="helloScreenOBT4.teensInstructionContent3"
    description="For a detailed analysis of your child’s smile, snap 4 photos of their teeth to complete the assessment.  "
    defaultMessage="For a detailed analysis of your child’s smile, snap 4 photos of their teeth to complete the assessment.  "
  />,
  getStarted:
  <FormattedMessage
    id="helloScreenNew.getStarted"
    description="Get started"
    defaultMessage="Get started"
  />,
  teensJourneyStart:
  <FormattedMessage
    id="helloScreenOBT4.teensJourneyStart"
    description="Start my child’s Smile Journey"
    defaultMessage="Start my child’s Smile Journey"
  />,
  parentRedirection:
  <FormattedMessage
    id="helloScreenOBT4.parentRedirection"
    description="Not you? Submit an assessment for yourself"
    defaultMessage="Not you? Submit an assessment for yourself"
  />,
  subHeader:
  <FormattedMessage
    id="helloScreenNew.subHeader"
    description="Free Invisible Braces Smile Assessment"
    defaultMessage="<b>Free</b> Invisible Braces<br />Smile Assessment"
  />,
  teensSubHeader:
  <FormattedMessage
    id="helloScreenNew.teensSubHeader"
    description="Free Smile Assessment For Teens "
    defaultMessage="<b>Free</b> Smile Assessment For Teens "
  />,
  whatsAppCheckboxNew:
  <FormattedMessage
    id="helloScreenNew.whatsAppCheckboxNew"
    description="My WhatsApp number is the same as my phone number"
    defaultMessage="My WhatsApp number is the same as my phone number"
  />,
  teensgeneralInstruction:
  <FormattedMessage
    id="helloScreenOBT4.teensgeneralInstruction"
    description="We can help your child get their dream smile if they’re 16 and above!"
    defaultMessage="We can help your child get their dream smile if they’re 16 and above!"
  />
};

export const occupationsTranslations = {
  officeWorker:
  <FormattedMessage
    id="occupations.officeWorker"
    description="Office Worker/Executive"
    defaultMessage="Office Worker/Executive"
  />,
  management:
  <FormattedMessage
    id="occupations.management"
    description="Company management"
    defaultMessage="Company management"
  />,
  freelance:
  <FormattedMessage
    id="occupations.freelance"
    description="Solo proprietor/Freelancer"
    defaultMessage="Solo proprietor/Freelancer"
  />,
  houseWife:
  <FormattedMessage
    id="occupations.houseWife"
    description="House wife"
    defaultMessage="House wife"
  />,
  student:
  <FormattedMessage
    id="occupations.student"
    description="Student"
    defaultMessage="Student"
  />,
  other:
  <FormattedMessage
    id="occupations.other"
    description="Other"
    defaultMessage="Other"
  />
};

export const locationsTranslations = { hokkaidō: <FormattedMessage id="prefecture.hokkaidō" description="Hokkaidō" defaultMessage="Hokkaidō" />,
  aomori: <FormattedMessage id="prefecture.aomori" description="Aomori" defaultMessage="Aomori" />,
  iwate: <FormattedMessage id="prefecture.iwate" description="Iwate" defaultMessage="Iwate" />,
  miyagi: <FormattedMessage id="prefecture.miyagi" description="Miyagi" defaultMessage="Miyagi" />,
  akita: <FormattedMessage id="prefecture.akita" description="Akita" defaultMessage="Akita" />,
  yamagata: <FormattedMessage id="prefecture.yamagata" description="Yamagata" defaultMessage="Yamagata" />,
  fukushima: <FormattedMessage id="prefecture.fukushima" description="Fukushima" defaultMessage="Fukushima" />,
  ibaraki: <FormattedMessage id="prefecture.ibaraki" description="Ibaraki" defaultMessage="Ibaraki" />,
  tochigi: <FormattedMessage id="prefecture.tochigi" description="Tochigi" defaultMessage="Tochigi" />,
  gunma: <FormattedMessage id="prefecture.gunma" description="Gunma" defaultMessage="Gunma" />,
  saitama: <FormattedMessage id="prefecture.saitama" description="Saitama" defaultMessage="Saitama" />,
  chiba: <FormattedMessage id="prefecture.chiba" description="Chiba" defaultMessage="Chiba" />,
  tōkyō: <FormattedMessage id="prefecture.tōkyō" description="Tōkyō" defaultMessage="Tōkyō" />,
  kanagawa: <FormattedMessage id="prefecture.kanagawa" description="Kanagawa" defaultMessage="Kanagawa" />,
  niigata: <FormattedMessage id="prefecture.niigata" description="Niigata" defaultMessage="Niigata" />,
  toyama: <FormattedMessage id="prefecture.toyama" description="Toyama" defaultMessage="Toyama" />,
  ishikawa: <FormattedMessage id="prefecture.ishikawa" description="Ishikawa" defaultMessage="Ishikawa" />,
  fukui: <FormattedMessage id="prefecture.fukui" description="Fukui" defaultMessage="Fukui" />,
  yamanashi: <FormattedMessage id="prefecture.yamanashi" description="Yamanashi" defaultMessage="Yamanashi" />,
  nagano: <FormattedMessage id="prefecture.nagano" description="Nagano" defaultMessage="Nagano" />,
  gifu: <FormattedMessage id="prefecture.gifu" description="Gifu" defaultMessage="Gifu" />,
  shizuoka: <FormattedMessage id="prefecture.shizuoka" description="Shizuoka" defaultMessage="Shizuoka" />,
  aichi: <FormattedMessage id="prefecture.aichi" description="Aichi" defaultMessage="Aichi" />,
  mie: <FormattedMessage id="prefecture.mie" description="Mie" defaultMessage="Mie" />,
  shiga: <FormattedMessage id="prefecture.shiga" description="Shiga" defaultMessage="Shiga" />,
  kyōto: <FormattedMessage id="prefecture.kyōto" description="Kyōto" defaultMessage="Kyōto" />,
  ōsaka: <FormattedMessage id="prefecture.ōsaka" description="Ōsaka" defaultMessage="Ōsaka" />,
  hyōgo: <FormattedMessage id="prefecture.hyōgo" description="Hyōgo" defaultMessage="Hyōgo" />,
  nara: <FormattedMessage id="prefecture.nara" description="Nara" defaultMessage="Nara" />,
  wakayama: <FormattedMessage id="prefecture.wakayama" description="Wakayama" defaultMessage="Wakayama" />,
  tottori: <FormattedMessage id="prefecture.tottori" description="Tottori" defaultMessage="Tottori" />,
  shimane: <FormattedMessage id="prefecture.shimane" description="Shimane" defaultMessage="Shimane" />,
  okayama: <FormattedMessage id="prefecture.okayama" description="Okayama" defaultMessage="Okayama" />,
  hiroshima: <FormattedMessage id="prefecture.hiroshima" description="Hiroshima" defaultMessage="Hiroshima" />,
  yamaguchi: <FormattedMessage id="prefecture.yamaguchi" description="Yamaguchi" defaultMessage="Yamaguchi" />,
  tokushima: <FormattedMessage id="prefecture.tokushima" description="Tokushima" defaultMessage="Tokushima" />,
  kagawa: <FormattedMessage id="prefecture.kagawa" description="Kagawa" defaultMessage="Kagawa" />,
  ehime: <FormattedMessage id="prefecture.ehime" description="Ehime" defaultMessage="Ehime" />,
  kōchi: <FormattedMessage id="prefecture.kōchi" description="Kōchi" defaultMessage="Kōchi" />,
  fukuoka: <FormattedMessage id="prefecture.fukuoka" description="Fukuoka" defaultMessage="Fukuoka" />,
  saga: <FormattedMessage id="prefecture.saga" description="Saga" defaultMessage="Saga" />,
  nagasaki: <FormattedMessage id="prefecture.nagasaki" description="Nagasaki" defaultMessage="Nagasaki" />,
  kumamoto: <FormattedMessage id="prefecture.kumamoto" description="Kumamoto" defaultMessage="Kumamoto" />,
  ōita: <FormattedMessage id="prefecture.ōita" description="Ōita" defaultMessage="Ōita" />,
  miyazaki: <FormattedMessage id="prefecture.miyazaki" description="Miyazaki" defaultMessage="Miyazaki" />,
  kagoshima: <FormattedMessage id="prefecture.kagoshima" description="Kagoshima" defaultMessage="Kagoshima" />,
  okinawa: <FormattedMessage id="prefecture.okinawa" description="Okinawa" defaultMessage="Okinawa" />,
  bangkok: <FormattedMessage id="prefecture.bangkok" description="Bangkok Metropolitan" defaultMessage="Bangkok Metropolitan" />,
  perimeterArea: <FormattedMessage id="prefecture.perimeterArea" description="Perimeter area - Bangkok" defaultMessage="Perimeter area - Bangkok" />,
  chaing: <FormattedMessage id="prefecture.chaing" description="Chaing Mai" defaultMessage="Chaing Mai" />,
  bangSaen: <FormattedMessage id="prefecture.bangSaen" description="Bang Saen - Chon Buri" defaultMessage="Bang Saen - Chon Buri" />,
  pathom: <FormattedMessage id="prefecture.pathom" description="Nakhon Pathom" defaultMessage="Nakhon Pathom" />,
  khon: <FormattedMessage id="prefecture.khon" description="Khon Kaen" defaultMessage="Khon Kaen" />,
  ratchasima: <FormattedMessage id="prefecture.ratchasima" description="Nakon Ratchasima" defaultMessage="Nakon Ratchasima" />,
  phuket: <FormattedMessage id="prefecture.phuket" description="Phuket" defaultMessage="Phuket" />,
  rayong: <FormattedMessage id="prefecture.rayong" description="Rayong" defaultMessage="Rayong" />,
  otherLocation: <FormattedMessage id="prefecture.otherLocation" description="Others" defaultMessage="Others" />,
  selangor: <FormattedMessage id="OBT_HelloFormScreen_State_Selangor" description="KL/Selangor" defaultMessage="KL/Selangor" />,
  penang: <FormattedMessage id="OBT_HelloFormScreen_State_Penang" description="Penang" defaultMessage="Penang" />,
  sabah: <FormattedMessage id="OBT_HelloFormScreen_State_Sabah" description="Sabah" defaultMessage="Sabah" />,
  kepong: <FormattedMessage id="OBT_HelloFormScreen_State_Selangor_Sub_One" description="Kepong/Batu Caves/Wangsa Maju" defaultMessage="Kepong/Batu Caves/Wangsa Maju" />,
  montKiara: <FormattedMessage id="OBT_HelloFormScreen_State_Selangor_Sub_Two" description="Mont Kiara/Bukit Bintang/Ampang Jaya" defaultMessage="Mont Kiara/Bukit Bintang/Ampang Jaya" />,
  cheras: <FormattedMessage id="OBT_HelloFormScreen_State_Selangor_Sub_Three" description="Cheras/Sungai Besi" defaultMessage="Cheras/Sungai Besi" />,
  bukitJalil: <FormattedMessage id="OBT_HelloFormScreen_State_Selangor_Sub_Four" description="Bukit Jalil/Old Klang Road/Seri Kembangan" defaultMessage="Bukit Jalil/Old Klang Road/Seri Kembangan" />,
  rawang: <FormattedMessage id="OBT_HelloFormScreen_State_Selangor_Sub_Five" description="Rawang/Kota Damansara/Sungai Buloh" defaultMessage="Rawang/Kota Damansara/Sungai Buloh" />,
  subang: <FormattedMessage id="OBT_HelloFormScreen_State_Selangor_Sub_Six" description="Subang/Petaling Jaya/Puchong" defaultMessage="Subang/Petaling Jaya/Puchong" />,
  klang: <FormattedMessage id="OBT_HelloFormScreen_State_Selangor_Sub_Seven" description="Klang/Bukit Tinggi/Kota Kemuning" defaultMessage="Klang/Bukit Tinggi/Kota Kemuning" />,
  serdang: <FormattedMessage id="OBT_HelloFormScreen_State_Selangor_Sub_Eight" description="Serdang/Kajang/Cyberjaya" defaultMessage="Serdang/Kajang/Cyberjaya" />,
  tanjongBungah: <FormattedMessage id="OBT_HelloFormScreen_State_Penang_Sub_One" description="Tanjong Bungah/Tanjong Tokong/Pulau Tikus" defaultMessage="Tanjong Bungah/Tanjong Tokong/Pulau Tikus" />,
  georgetown: <FormattedMessage id="OBT_HelloFormScreen_State_Penang_Sub_Two" description="Georgetown/Weld Quay/Jelutong" defaultMessage="Georgetown/Weld Quay/Jelutong" />,
  farlim: <FormattedMessage id="OBT_HelloFormScreen_State_Penang_Sub_Three" description="Air Itam/Farlim/Paya Terubong" defaultMessage="Air Itam/Farlim/Paya Terubong" />,
  greenlane: <FormattedMessage id="OBT_HelloFormScreen_State_Penang_Sub_Four" description="Greenlane/Gelugor/Sungai Dua" defaultMessage="Greenlane/Gelugor/Sungai Dua" />,
  sungaiAra: <FormattedMessage id="OBT_HelloFormScreen_State_Penang_Sub_Five" description="Sungai Ara/Bayan Baru/Bayan Lepas" defaultMessage="Sungai Ara/Bayan Baru/Bayan Lepas" />,
  kotaKinabalu: <FormattedMessage id="OBT_HelloFormScreen_State_Sabah_Sub_One" description="Kota Kinabalu/ Papar/ Penampang" defaultMessage="Kota Kinabalu/ Papar/ Penampang" />,
  putatan: <FormattedMessage id="OBT_HelloFormScreen_State_Sabah_Sub_Two" description="Putatan/ Tuaran/ Kota Belud" defaultMessage="Putatan/ Tuaran/ Kota Belud" /> };

export const allSetTranslations = {
  allSet:
  <FormattedMessage
    id="allSet.allSet"
    description="You’re all set!"
    defaultMessage="You’re all set!"
  />,
  retakePhoto:
  <FormattedMessage
    id="allSet.retakePhoto"
    description="Retake Photo"
    defaultMessage="Retake Photo"
  />,
  buttonLabel:
  <FormattedMessage
    id="allSet.buttonLabel"
    description="Complete Assessment"
    defaultMessage="Complete Assessment"
  />,
  photoNotFoundError:
  <FormattedMessage
    id="allSet.photoNotFoundError"
    description="Select one photo"
    defaultMessage="Select one photo"
  />,
  contactTitle:
  <FormattedMessage
    id="allSet.contactTitle"
    description="We will get back to you in 2-3 business days"
    defaultMessage="We will get back to you in 2-3 business days"
  />,
  contactSubTitle:
  <FormattedMessage
    id="allSet.contactSubTitle"
    description="Please expect a call from this number"
    defaultMessage="Please expect a call from this number"
  />,
  phoneNumber:
  <FormattedMessage
    id="allSet.phoneNumber"
    description="(+603) 9212 6421"
    defaultMessage="(+603) 9212 6421"
  />,
  spinWheel:
  <FormattedMessage
    id="allSet.spinWheel"
    description="Spin To Win!"
    defaultMessage="Spin To Win!"
  />
};

export const teensTranslation = {

  remindMeScreenContentNew: (age) => (
    <FormattedMessage
      id="teens.remindMeScreenContentNew"
      description="As your child’s teeth are still growing, starting their aligner treatment early may pose some challenges. Once your child turns 16, let’s talk about how we can help with their smile transformation."
      defaultMessage="As your child’s teeth are still growing, starting their aligner treatment early may pose some challenges. Once your child turns {age}, let’s talk about how we can help with their smile transformation."
      values={{ age }}
    />),
  guardianPhoneNumber:
  <FormattedMessage
    id="teens.childPhoneNumber"
    description="Child’s number"
    defaultMessage="Child’s number"
  />,
  yourBirthday:
  <FormattedMessage
    id="teens.yourChildBirthday"
    description="Your child’s birth date"
    defaultMessage="Your child’s birth date"
  />,
  question1:
  <FormattedMessage
    id="teens.question1"
    description="How old is your child?"
    defaultMessage="How old is your child?"
  />,
  question2:
  <FormattedMessage
    id="teens.question2"
    description="How would you describe your child’s teeth?"
    defaultMessage="How would you describe your child’s teeth?"
  />,
  question3:
  <FormattedMessage
    id="teens.question3"
    description="What are your concerns about your child’s Smile Journey?"
    defaultMessage="What are your concerns about your child’s Smile Journey?"
  />,
  tellUsMore:
  <FormattedMessage
    id="teens.tellUsMore"
    description="Tell us more about your child’s smile concerns"
    defaultMessage="Tell us more about your child’s smile concerns"
  />,
  next:
  <FormattedMessage
    id="teens.letsDoThis"
    description="Let’s do this"
    defaultMessage="Let’s do this"
  />,
  placeholderExample:
  <FormattedMessage
    id="teens.placeholderExample"
    description="Please transform my child’s smile!"
    defaultMessage="Please transform my child’s smile!"
  />,
  specificGoals:
  <FormattedMessage
    id="teens.specificGoals"
    description="How can we help your child Smile More?"
    defaultMessage="How can we help your child Smile More?"
  />,
  snapPhotos:
  <FormattedMessage
    id="teens.snapPhotos"
    description="Time to snap some photos of your child’s teeth, so make sure they're by your side. If they aren’t with you, simply upload their photos and you’re done!"
    defaultMessage="Time to snap some photos of your child’s teeth, so make sure they're by your side. If they aren’t with you, simply upload their photos and you’re done!"
  />,
  anotherEmail:
  <FormattedMessage
    id="teens.anotherEmail"
    description="Please enter another email that we can contact your child at!"
    defaultMessage="Please enter another email that we can contact your child at!"
  />,
  emailHeader:
  <FormattedMessage
    id="teens.emailHeader"
    description="Child’s email"
    defaultMessage="Child’s email"
  />,
  phoneNumber:
  <FormattedMessage
    id="teens.phoneNumber"
    description="Child’s phone number"
    defaultMessage="Child’s phone number"
  />,
  dateOfBirth:
  <FormattedMessage
    id="teens.dateOfBirth"
    description="Child’s date of birth"
    defaultMessage="Child’s date of birth"
  />
};

export const smileTranslations = {
  question1:
  <FormattedMessage
    id="smile.question1"
    description="Which age category do you belong to ?"
    defaultMessage="Which age category do you belong to ?"
  />,
  question2:
  <FormattedMessage
    id="smile.question2"
    description="How would you describe your teeth ?"
    defaultMessage="How would you describe your teeth ?"
  />,
  childTeethConditionQuestion:
  <FormattedMessage
    id="smile.childTeethConditionQuestion"
    description="How would you describe your child’s teeth?"
    defaultMessage="How would you describe your child’s teeth?"
  />,
  question3:
  <FormattedMessage
    id="smile.question3"
    description="What are your concerns when it comes to invisible braces ?"
    defaultMessage="What are your concerns when it comes to invisible braces ?"
  />,
  childTreatmentConcernQuestion:
  <FormattedMessage
    id="smile.childTreatmentConcernQuestion"
    description="What are your concerns about your child’s Smile Journey?"
    defaultMessage="What are your concerns about your child’s Smile Journey?"
  />,
  guardianContactInfoQuestion:
  <FormattedMessage
    id="smile.guardianContactInfoQuestion"
    description="Please provide your guardian’s contact information."
    defaultMessage="Please provide your guardian’s contact information."
  />,
  firstQuestionHint:
  <FormattedMessage
    id="smile.firstQuestionHint"
    description="Select one option."
    defaultMessage="Select one option."
  />,
  hint:
  <FormattedMessage
    id="smile.hint"
    description="Select all that apply"
    defaultMessage="Select all that apply"
  />,
  under13:
  <FormattedMessage
    id="smile.under13"
    description="Under 13"
    defaultMessage="Under 13"
  />,
  under15:
  <FormattedMessage
    id="smile.under15"
    description="Under 15"
    defaultMessage="Under 15"
  />,
  under16:
  <FormattedMessage
    id="smile.under16"
    description="Under 16"
    defaultMessage="Under 16"
  />,
  under18:
  <FormattedMessage
    id="smile.under18"
    description="Under 18"
    defaultMessage="Under 18"
  />,
  under20:
  <FormattedMessage
    id="smile.under20"
    description="Under 20"
    defaultMessage="Under 20"
  />,
  above45:
  <FormattedMessage
    id="smile.above45"
    description="Above 45"
    defaultMessage="Above 45"
  />,
  above55:
  <FormattedMessage
    id="smile.above55"
    description="Above 55"
    defaultMessage="Above 55"
  />,
  crowdingTeeth:
  <FormattedMessage
    id="smile.crowdingTeeth"
    description="Crowding teeth"
    defaultMessage="Crowding teeth"
  />,
  crowdedTeeth:
  <FormattedMessage
    id="smile.crowdedTeeth"
    description="Crowded teeth"
    defaultMessage="Crowded teeth"
  />,
  protrudingTeeth:
  <FormattedMessage
    id="smile.protrudingTeeth"
    description="Protruding teeth"
    defaultMessage="Protruding teeth"
  />,
  gapsInTeeth:
  <FormattedMessage
    id="smile.gapsInTeeth"
    description="Gaps in teeth"
    defaultMessage="Gaps in teeth"
  />,
  crookedTeeth:
  <FormattedMessage
    id="smile.crookedTeeth"
    description="Crooked teeth"
    defaultMessage="Crooked teeth"
  />,
  straighterTeeth:
  <FormattedMessage
    id="smile.straighterTeeth"
    description="I just want straighter teeth"
    defaultMessage="I just want straighter teeth"
  />,
  price:
  <FormattedMessage
    id="smile.price"
    description="Price"
    defaultMessage="Price"
  />,
  treatmentProcess:
  <FormattedMessage
    id="smile.treatmentProcess"
    description="Treatment Process"
    defaultMessage="Treatment Process"
  />,
  duration:
  <FormattedMessage
    id="smile.duration"
    description="Duration"
    defaultMessage="Duration"
  />,
  treatmentDuration:
  <FormattedMessage
    id="smile.treatmentDuration"
    description="Treatment Duration"
    defaultMessage="Treatment Duration"
  />,
  pain:
  <FormattedMessage
    id="smile.pain"
    description="Pain"
    defaultMessage="Pain"
  />,
  noneOfTheAbove:
  <FormattedMessage
    id="smile.noneOfTheAbove"
    description="None of the above"
    defaultMessage="None of the above"
  />,
  tellUsMore:
  <FormattedMessage
    id="smile.tellUsMore"
    description="Tell us more about your smile"
    defaultMessage="Tell us more about your smile"
  />,
  childTellUsMore:
  <FormattedMessage
    id="smile.childTellUsMore"
    description="Tell us more about your child’s smile concerns"
    defaultMessage="Tell us more about your child’s smile concerns"
  />,
  specificGoals:
  <FormattedMessage
    id="smile.specificGoals"
    description=" What would you like to improve?"
    defaultMessage="What would you like to improve?"
  />,
  childSmileImprovementQuestion:
  <FormattedMessage
    id="smile.childSmileImprovementQuestion"
    description="How can we help your child Smile More?"
    defaultMessage="How can we help your child Smile More?"
  />,
  placeholderExample:
  <FormattedMessage
    id="smile.placeholderExample"
    description="E.g. I want straighter teeth"
    defaultMessage="E.g. I want straighter teeth"
  />,
  childSmileImprovementPlaceholder:
  <FormattedMessage
    id="smile.childSmileImprovementPlaceholder"
    description="Please transform my child’s smile! "
    defaultMessage="Please transform my child’s smile! "
  />,
  above50:
  <FormattedMessage
    id="smile.above50"
    description="Above 50"
    defaultMessage="Above 50"
  />,
  weekendTime:
  <FormattedMessage
    id="smile.weekendTime"
    description="Weekends(Afternoon)"
    defaultMessage="Weekends(Afternoon)"
  />,
  weekdayMorningTime:
  <FormattedMessage
    id="smile.weekdayMorningTime"
    description="Weekdays(9am-12pm)"
    defaultMessage="Weekdays(9am-12pm)"
  />,
  weekdayAfternoonTime:
  <FormattedMessage
    id="smile.weekdayAfternoonTime"
    description="Weekdays(12pm-3pm)"
    defaultMessage="Weekdays(12pm-3pm)"
  />,
  weekdayEveningTime:
  <FormattedMessage
    id="smile.weekdayEveningTime"
    description="Weekdays(3pm-6pm)"
    defaultMessage="Weekdays(3pm-6pm)"
  />,
  weekdayEarlyNightTime:
  <FormattedMessage
    id="smile.weekdayEarlyNightTime"
    description="Weekdays(6:30pm-8pm)"
    defaultMessage="Weekdays(6:30pm-8pm)"
  />,
  weekdayNightTime:
  <FormattedMessage
    id="smile.weekdayNightTime"
    description="Weekdays(8pm-9:30pm)"
    defaultMessage="Weekdays(8pm-9:30pm)"
  />,
  otherCallTime:
  <FormattedMessage
    id="smile.otherCallTime"
    description="Other"
    defaultMessage="Other"
  />,
  otherOption:
  <FormattedMessage
    id="smile.otherOption"
    description="Other"
    defaultMessage="Other"
  />,
  otherCallTimeTextBox:
  <FormattedMessage
    id="smile.otherCallTimeTextBox"
    description="Other preference type here..."
    defaultMessage="Other preference type here..."
  />,
  otherConcernsTextBox:
  <FormattedMessage
    id="smile.otherConcernsTextBox"
    description="Other concerns type here..."
    defaultMessage="Other concerns type here..."
  />,
  timeQuestion:
  <FormattedMessage
    id="smile.timeQuestion"
    description="What time should we call you?"
    defaultMessage="What time should we call you?"
  />,
  treatmentStart:
  <FormattedMessage
    id="OBT_SmileQuestions_treatmentStart_question"
    description="When do you plan to start your aligner treatment?"
    defaultMessage="When do you plan to start your aligner treatment?"
  />,
  treatmentStartOptionOne:
  <FormattedMessage
    id="OBT_SmileQuestions_treatmentStart_optionOne"
    description="As soon as possible"
    defaultMessage="As soon as possible"
  />,
  treatmentStartOptionTwo:
  <FormattedMessage
    id="OBT_SmileQuestions_treatmentStart_optionTwo"
    description="Next 3 months"
    defaultMessage="Next 3 months"
  />,
  treatmentStartOptionThree:
  <FormattedMessage
    id="OBT_SmileQuestions_treatmentStart_optionThree"
    description="Next 6 months"
    defaultMessage="Next 6 months"
  />,
  treatmentStartOptionFour:
  <FormattedMessage
    id="OBT_SmileQuestions_treatmentStart_optionFour"
    description="Next year"
    defaultMessage="Next year"
  />,
  youtubeQuestion:
  <FormattedMessage
    id="smile.youtubeQuestion"
    description="When are you planning to start your treatment?"
    defaultMessage="When are you planning to start your treatment?"
  />,
  yourBirthday:
  <FormattedMessage
    id="smile.yourBirthday"
    description="Your birthday"
    defaultMessage="Your birthday"
  />,
  invalidDOB:
  <FormattedMessage
    id="smile.invalidDOB"
    description="Invalid Date of birth. Try again"
    defaultMessage="Invalid Date of birth. Try again"
  />,
  guardianPhoneNumber:
  <FormattedMessage
    id="smile.guardianPhoneNumber"
    description="Guardian’s phone number"
    defaultMessage="Guardian’s phone number"
  />,
  guardianEmail:
  <FormattedMessage
    id="smile.guardianEmail"
    description="Guardian’s email"
    defaultMessage="Guardian’s email"
  />,
  guardianWhatsAppNumber:
  <FormattedMessage
    id="smile.guardianWhatsAppNumber"
    description="Guardian’s whatsApp number"
    defaultMessage="Guardian’s whatsApp number"
  />,
  guardianWhatsappDiffersMessage:
  <FormattedMessage
    id="smile.guardianWhatsappDiffersMessage"
    description="My guardian’s WhatsApp number is different from their phone number"
    defaultMessage="My guardian’s WhatsApp number is different from their phone number"
  />,
  guardianNotificationMessage:
  <FormattedMessage
    id="smile.guardianNotificationMessage"
    description="I have informed my guardian(s) and they are expecting your call"
    defaultMessage="I have informed my guardian(s) and they are expecting your call"
  />
};

export const errorTranslations = {
  fourOhFour:
  <FormattedMessage
    id="error.fourOhFour"
    description="Four Oh-Oh!"
    defaultMessage="Four Oh-Oh!"
  />,
  pageContent:
  <FormattedMessage
    id="error.pageContent"
    description="This page seems to be missing, but your confident smile doesn’t have to be! Continue your smile assessment today."
    defaultMessage="This page seems to be missing, but your confident smile doesn’t have to be! Continue your smile assessment today."
  />,
  buttonLabel:
  <FormattedMessage
    id="error.buttonLabel"
    description="Back to your free smile assessment"
    defaultMessage="Back to your free smile assessment"
  />,
  somethingWentWrong:
  <FormattedMessage
    id="error.somethingWentWrong"
    description="Something Went Wrong"
    defaultMessage="Something Went Wrong"
  />,
  photoError:
  <FormattedMessage
    id="error.photoError"
    description="Photo capture unsuccessful"
    defaultMessage="Photo capture unsuccessful"
  />,
  urlNotFound:
  <FormattedMessage
    id="error.urlNotFound"
    description="URL not found"
    defaultMessage="URL not found"
  />,
  retakePhoto:
  <FormattedMessage
    id="error.retakePhoto"
    description="Retake Photo"
    defaultMessage="Retake Photo"
  />,
  goHome:
  <FormattedMessage
    id="error.goHome"
    description="Go Home"
    defaultMessage="Go Home"
  />
};


export const commonTranslations = {
  hello:
  <FormattedMessage
    id="common.hello"
    description="Hello"
    defaultMessage="Hello"
  />,
  smileQuestions:
  <FormattedMessage
    id="common.smileQuestions"
    description="Smile Questions"
    defaultMessage="Smile Questions"
  />,
  assessment:
  <FormattedMessage
    id="common.assesment"
    description="Assessment"
    defaultMessage="Assessment"
  />,
  contactDetails:
  <FormattedMessage
    id="common.contactDetails"
    description="Contact details"
    defaultMessage="Contact details"
  />,
  smileQuestionsNew:
  <FormattedMessage
    id="common.smileQuestionsNew"
    description="Smile questions"
    defaultMessage="Smile questions"
  />,
  photoTaking:
  <FormattedMessage
    id="common.photoTaking"
    description="Photo taking"
    defaultMessage="Photo taking"
  />,
  photoTakingCompleted:
  <FormattedMessage
    id="common.photoTakingCompleted"
    description="Photo taking completed!"
    defaultMessage="Photo taking completed!"
  />,
  next:
  <FormattedMessage
    id="common.next"
    description="Next"
    defaultMessage="Next"
  />,
  invalidName:
  <FormattedMessage
    id="common.invalidName"
    description="Invalid name"
    defaultMessage="Invalid name"
  />,
  invalidEmail:
  <FormattedMessage
    id="common.invalidEmail"
    description="Invalid email"
    defaultMessage="Invalid email"
  />,
  invalidNumber:
  <FormattedMessage
    id="common.invalidNumber"
    description="Invalid number"
    defaultMessage="Invalid number"
  />,
  invalidNameTryAgain:
  <FormattedMessage
    id="common.invalidNameTryAgain"
    description="Invalid name. Try again"
    defaultMessage="Invalid name. Try again"
  />,
  invalidEmailTryAgain:
  <FormattedMessage
    id="common.invalidEmailTryAgain"
    description="Invalid email. Try again"
    defaultMessage="Invalid email. Try again"
  />,
  invalidPhoneTryAgain:
  <FormattedMessage
    id="common.invalidPhoneTryAgain"
    description="Invalid phone number. Try again"
    defaultMessage="Invalid phone number. Try again"
  />,
  optional:
  <FormattedMessage
    id="common.optional"
    description="Optional"
    defaultMessage="Optional"
  />,
  required:
  <FormattedMessage
    id="common.required"
    description="Required"
    defaultMessage="Required"
  />,
  requiredField:
  <FormattedMessage
    id="common.requiredField"
    description="Required field"
    defaultMessage="Required field"
  />,
  childEmailInstruction:
  <FormattedMessage
    id="common.childEmailInstruction"
    description="Please enter your child’s email address, which needs to be different from yours. This email address won’t be used to contact your child directly."
    defaultMessage="Please enter your child’s email address, which needs to be different from yours. This email address won’t be used to contact your child directly."
  />,
  childPhoneNumberInstruction:
  <FormattedMessage
    id="common.childPhoneNumberInstruction"
    description="Your child’s phone number needs to be different from yours."
    defaultMessage="Your child’s phone number needs to be different from yours."
  />,
  company:
  <FormattedMessage
    id="common.comapny"
    description="2020 Zenyum Pte. Ltd. All Rights Reserved."
    defaultMessage="2020 Zenyum Pte. Ltd. All Rights Reserved."
  />,
  companyHK:
  <FormattedMessage
    id="common.comapnyHK"
    description="2020 Zenyum (HK) Ltd. All Rights Reserved."
    defaultMessage="2020 Zenyum (HK) Ltd. All Rights Reserved."
  />,
  refundPolicy:
  <FormattedMessage
    id="common.refundPolicy"
    description="Refund Policy"
    defaultMessage="Refund Policy"
  />,
  disclaimer:
  <FormattedMessage
    id="common.disclaimer"
    description="Disclaimer"
    defaultMessage="Disclaimer"
  />,
  termsAndConditions:
  <FormattedMessage
    id="common.termsAndConditions"
    description="Terms and Conditions"
    defaultMessage="Terms and Conditions"
  />,
  privacyPolicy:
  <FormattedMessage
    id="common.privacyPolicy"
    description="Privacy Policy"
    defaultMessage="Privacy Policy"
  />,
  commersialLaw:
  <FormattedMessage
    id="OBT_IntroScreen_Footer_CommersialLaw"
    description="特定商取引法に基づく表示"
    defaultMessage="特定商取引法に基づく表示"
  />,
  contactInfo:
  <FormattedMessage
    id="common.contactInfo"
    description="Contact: smile.vietnam@zenyum.com; (+84) 8 9919 4889)"
    defaultMessage="Contact: smile.vietnam@zenyum.com; (+84) 8 9919 4889)"
  />,
  yes:
  <FormattedMessage
    id="common.yes"
    description="Yes"
    defaultMessage="Yes"
  />,
  no:
  <FormattedMessage
    id="common.no"
    description="No"
    defaultMessage="No"
  />
};

export const cameraTranslations = {
  instruction1:
  <FormattedMessage
    id="camera.instruction1"
    description="Bite back on your back teeth naturally and smile."
    defaultMessage="Bite back on your back teeth naturally and smile."
  />,
  instruction2:
  <FormattedMessage
    id="camera.instruction2"
    description="Bring your phone up, open wide and show your bottom front teeth by keeping lips away."
    defaultMessage="Bring your phone up, open wide and show your bottom front teeth by keeping lips away."
  />,
  instruction3:
  <FormattedMessage
    id="camera.instruction3"
    description="Tilt your phone down, open wide and show your upper front teeth by keeping lips away."
    defaultMessage="Tilt your phone down, open wide and show your upper front teeth by keeping lips away."
  />,
  instruction4:
  <FormattedMessage
    id="camera.instruction4"
    description="Turn your head to the side and clench evenly on your back teeth on both sides."
    defaultMessage="Turn your head to the side and clench evenly on your back teeth on both sides."
  />,
  instruction1OBT4:
  <FormattedMessage
    id="camera.instruction1OBT4"
    description="Bite down on your back teeth naturally and smile."
    defaultMessage="Bite down on your back teeth naturally and smile."
  />,
  instruction2OBT4:
  <FormattedMessage
    id="camera.instruction2OBT4"
    description="Tilt your chin downwards a little and fully show your bottom row of teeth by opening wide and keeping your lips away."
    defaultMessage="Tilt your chin downwards a little and fully show your bottom row of teeth by opening wide and keeping your lips away."
  />,
  instruction3OBT4:
  <FormattedMessage
    id="camera.instruction3OBT4"
    description="Tilt your chin upwards a little and fully show your upper row of teeth by opening wide and keeping your lips away."
    defaultMessage="Tilt your chin upwards a little and fully show your upper row of teeth by opening wide and keeping your lips away."
  />,
  instruction4OBT4:
  <FormattedMessage
    id="camera.instruction4OBT4"
    description="Turn your head to the side and bite down on your back teeth on both sides."
    defaultMessage="Turn your head to the side and bite down on your back teeth on both sides."
  />,
  instruction2OBT4New:
  <FormattedMessage
    id="camera.instruction2OBT4New"
    description="Tilt your chin slightly downwards. Open wide and keep lips away to fully show your bottom row of teeth."
    defaultMessage="Tilt your chin slightly downwards. Open wide and keep lips away to fully show your bottom row of teeth."
  />,
  instruction3OBT4New:
  <FormattedMessage
    id="camera.instruction3OBT4New"
    description="Tilt your chin slightly upwards. Open wide and keep lips away to fully show your upper row of teeth."
    defaultMessage="Tilt your chin slightly upwards. Open wide and keep lips away to fully show your upper row of teeth."
  />,
  instruction4OBT4New:
  <FormattedMessage
    id="camera.instruction4OBT4New"
    description="Turn your head to the side and bite down on your back teeth."
    defaultMessage="Turn your head to the side and bite down on your back teeth."
  />,
  instructionIndex1:
  <FormattedMessage
    id="camera.1of4"
    description="1 of 4"
    defaultMessage="1 of 4"
  />,
  instructionIndex2:
  <FormattedMessage
    id="camera.2of4"
    description="2 of 4"
    defaultMessage="2 of 4"
  />,
  instructionIndex3:
  <FormattedMessage
    id="camera.3of4"
    description="3 of 4"
    defaultMessage="3 of 4"
  />,
  instructionIndex4:
  <FormattedMessage
    id="camera.4of4"
    description="4 of 4"
    defaultMessage="4 of 4"
  />,
  checkCamera:
  <FormattedMessage
    id="camera.checkCamera"
    description="Check Camera Settings"
    defaultMessage="Check Camera Settings"
  />,
  switchCamera:
  <FormattedMessage
    id="camera.switchCamera"
    description="Switch Camera"
    defaultMessage="Switch Camera"
  />,
  retakePhoto:
  <FormattedMessage
    id="camera.retakePhoto"
    description="Retake Photo"
    defaultMessage="Retake Photo"
  />,
  switchingCamera:
  <FormattedMessage
    id="camera.switchingCamera"
    description="Switching Camera"
    defaultMessage="Switching Camera"
  />,
  takePhoto:
  <FormattedMessage
    id="camera.takePhotoBtn"
    description="Take Photo"
    defaultMessage="Take Photo"
  />,
  noFileMsg:
  <FormattedMessage
    id="camera.noFileMsg"
    description="Please choose a valid file"
    defaultMessage="Please choose a valid file"
  />,
  invalidFileMsg:
  <FormattedMessage
    id="camera.invalidFileMsg"
    description="Choose only jpg jpeg or png files"
    defaultMessage="Choose only jpg jpeg or png files"
  />,
  uploadPhoto:
  <FormattedMessage
    id="camera.uploadPhoto"
    description="Upload"
    defaultMessage="Upload"
  />,
  frontViewMsg:
  <FormattedMessage
    id="camera.frontViewMsg"
    description="Oops, please bite down on your back teeth and try again!"
    defaultMessage="Oops, please bite down on your back teeth and try again!"
  />,
  teensFrontViewMsg:
  <FormattedMessage
    id="camera.teensFrontViewMsg"
    description="Oops! Please get your child to bite down on their back teeth and smile wide. Pro tip: Ensure you have bright lighting, hold the camera still, and don’t let the lips block the teeth."
    defaultMessage="Oops! Please get your child to bite down on their back teeth and smile wide. Pro tip: Ensure you have bright lighting, hold the camera still, and don’t let the lips block the teeth."
  />,
  upperViewMSg:
  <FormattedMessage
    id="camera.upperViewMSg"
    description="Oops, please make sure your front 8 teeth are fully visible and try again!"
    defaultMessage="Oops, please make sure your front 8 teeth are fully visible and try again!"
  />,
  teensUpperViewMSg:
  <FormattedMessage
    id="camera.teensUpperViewMSg"
    description="Oops! Please ensure your child’s front 8 teeth are fully visible. Pro tip: Use bright lighting, hold the camera still, and don’t let the lips block the teeth. "
    defaultMessage="Oops! Please ensure your child’s front 8 teeth are fully visible. Pro tip: Use bright lighting, hold the camera still, and don’t let the lips block the teeth. "
  />,
  lowerViewMsg:
  <FormattedMessage
    id="camera.lowerViewMsg"
    description="Oops, please make sure your lower 8 teeth are fully visible and try again!"
    defaultMessage="Oops, please make sure your lower 8 teeth are fully visible and try again!"
  />,
  teensLowerViewMsg:
  <FormattedMessage
    id="camera.teensLowerViewMsg"
    description="Oops! Please ensure your child’s lower 8 teeth are fully visible. Pro tip: Use bright lighting, hold the camera still, and don’t let the lips block the teeth."
    defaultMessage="Oops! Please ensure your child’s lower 8 teeth are fully visible. Pro tip: Use bright lighting, hold the camera still, and don’t let the lips block the teeth."
  />,
  sideViewMsg:
  <FormattedMessage
    id="camera.sideViewMsg"
    description="Oops, please bite down on your back teeth and try again!"
    defaultMessage="Oops, please bite down on your back teeth and try again!"
  />,
  teensSideViewMsg:
  <FormattedMessage
    id="camera.teensSideViewMsg"
    description="Oops! Please get your child to bite down on their back teeth and smile wide. Pro tip: Ensure you have bright lighting, hold the camera still, and don’t let the lips block the teeth."
    defaultMessage="Oops! Please get your child to bite down on their back teeth and smile wide. Pro tip: Ensure you have bright lighting, hold the camera still, and don’t let the lips block the teeth."
  />,
  missingTeethMsg:
  <FormattedMessage
    id="camera.missingTeethMsg"
    description="Oops, please position your teeth within the frame and try again!"
    defaultMessage="Oops, please position your teeth within the frame and try again!"
  />,
  otherErrorMessage:
  <FormattedMessage
    id="camera.otherErrorMessage"
    description="Oops, please reposition your mouth and try again!"
    defaultMessage="Oops, please reposition your mouth and try again!"
  />,
  analysingPhotoMsg:
  <FormattedMessage
    id="camera.analysingPhotoMsg"
    description="Analysing photo"
    defaultMessage="Analysing photo"
  />,
  cameraProgressLoaderMessage:
  <FormattedMessage
    id="camera.progressLoaderMessage"
    description="Analysing your photo..."
    defaultMessage="Analysing your photo..."
  />,
  swipeTitle:
  <FormattedMessage
    id="camera.swipeTitle"
    description="Oops! Photo can’t be used."
    defaultMessage="Oops! Photo can’t be used."
  />,
  swipeSubTitle:
  <FormattedMessage
    id="camera.swipeSubTitle"
    description="Let us help you take a better picture for more accurate results."
    defaultMessage="Let us help you take a better picture for more accurate results."
  />,
  whatWentWrong:
  <FormattedMessage
    id="camera.whatWentWrong"
    description="What went wrong?"
    defaultMessage="What went wrong?"
  />,
  swipeInstructionHeading:
  <FormattedMessage
    id="camera.swipeInstructionHeading"
    description="Make sure that..."
    defaultMessage="Analysing photo"
  />,
  swipeHelpTitle:
  <FormattedMessage
    id="camera.swipeHelpTitle"
    description="We’re here to help"
    defaultMessage="We’re here to help"
  />,
  swipeHelpSubTitle:
  <FormattedMessage
    id="camera.swipeHelpSubTitle"
    description="If you have any questions, feel free to reach out!"
    defaultMessage="If you have any questions, feel free to reach out!"
  />,
  swipeInstruction1:
  <FormattedMessage
    id="camera.swipeInstruction1"
    description="Your eight upper and bottom front teeth are visible."
    defaultMessage="Your eight upper and bottom front teeth are visible."
  />,
  swipeInstructionBottom:
  <FormattedMessage
    id="camera.swipeInstructionBottom"
    description="Your eight bottom teeth are visible."
    defaultMessage="Your eight bottom teeth are visible."
  />,
  swipeInstructionUpper:
  <FormattedMessage
    id="camera.swipeInstructionUpper"
    description="Your eight upper teeth are visible."
    defaultMessage="Your eight upper teeth are visible."
  />,
  swipeInstructionSide:
  <FormattedMessage
    id="camera.swipeInstructionSide"
    description="The side view of your teeth is visible."
    defaultMessage="The side view of your teeth is visible."
  />,
  swipeInstruction2:
  <FormattedMessage
    id="camera.swipeInstruction2"
    description="You hold your camera still."
    defaultMessage="You hold your camera still."
  />,
  swipeInstruction3:
  <FormattedMessage
    id="camera.swipeInstruction3"
    description="Your mouth is opened wide, and your lips do not cover your teeth."
    defaultMessage="Your mouth is opened wide, and your lips do not cover your teeth."
  />,
  swipeInstruction4:
  <FormattedMessage
    id="camera.swipeInstruction4"
    description="You use the blue guide to align your teeth."
    defaultMessage="You use the blue guide to align your teeth."
  />,
  swipeInstruction5:
  <FormattedMessage
    id="camera.swipeInstruction5"
    description="If possible, get someone to take the photo for you!"
    defaultMessage="If possible, get someone to take the photo for you!"
  />,
  swipeInstruction6:
  <FormattedMessage
    id="camera.swipeInstruction6"
    description="Your surroundings are brightly lit"
    defaultMessage="Your surroundings are brightly lit"
  />,
  swipeInstructionTitle:
  <FormattedMessage
    id="camera.swipeInstructionTitle"
    description="Capture your smile perfectly"
    defaultMessage="Capture your smile perfectly"
  />,
  swipeInstructionGuideLine:
  <FormattedMessage
    id="camera.swipeInstructionGuideLine"
    description="Follow these tips for an accurate assessment of your smile."
    defaultMessage="Follow these tips for an accurate assessment of your smile."
  />,
  successToastMessage:
  <FormattedMessage
    id="camera.successToastMessage"
    description="You've successfully added a photo"
    defaultMessage="You've successfully added a photo"
  />,
  errorToastMessage:
  <FormattedMessage
    id="camera.errorToastMessage"
    description="Oops! Photo can't be used. Please review our guide and try again"
    defaultMessage="Oops! Photo can't be used. Please review our guide and try again"
  />,
  missingTeethToastMessage:
  <FormattedMessage
    id="camera.missingTeethToastMessage"
    description="Uh oh! That doesn’t look like a picture of your teeth. Please try again!"
    defaultMessage="Uh oh! That doesn’t look like a picture of your teeth. Please try again!"
  />,
  positionInstructionOne:
  <FormattedMessage
    id="OBTAI_PhotoUpload1_facePositionInstruction"
    description="Position your smile within blue guide"
    defaultMessage="Position your smile within blue guide"
  />,
  positionInstructionTwo:
  <FormattedMessage
    id="OBTAI_PhotoUpload3_facePositionInstruction"
    description="Position your smile within blue guide. Tip: Flip device 180° for easy viewing"
    defaultMessage="Position your smile within blue guide. Tip: Flip device 180° for easy viewing"
  />,
  positionInstructionOBT4:
  <FormattedMessage
    id="camera.positionInstructionOBT4"
    description="Position your smile within guide outline"
    defaultMessage="Position your smile within guide outline"
  />,
  positionInstructionZenSmart:
  <FormattedMessage
    id="camera.positionInstructionZenSmart"
    description="Position your smile within guide outline"
    defaultMessage="Position your smile within guide outline"
  />,
  positionInstructionThree:
  <FormattedMessage
    id="OBTAI_PhotoUpload4_facePositionInstruction"
    description="Place finger on camera button and turn head to the right. Position smile within blue guide."
    defaultMessage="Place finger on camera button and turn head to the right. Position smile within blue guide."
  />,
  initializingZenSmart:
  <FormattedMessage
    id="camera.initializingZenSmart"
    description="Initialising auto capture..."
    defaultMessage="Initialising auto capture..."
  />,
  detectingZenSmart:
  <FormattedMessage
    id="camera.detectingZenSmart"
    description="Detecting...."
    defaultMessage="Detecting...."
  />,
  moveToTheLeftZenSmart:
  <FormattedMessage
    id="camera.moveToTheLeftZenSmart"
    description="Move your smile to the left"
    defaultMessage="Move your smile to the left"
  />,
  moveToTheRightZenSmart:
  <FormattedMessage
    id="camera.moveToTheRightZenSmart"
    description="Move your smile to the right"
    defaultMessage="Move your smile to the right"
  />,
  lipsBlockingZenSmart:
  <FormattedMessage
    id="camera.lipsBlockingZenSmart"
    description="Ensure lips do not block!"
    defaultMessage="Ensure lips do not block!"
  />,
  lookingGoodZenSmart:
  <FormattedMessage
    id="camera.lookingGoodZenSmart"
    description="Looking good! Your teeth are perfectly placed"
    defaultMessage="Looking good! Your teeth are perfectly placed"
  />,
  lowLightZenSmart:
  <FormattedMessage
    id="camera.lowLightZenSmart"
    description="Light is too low to capture!"
    defaultMessage="Light is too low to capture!"
  />,
  moveCloserToCameraZenSmart:
  <FormattedMessage
    id="camera.moveCloserToCameraZenSmart"
    description="Move Closer to the Camera"
    defaultMessage="Move Closer to the Camera"
  />,
  faceDirectionFront:
  <FormattedMessage
    id="camera.faceDirectionFront"
    description="Face forward"
    defaultMessage="Face forward"
  />,
  faceDirectionRight:
  <FormattedMessage
    id="camera.faceDirectionRight"
    description="Turn to the side(Right)"
    defaultMessage="Turn to the side(Right)"
  />,
  needOfPhotos:
  <FormattedMessage
    id="OBTAI_PhotoUpload1_needOfPhotos"
    description="Why take photos?"
    defaultMessage="Why take photos?"
  />,
  photoCount1:
  <FormattedMessage
    id="camera.photoCount1"
    description="Photo 1 of 4"
    defaultMessage="Photo 1 of 4"
  />,
  photoCount2:
  <FormattedMessage
    id="camera.photoCount2"
    description="Photo 2 of 4"
    defaultMessage="Photo 1 of 4"
  />,
  photoCount3:
  <FormattedMessage
    id="camera.photoCount3"
    description="Photo 3 of 4"
    defaultMessage="Photo 1 of 4"
  />,
  photoCount4:
  <FormattedMessage
    id="camera.photoCount4"
    description="Photo 4 of 4"
    defaultMessage="Photo 1 of 4"
  />,
  swipeUp:
  <FormattedMessage
    id="camera.swipeUp"
    description="See more details"
    defaultMessage="See more details"
  />
};

export const otpCardTranslations = {
  successful:
  <FormattedMessage
    id="otpCard.successful"
    description="Successful!"
    defaultMessage="Successful!"
  />,
  otpNotReceivedQuestion:
  <FormattedMessage
    id="otpCard.otpNotReceived"
    description="Didn’t receive your OTP?"
    defaultMessage="Didn’t receive your OTP?"
  />,
  resend:
  <FormattedMessage
    id="otpCard.resend"
    description="Resend"
    defaultMessage="Resend"
  />,
  submit:
  <FormattedMessage
    id="otpCard.submit"
    description="Resend"
    defaultMessage="Resend"
  />,
  invalidOtpMessage:
  <FormattedMessage
    id="otpCard.invalidOtpMessage"
    description="Invalid OTP"
    defaultMessage="Invalid OTP"
  />,
  tooManyRequestsMessage:
  <FormattedMessage
    id="otpCard.tooManyRequestsMeassge"
    description="Too many requests, please try again later."
    defaultMessage="Too many requests, please try again later."
  />,
  userBlockMessage:
  <FormattedMessage
    id="otpCard.userBlockMessage"
    description="You have been blocked"
    defaultMessage="You have been blocked"
  />
};

export const overlayScreenTranslations = {
  title:
  <FormattedMessage
    id="OBTAI_PhotoUpload1_popUp_title"
    description="Why take pictures??"
    defaultMessage="Why take pictures?"
  />,
  questionOne:
  <FormattedMessage
    id="OBTAI_PhotoUpload1_popUp_questionOne"
    description="Why do you need photos of my teeth?"
    defaultMessage="Why do you need photos of my teeth?"
  />,
  teensQuestionOne:
  <FormattedMessage
    id="camera.overlayQuestionOneTeens"
    description="Why do you need photos of my child’s teeth?"
    defaultMessage="Why do you need photos of my child’s teeth?"
  />,
  questionTwo:
  <FormattedMessage
    id="OBTAI_PhotoUpload1_popUp_questionTwo"
    description="Are my photos safe with Zenyum?"
    defaultMessage="Are my photos safe with Zenyum?"
  />,
  teensQuestionTwo:
  <FormattedMessage
    id="camera.overlayQuestionTwoTeens"
    description="Are these photos safe with Zenyum?"
    defaultMessage="Are these photos safe with Zenyum?"
  />,
  answerOne:
  <FormattedMessage
    id="OBTAI_PhotoUpload1_popUp_answerOne"
    description="So our dentists can evaluate your suitability for Zenyum Invisible Braces, as they aren’t an option for everyone. Your photos will help us provide the best treatment advice for your unique case."
    defaultMessage="So our dentists can evaluate your suitability for Zenyum Invisible Braces, as they aren’t an option for everyone. Your photos will help us provide the best treatment advice for your unique case."
  />,
  teensAnswerOne:
  <FormattedMessage
    id="camera.overlayAnswerOneTeens"
    description="Photos are used to assess if Zenyum Invisible Braces is suitable for your child, and to help us recommend the best treatment options. "
    defaultMessage="Photos are used to assess if Zenyum Invisible Braces is suitable for your child, and to help us recommend the best treatment options. "
  />,
  answerTwo:
  <FormattedMessage
    id="OBTAI_PhotoUpload1_popUp_answerTwo"
    description="Yes, absolutely! We only use your photos for medical reasons, and never for marketing purposes."
    defaultMessage="Yes, absolutely! We only use your photos for medical reasons, and never for marketing purposes."
  />,
  teensAnswerTwo:
  <FormattedMessage
    id="camera.overlayAnswerTwoTeens"
    description="Yes, absolutely! We only use these photos for medical reasons, and never for marketing purposes."
    defaultMessage="Yes, absolutely! We only use these photos for medical reasons, and never for marketing purposes."
  />,
  answerOneOBT4:
  <FormattedMessage
    id="camera.answerOneOBT4"
    description="To assess your suitability for Zenyum Invisible Braces, as they aren’t an option for everyone. Your photos will help provide the best treatment advice for your unique case."
    defaultMessage="To assess your suitability for Zenyum Invisible Braces, as they aren’t an option for everyone. Your photos will help provide the best treatment advice for your unique case."
  />,
  continueBtn:
  <FormattedMessage
    id="OBTAI_PhotoUpload1_popUp_continueBtn"
    description="Continue"
    defaultMessage="Continue"
  />,
  headLine:
  <FormattedMessage
    id="OBT_Desktop_To_Mobile_QR_Code_title"
    description="Switch to your mobile phone for easier photo-taking"
    defaultMessage="Switch to your mobile phone for easier photo-taking"
  />,
  qrCodeWarningText:
  <FormattedMessage
    id="OBT_Desktop_To_Mobile_QR_Code_qrCodeWarningText"
    description="To protect your privacy, do not disclose the QR code to anyone"
    defaultMessage="To protect your privacy, do not disclose the QR code to anyone"
  />,
  description:
  <FormattedMessage
    id="OBT_Desktop_To_Mobile_QR_Code_Description"
    description="Scan the QR code to continue your free assessment on your mobile phone."
    defaultMessage="Scan the QR code to continue your free assessment on your mobile phone."
  />,
  buttonText:
  <FormattedMessage
    id="OBT_Desktop_To_Mobile_QR_Code_Button_text"
    description="Continue on mobile"
    defaultMessage="Continue on mobile"
  />,
  buttonTextDesktop:
  <FormattedMessage
    id="OBT_Desktop_To_Mobile_QR_Code_Button_text_Desktop"
    description="Stay on desktop"
    defaultMessage="Stay on desktop"
  />,
  scanMessage:
  <FormattedMessage
    id="OBT_Desktop_To_Mobile_QR_Code_Scan_Message"
    description="Continue on mobile phone"
    defaultMessage="Continue on mobile phone"
  />,
  desktopMessage:
  <FormattedMessage
    id="OBT_Desktop_To_Mobile_QR_Code_Desktop_Message"
    description="You can now continue your free assessment on your mobile phone."
    defaultMessage="You can now continue your free assessment on your mobile phone."
  />,
  mobileSuccessMessage:
  <FormattedMessage
    id="OBT_Desktop_To_Mobile_QR_Code_Mobile_Success_Message"
    description="Continue your free assessment here."
    defaultMessage="Continue your free assessment here."
  />,
  backbutton:
  <FormattedMessage
    id="OBT_Desktop_To_Mobile_QR_Code_Back_Button"
    description="Back"
    defaultMessage="Back"
  />,
  retake:
  <FormattedMessage
    id="camera.retake"
    description="Please retake photo."
    defaultMessage="Please retake photo."
  />,
  understood:
  <FormattedMessage
    id="camera.understood"
    description="Understood"
    defaultMessage="Understood"
  />,
  completionMessage:
  <FormattedMessage
    id="camera.completionMessage"
    description="Congratulations on completing your photo taking session! Press “Continue” to proceed to Zenyum Smile Journey."
    defaultMessage="Congratulations on completing your photo taking session! Press “Continue” to proceed to Zenyum Smile Journey."
  />,
  landscapeMsgPsst:
  <FormattedMessage
    id="camera.landscapeMsgPsst"
    description="Psst!"
    defaultMessage="Psst!"
  />,
  landscapeMsgContent:
  <FormattedMessage
    id="camera.landscapeMsgContent"
    description="Before you snap away, please rotate your phone to take pictures using portrait mode to avoid cropped or distorted images."
    defaultMessage="Before you snap away, please rotate your phone to take pictures using portrait mode to avoid cropped or distorted images."
  />
};

export const smileCaptureScreenTranslations = {
  needHelp:
  <FormattedMessage
    id="smileCaptureScreen.needHelp"
    description="Need some help?"
    defaultMessage="Need some help?"
  />,
  swipeInstructionMainTitle:
  <FormattedMessage
    id="smileCaptureScreen.swipeInstructionMainTitle"
    description="How to capture your smile perfectly"
    defaultMessage="How to capture your smile perfectly"
  />
};

export const updatedThanksScreenTranslations = {
  thankYou:
  <FormattedMessage
    id="updatedThanks.thankYou"
    description="Thank You!"
    defaultMessage="Thank You!"
  />,
  thankYouMessage:
  <FormattedMessage
    id="updatedThanks.thankYouMessage"
    description="We’ve sent you an e-mail and will call you in 2-3 days if you are suitable."
    defaultMessage="We’ve sent you an e-mail and will call you in 2-3 days if you are suitable."
  />,
  whatsappUs:
  <FormattedMessage
    id="updatedThanks.whatsappUs"
    description="WhatsApp Us"
    defaultMessage="WhatsApp Us"
  />,
  learnMore:
  <FormattedMessage
    id="updatedThanks.learnMore"
    description="Learn More"
    defaultMessage="Learn More"
  />,
  followTxt:
  <FormattedMessage
    id="updatedThanks.followTxt"
    description="Follow us to get more smile updates!"
    defaultMessage="Follow us to get more smile updates!"
  />,
  zhBlog1:
  <FormattedMessage
    id="updatedThanks.zhBlog1"
    description="如何為你節省費用"
    defaultMessage="如何為你節省費用"
  />,
  zhBlog2:
  <FormattedMessage
    id="updatedThanks.zhBlog2"
    description="真實用家分享"
    defaultMessage="真實用家分享"
  />,
  enBlog1:
  <FormattedMessage
    id="updatedThanks.enBlog1"
    description="How to Spend 70% Less on Invisible Braces"
    defaultMessage="How to Spend 70% Less on Invisible Braces"
  />,
  enBlog2:
  <FormattedMessage
    id="updatedThanks.enBlog2"
    description="5 Recommendations For Selecting An Invisible Braces Provider"
    defaultMessage="5 Recommendations For Selecting An Invisible Braces Provider"
  />,
  lineBtn:
  <FormattedMessage
    id="updatedThanks.lineBtn"
    description="Add us on Line!"
    defaultMessage="Add us on Line!"
  />,
  zaloBtn:
  <FormattedMessage
    id="updatedThanks.zaloBtn"
    description="Add us on Zalo!"
    defaultMessage="Add us on Zalo!"
  />,
  zaloInstructionMsg:
  <FormattedMessage
    id="updatedThanks.zaloInstructionMsg"
    description="Add Zenyum on Zalo so we can contact you with your results shortly"
    defaultMessage="Add Zenyum on Zalo so we can contact you with your results shortly"
  />,
  lineInstructionMsg:
  <FormattedMessage
    id="updatedThanks.lineInstructionMsg"
    description="Add us on Line, so we can contact you with your results shortly"
    defaultMessage="Add us on Line, so we can contact you with your results shortly"
  />
};

export const updatedHelloScreenTranslations = {
  bannerSlogan:
  <FormattedMessage
    id="updatedHelloScreen.bannerSlogan"
    description="Is ZenyumClear &trade; Aligners suitable for me?"
    defaultMessage="Is ZenyumClear &trade; Aligners suitable for me?"
  />,
  bannerSloganTaiwan:
  <FormattedMessage
    id="updatedHelloScreen.bannerSloganTaiwan"
    description="隱形牙套嗎？"
    defaultMessage="隱形牙套嗎？"
  />,
  assesmentTitle:
  <FormattedMessage
    id="updatedHelloScreen.assesmentTitle"
    description="Free Assessment"
    defaultMessage="Free Assessment"
  />,
  assesmentSubTitle:
  <FormattedMessage
    id="updatedHelloScreen.assesmentSubTitle"
    description="It takes only 5 mins"
    defaultMessage="It takes only 5 mins"
  />,
  namePlaceholder:
  <FormattedMessage
    id="updatedHelloScreen.namePlaceholder"
    description="Full Name"
    defaultMessage="Full Name"
  />,
  emailPlaceholder:
  <FormattedMessage
    id="updatedHelloScreen.emailPlaceholder"
    description="Email"
    defaultMessage="Email"
  />,
  numberPlaceholder:
  <FormattedMessage
    id="updatedHelloScreen.numberPlaceholder"
    description="Mobile Number"
    defaultMessage="Mobile Number"
  />,
  updatedCheckboxText:
  <FormattedMessage
    id="updatedHelloScreen.updatedCheckboxText"
    description="Checkbox text"
    defaultMessage="By Checking this box, you agree to be contacted for further information. Your personal information will be handled in accordance to our privacy policy"
  />,
  startAssesmentBtn:
  <FormattedMessage
    id="updatedHelloScreen.startAssesmentBtn"
    description="Start Assessment"
    defaultMessage="Start Assessment"
  />,
  confidentSmile:
  <FormattedMessage
    id="updatedHelloScreen.confidentSmile"
    description="Confident Smile"
    defaultMessage="Confident Smile"
  />,
  powerToDrive:
  <FormattedMessage
    id="updatedHelloScreen.powerToDrive"
    description="Is the power to drive good things"
    defaultMessage="Is the power to drive good things"
  />,
  brightDay:
  <FormattedMessage
    id="updatedHelloScreen.brightDay"
    description="Let's change your smile today to make your every day bright. and more confident than ever"
    defaultMessage="Let's change your smile today to make your every day bright. and more confident than ever"
  />

};

export const helloFormScreenTranslations = {
  pageTitle:
  <FormattedMessage
    id="helloFormScreenTranslations.pageTitle"
    description="Lets get to know you first"
    defaultMessage="Lets get to know you first"
  />,
  contactInfo:
  <FormattedMessage
    id="helloFormScreenTranslations.contactInfo"
    description="Contact details"
    defaultMessage="Contact details"
  />,
  fullName:
  <FormattedMessage
    id="helloFormScreenTranslations.fullName"
    description="Full Name"
    defaultMessage="Full Name"
  />,
  emailAddress:
  <FormattedMessage
    id="helloFormScreenTranslations.emailAddress"
    description="Email Address"
    defaultMessage="Email Address"
  />,
  phoneNumber:
  <FormattedMessage
    id="helloFormScreenTranslations.phoneNumber"
    description="Phone Number"
    defaultMessage="Phone Number"
  />,
  whatsAppNumber:
  <FormattedMessage
    id="helloFormScreenTranslations.whatsAppNumber"
    description="WhatsApp Number"
    defaultMessage="WhatsApp Number"
  />,
  weChatAppNumber:
  <FormattedMessage
    id="helloFormScreenTranslations.weChatAppNumber"
    description="WeChat Number"
    defaultMessage="WeChat Number"
  />,
  termsAndConditions:
  <FormattedMessage
    id="helloFormScreenTranslations.termsAndConditions"
    description="By clicking here you accept our Terms & Conditions and Privacy Policy"
    defaultMessage="By clicking here you accept our Terms & Conditions and Privacy Policy"
  />,
  startAssesment:
  <FormattedMessage
    id="helloFormScreenTranslations.startAssesment"
    description="Start Assessment"
    defaultMessage="Start Assessment"
  />,
  followUsOnLine:
  <FormattedMessage
    id="helloFormScreenTranslations.followUs"
    description="Follow us on Line @zenyumth for more information"
    defaultMessage="Follow us on Line @zenyumth for more information"
  />,
  contactNumber:
  <FormattedMessage
    id="helloFormScreenTranslations.contactNumber"
    description="We will contact you by number 02-506-1027 / 02-171-2416"
    defaultMessage="We will contact you by number 02-506-1027 / 02-171-2416"
  />
};

export const userFormTranslations = {
  whatsAppContact:
  <FormattedMessage
    id="userDetailsForm.whatsAppContact"
    description="I agree to be contacted by Zenyum via WhatsApp and other channels"
    defaultMessage="I agree to be contacted by Zenyum via WhatsApp and other channels"
  />,
  popUpBoothTermsAndCondition:
  <FormattedMessage
    id="userDetailsForm.popUpBoothTermsAndCondition"
    description="I have read and accept the Terms & Conditions and Privacy Policy"
    defaultMessage="I have read and accept the Terms & Conditions and Privacy Policy"
  />,
  submitForm:
  <FormattedMessage
    id="userDetailsForm.submitForm"
    description="Submit"
    defaultMessage="Submit"
  />,
  title:
  <FormattedMessage
    id="userDetailsForm.title"
    description="Uh-oh! Looks like you’re under 18 years old"
    defaultMessage="Uh-oh! Looks like you’re under 18 years old"
  />,
  details:
  <FormattedMessage
    id="userDetailsForm.details"
    description="Your teeth are still developing, so we don’t recommend Zenyum Invisible Braces just yet. But do leave your details to get on the waiting list!"
    defaultMessage="Your teeth are still developing, so we don’t recommend Zenyum Invisible Braces just yet. But do leave your details to get on the waiting list!"
  />,
  birthday:
  <FormattedMessage
    id="userDetailsForm.birthDay"
    description="Birthday"
    defaultMessage="Birthday"
  />,
  date:
  <FormattedMessage
    id="userDetailsForm.date"
    description="Date"
    defaultMessage="Date"
  />,
  month:
  <FormattedMessage
    id="userDetailsForm.month"
    description="Month"
    defaultMessage="Month"
  />,
  year:
  <FormattedMessage
    id="userDetailsForm.year"
    description="Year"
    defaultMessage="Year"
  />,
  selectMonth:
  <FormattedMessage
    id="userDetailsForm.selectMonth"
    description="Select Month"
    defaultMessage="Select Month"
  />,
  saveMonth:
  <FormattedMessage
    id="userDetailsForm.saveMonth"
    description="Save"
    defaultMessage="Save"
  />,
  photoUploadFailed:
  <FormattedMessage
    id="userDetailsForm.photoUploadFailed"
    description="Failed to upload the images"
    defaultMessage="Save"
  />,
  submitFailed:
  <FormattedMessage
    id="userDetailsForm.submitFailed"
    description="Failed to submit the user details"
    defaultMessage="Failed to submit the user details"
  />
};

export const newThankyouScreenTranslations = {
  thankYou:
  <FormattedMessage
    id="newThankyouScreen.thankyou"
    description="Thank you"
    defaultMessage="Thank you!"
  />,
  thankyouHeader:
  <FormattedMessage
    id="newThankyouScreen.thankyouHeader"
    description="Your smile assessment is complete!"
    defaultMessage="Your smile assessment is complete!"
  />,
  aiAcceptedThankyouHeader:
  <FormattedMessage
    id="newThankyouScreen.aiAcceptedThankyouHeader"
    description="Your dream smile, coming right up!"
    defaultMessage="Your dream smile, coming right up!"
  />,
  thanks:
  <FormattedMessage
    id="newThankyouScreen.thanks"
    description="Thanks"
    defaultMessage="Thanks"
  />,
  nonPlusMarketsContent:
  <FormattedMessage
    id="newThankyouScreen.nonMarketsContent"
    description="We’re excited to help you and get a new smile! Our dental experts will review your pictures to see if treatment is suitable. Look forward to hearing from us within 2-3 working days. "
    defaultMessage="We’re excited to help you and get a new smile! Our dental experts will review your pictures to see if treatment is suitable. Look forward to hearing from us within 2-3 working days. "
  />,
  plusMarketsContent:
  <FormattedMessage
    id="newThankyouScreen.plusMarketContent"
    description="Now our dentists will review your pictures to determine which treatment plan can help you get your dream smile."
    defaultMessage="Now our dentists will review your pictures to determine which treatment plan can help you get your dream smile."
  />,
  zenyumClear:
  <FormattedMessage
    id="thankyouScreen.zenyumClear"
    description="ZenyumClear™:"
    defaultMessage="ZenyumClear™:"
  />,
  clearDescription:
  <FormattedMessage
    id="newThankyouScreen.clearDescription"
    description="At only $2,400, for simpler teeth movement and may take from 3 - 9 months to fully straighten. "
    defaultMessage="At only $2,400, for simpler teeth movement and may take from 3 - 9 months to fully straighten. "
  />,
  zenyumClearPlus:
  <FormattedMessage
    id="thankyouScreen.zenyumClearPlus"
    description="ZenyumClear™ Plus:"
    defaultMessage="ZenyumClear™ Plus:"
  />,
  clearPlusDescription:
  <FormattedMessage
    id="newThankyouScreen.clearPlusDescription"
    description="From $3,200 to $3,800, for more extensive teeth movements and may take up to 15 months to fully straighten. "
    defaultMessage="From $3,200 to $3,800, for more extensive teeth movements and may take up to 15 months to fully straighten. "
  />,
  zenyumClearExtra:
  <FormattedMessage
    id="thankyouScreen.zenyumClearExtra"
    description="ZenyumClear™ Extra:"
    defaultMessage="ZenyumClear™ Extra:"
  />,
  clearExtraDescription:
  <FormattedMessage
    id="newThankyouScreen.clearExtraDescription"
    description="From $3,200 to $3,800, for more extensive teeth movements and may take up to 15 months to fully straighten. "
    defaultMessage="From $3,200 to $3,800, for more extensive teeth movements and may take up to 15 months to fully straighten. "
  />,
  learnMoreBtn:
  <FormattedMessage
    id="newThankyouScreen.learnMoreBtn"
    description="Learn More"
    defaultMessage="Learn More"
  />,
  shopOtherProductsBtn:
  <FormattedMessage
    id="newThankyouScreen.shopOtherProductsBtn"
    description="Shop other products"
    defaultMessage="Shop other products"
  />,
  stayTuned:
  <FormattedMessage
    id="newThankyouScreen.stayTuned"
    description="Stay tuned as we’ll get back to you within 2 - 3 working days! "
    defaultMessage="Stay tuned as we’ll get back to you within 2 - 3 working days! "
  />,
  joinFriends:
  <FormattedMessage
    id="newThankyouScreen.joinFriends"
    description="Join friends now!"
    defaultMessage="Join friends now!"
  />,
  lineAppButton:
  <FormattedMessage
    id="OBTAI_PassedBookLINE_lineAppButton_label"
    description="LINE Schedule a Call!"
    defaultMessage="LINE Schedule a Call"
  />,
  socialMediaText:
  <FormattedMessage
    id="newThankyouScreen.socialMediaText"
    description="Join friends now!"
    defaultMessage="Join friends now!"
  />,
  whatsappLabel:
  <FormattedMessage
    id="newThankyouScreen.whatsappLabel"
    description="Reach us on WhatsApp"
    defaultMessage="Reach us on WhatsApp"
  />,
  thanksDetails1:
  <FormattedMessage
    id="newThankyouScreen.thanksDetails1"
    description="Now our dentists will review your pictures to determine which treatment plan can help you get your dream smile."
    defaultMessage="Now our dentists will review your pictures to determine which treatment plan can help you get your dream smile."
  />,
  thanksDetails2:
  <FormattedMessage
    id="newThankyouScreen.thanksDetails2"
    description="Stay tuned! We’ll get back to you within 2 - 3 working days!"
    defaultMessage="Stay tuned! We’ll get back to you within 2 - 3 working days!"
  />,
  doneButton:
  <FormattedMessage
    id="newThankyouScreen.doneButton"
    description="Done"
    defaultMessage="Done"
  />,
  exploreProducts:
  <FormattedMessage
    id="OBTAI_ThanksScreen_discoverProductsButton_label"
    description="Discover more Zenyum products"
    defaultMessage="Discover more Zenyum products"
  />,
  thanksMessage:
  <FormattedMessage
    id="OBTAI_ThanksScreen_thanksMessage"
    description="Our doctors will assess your photos. We’ll get back to you in 2–3 days. Thanks for your patience. Speak soon! "
    defaultMessage="Our doctors will assess your photos. We’ll get back to you in 2–3 days. Thanks for your patience. Speak soon! "
  />,
  singlePhotoThanksMessage:
  <FormattedMessage
    id="thankyouScreen.singlePhotoThanksMessage"
    description="One photo was enough to see that you are eligible for Zenyum Invisible braces!"
    defaultMessage="One photo was enough to see that you are eligible for Zenyum Invisible braces!"
  />,
  singlePhotoZencalFlowThanksMessage:
  <FormattedMessage
    id="thankyouScreen.singlePhotoZencalFlowThanksMessage"
    description="During the call, we’ll share more details about your Zenyum smile journey and possible slots for your first consultation. In the meantime, here’s some information about our treatment options."
    defaultMessage="During the call, we’ll share more details about your Zenyum smile journey and possible slots for your first consultation. In the meantime, here’s some information about our treatment options."
  />,
  zenFlowThanksMessage:
  <FormattedMessage
    id="OBTAI_ThanksScreen_zenFlow_thanksMessage"
    description="Now our dentists will review your pictures to determine which treatment plan can help you get your dream smile."
    defaultMessage="Now our dentists will review your pictures to determine which treatment plan can help you get your dream smile."
  />,
  photoSubmissionDisabledThanksMessage:
  <FormattedMessage
    id="thankyouScreen.photoSubmissionDisabledThanksMessage"
    description="We have received your information and the Smile Specialist will contact you via the official LINE later!"
    defaultMessage="We have received your information and the Smile Specialist will contact you via the official LINE later!"
  />,
  callInstruction:
  <FormattedMessage
    id="thankyouScreen.callInstruction"
    description="What's Next? Let us know when is a good time for us to call you to chat more about your Smile Journey."
    defaultMessage="What's Next? Let us know when is a good time for us to call you to chat more about your Smile Journey."
  />,
  zenyumProductsHeader:
  <FormattedMessage
    id="thankyouScreen.zenyumProductsHeader"
    description="Zenyum’s full range of products:"
    defaultMessage="Zenyum’s full range of products:"
  />
};

export const thankyouScreenNewTranslations = {
  thankyouHeader:
  <FormattedMessage
    id="thankyouScreenNew.thankyouHeader"
    description="Thanks for submitting your smile assessment!"
    defaultMessage="Thanks for submitting your smile assessment!"
  />,
  teensThankyouHeader:
  <FormattedMessage
    id="thankyouScreenNew.teensThankyouHeader"
    description="Thanks for sending in your child’s smile assessment!"
    defaultMessage="Thanks for sending in your child’s smile assessment!"
  />,
  message:
  <FormattedMessage
    id="thankyouScreenNew.message"
    description="Sit back, relax and let us do the work! Your smile will now be reviewed by a team of specialists to determine which treatment option is best for you."
    defaultMessage="Sit back, relax and let us do the work! Your smile will now be reviewed by a team of specialists to determine which treatment option is best for you."
  />,
  teensMessage:
  <FormattedMessage
    id="thankyouScreenNew.teensMessage"
    description="Congratulations on taking the first step towards your child's confident smile! Sit back, relax, and let us review their smile to recommend the best treatment options."
    defaultMessage="Congratulations on taking the first step towards your child's confident smile! Sit back, relax, and let us review their smile to recommend the best treatment options."
  />,
  paymentQuestion:
  <FormattedMessage
    id="thankyouScreenNew.paymentQuestion"
    description="Did someone say flexible payments?"
    defaultMessage="Did someone say flexible payments?"
  />,
  paymentAnswer:
  <FormattedMessage
    id="thankyouScreenNew.paymentAnswer"
    description="Yes! Pay it your way with our flexible payment options."
    defaultMessage="Yes! Pay it your way with our flexible payment options."
  />,
  teensPaymentAnswer:
  <FormattedMessage
    id="thankyouScreenNew.teensPaymentAnswer"
    description="Yes! Pay for your child’s Zenyum Invisible Braces treatment with our flexible payment options."
    defaultMessage="Yes! Pay for your child’s Zenyum Invisible Braces treatment with our flexible payment options."
  />,
  treatmentQuestion:
  <FormattedMessage
    id="thankyouScreenNew.treatmentQuestion"
    description="Which Zenyum treatment is right for me?"
    defaultMessage="Which Zenyum treatment is right for me?"
  />,
  teensTreatmentQuestion:
  <FormattedMessage
    id="thankyouScreenNew.teensTreatmentQuestion"
    description="Which Zenyum treatment is right for your child?"
    defaultMessage="Which Zenyum treatment is right for your child?"
  />,
  treatmentAnswer:
  <FormattedMessage
    id="thankyouScreenNew.treatmentAnswer"
    description="We offer ZenyumClear™ for mild cases, and ZenyumClear™ Plus for more complex cases as recommended by the treating dentists."
    defaultMessage="We offer ZenyumClear™ for mild cases, and ZenyumClear™ Plus for more complex cases as recommended by the treating dentists."
  />,
  zenyumClear:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClear"
    description="ZenyumClear™"
    defaultMessage="ZenyumClear™"
  />,
  zenyumClearContent1:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearContent1"
    description="S$2,590*"
    defaultMessage="S$2,590*"
  />,
  zenyumClearContent2:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearContent2"
    description="Mild to moderate crowding, overbite, gaps"
    defaultMessage="Mild to moderate crowding, overbite, gaps"
  />,
  zenyumClearContent3:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearContent3"
    description="Cannot fix bite"
    defaultMessage="Cannot fix bite"
  />,
  zenyumClearContent4:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearContent4"
    description="Treatment duration: 3 - 9 months"
    defaultMessage="Treatment duration: 3 - 9 months"
  />,
  zenyumClearContent5:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearContent5"
    description="Minimal or no attachments"
    defaultMessage="Minimal or no attachments"
  />,
  zenyumClearContent6:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearContent6"
    description="No extractions"
    defaultMessage="No extractions"
  />,
  zenyumClearPlus:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearPlus"
    description="ZenyumClear™ Plus"
    defaultMessage="ZenyumClear™ Plus"
  />,
  zenyumClearPlusContent1:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearPlusContent1"
    description="S$3,600 - S$3,900*"
    defaultMessage="S$3,600 - S$3,900*"
  />,
  zenyumClearPlusContent2:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearPlusContent2"
    description="Moderate to severe crowding, overbite, gaps"
    defaultMessage="Moderate to severe crowding, overbite, gaps"
  />,
  zenyumClearPlusContent3:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearPlusContent3"
    description="Can fix bite"
    defaultMessage="Can fix bite"
  />,
  zenyumClearPlusContent4:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearPlusContent4"
    description="Treatment duration: 9 - 15 months"
    defaultMessage="Treatment duration: 9 - 15 months"
  />,
  zenyumClearPlusContent5:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearPlusContent5"
    description="Attachments on multiple teeth"
    defaultMessage="Attachments on multiple teeth"
  />,
  zenyumClearPlusContent6:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearPlusContent6"
    description="Extractions if necessary"
    defaultMessage="Extractions if necessary"
  />,
  zenyumClearExtra:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearExtra"
    description="ZenyumClear™ Extra"
    defaultMessage="ZenyumClear™ Extra"
  />,
  zenyumClearExtraContent1:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearExtraContent1"
    description="S$2,650"
    defaultMessage="S$2,650"
  />,
  zenyumClearExtraContent2:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearExtraContent2"
    description="Severe Crowding"
    defaultMessage="Severe Crowding"
  />,
  zenyumClearExtraContent3:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearExtraContent3"
    description="All teeth"
    defaultMessage="All teeth"
  />,
  zenyumClearExtraContent4:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearExtraContent4"
    description="Treatment duration: 2 - 4 years"
    defaultMessage="Treatment duration: 2 - 4 years"
  />,
  zenyumClearExtraContent5:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearExtraContent5"
    description="Attachments on most teeth"
    defaultMessage="Attachments on most teeth"
  />,
  zenyumClearExtraContent6:
  <FormattedMessage
    id="thankyouScreenNew.zenyumClearExtraContent6"
    description="Extractions will be performed if necessary"
    defaultMessage="Extractions will be performed if necessary"
  />,
  moreInfo:
  <FormattedMessage
    id="thankyouScreenNew.moreInfo"
    description="*Any charges or fees relating to services provided by the treating dentist (including but not limited to dental appointments and scan fees) shall be solely borne by you and payable directly to the clinic. Prices are captured on a biweekly basis and may be subject to change."
    defaultMessage="*Any charges or fees relating to services provided by the treating dentist (including but not limited to dental appointments and scan fees) shall be solely borne by you and payable directly to the clinic. Prices are captured on a biweekly basis and may be subject to change."
  />,
  whatsNext:
  <FormattedMessage
    id="thankyouScreenNew.whatsNext"
    description="What’s next?"
    defaultMessage="What’s next?"
  />,
  smileCosmetics:
  <FormattedMessage
    id="thankyouScreenNew.smileCosmetics"
    description="Discover our Smile Cosmetics"
    defaultMessage="Discover our Smile Cosmetics"
  />,
  redirectionText:
  <FormattedMessage
    id="thankyouScreenNew.redirectionText"
    description="Not you? Submit an assessment for yourself or another child"
    defaultMessage="Not you? Submit an assessment for <a>yourself</a> or <a>another child</a>"
  />,
  attachmentsInfo:
  <FormattedMessage
    id="thankyouScreenNew.attachmentsInfo"
    description="Attachments are small, temporary, tooth-coloured “anchors” bonded to specific positions on your teeth. They support a snug aligner fit for more precise movements and effective results. They will be removed at end of treatment."
    defaultMessage="Attachments are small, temporary, tooth-coloured “anchors” bonded to specific positions on your teeth. They support a snug aligner fit for more precise movements and effective results. They will be removed at end of treatment."
  />
};

export const competitionScreenTranslations = {
  introSlogan:
  <FormattedMessage
    id="competitionScreenTranslations.introSlogan"
    description="Win Ox-clusive prizes when you complete the pre-assessment:"
    defaultMessage="Win Ox-clusive prizes when you complete the pre-assessment:"
  />,
  titleMessage:
  <FormattedMessage
    id="competitionScreenTranslations.titleMessage"
    description="Show Your True Smile!"
    defaultMessage="Show Your True Smile!"
  />,
  startAssesmentBtn:
  <FormattedMessage
    id="competitionScreenTranslations.startAssesmentBtn"
    description="Spin The Wheel"
    defaultMessage="Spin The Wheel"
  />,
  thanksPagePrizeMsg:
  <FormattedMessage
    id="competitionScreenTranslations.thanksPagePrizeMsg"
    description="Your prize has been reserved and it will be reflected on our database when it's time for you to claim your new smile."
    defaultMessage="Your prize has been reserved and it will be reflected on our database when it's time for you to claim your new smile."
  />,
  winnerBanner:
  <FormattedMessage
    id="competitionScreenTranslations.winnerBanner"
    description="You are a winner!"
    defaultMessage="You are a winner!"
  />,
  reservedMsg:
  <FormattedMessage
    id="competitionScreenTranslations.reservedMsg"
    description="Your prize has been reserved!"
    defaultMessage="Your prize has been reserved!"
  />,
  intructionMsg:
  <FormattedMessage
    id="competitionScreenTranslations.intructionMsg"
    description="To claim your prize, you have to attend your dental consultation by 21st Feb,
    upon receiving your pre-assessment results,
    and make first payment by 28th Feb."
    defaultMessage="To claim your prize, you have to attend your dental consultation by 21st Feb,
    upon receiving your pre-assessment results,
    and make first payment by 28th Feb."
  />,
  claimBtn:
  <FormattedMessage
    id="competitionScreenTranslations.claimBtn"
    description="Claim"
    defaultMessage="Claim"
  />,
  offerOne:
  <FormattedMessage
    id="competitionScreenTranslations.offerOne"
    description="$168 off on ZenyumClear Aligners"
    defaultMessage="$168 off on ZenyumClear Aligners"
  />,
  offerTwo:
  <FormattedMessage
    id="competitionScreenTranslations.offerTwo"
    description="$128 off on ZenyumClear Aligners"
    defaultMessage="$128 off on ZenyumClear Aligners"
  />,
  offerThree:
  <FormattedMessage
    id="competitionScreenTranslations.offerThree"
    description="$88 off on ZenyumClear Aligners"
    defaultMessage="$88 off on ZenyumClear Aligners"
  />,
  offerFour:
  <FormattedMessage
    id="competitionScreenTranslations.offerFour"
    description="$188 off on ZenyumClear Aligners"
    defaultMessage="$188 off on ZenyumClear Aligners"
  />,
  offerFive:
  <FormattedMessage
    id="competitionScreenTranslations.offerFive"
    description="Refund of Dentist Consultation"
    defaultMessage="Refund of Dentist Consultation"
  />,
  offerSix:
  <FormattedMessage
    id="competitionScreenTranslations.offerSix"
    description="Rebate conultation fee"
    defaultMessage="Rebate conultation fee"
  />,
  offerSeven:
  <FormattedMessage
    id="competitionScreenTranslations.offerSeven"
    description="Happy New Year"
    defaultMessage="Happy New Year"
  />,
  offerEight:
  <FormattedMessage
    id="competitionScreenTranslations.offerEight"
    description="Happy New Year"
    defaultMessage="Happy New Year"
  />,
  reward:
  <FormattedMessage
    id="competitionScreenTranslations.reward"
    description="Your reward has been reserved!"
    defaultMessage="Your reward has been reserved!"
  />,
  reviewMessage:
  <FormattedMessage
    id="competitionScreenTranslations.reviewMessage"
    description="We’re excited to help you reveal your True Smile! Our dentists will review your pictures to see if treatment is suitable. Upon submission of your pre-assessment, we will be in touch within 2-3 working days."
    defaultMessage="We’re excited to help you reveal your True Smile! Our dentists will review your pictures to see if treatment is suitable. Upon submission of your pre-assessment, we will be in touch within 2-3 working days."
  />,
  passAssessment:
  <FormattedMessage
    id="competitionScreenTranslations.passAssessment"
    description="Your reward will be applied once you pass your FREE Smile Assessment! "
    defaultMessage="Your reward will be applied once you pass your FREE Smile Assessment! "
  />,
  stayTuned:
  <FormattedMessage
    id="competitionScreenTranslations.stayTuned"
    description="Stay tuned!"
    defaultMessage="Stay tuned!"
  />,
  tAndc:
  <FormattedMessage
    id="competitionScreenTranslations.tAndc"
    description="T&C Applies"
    defaultMessage="T&C Applies"
  />,
  confirmLineOne:
  <FormattedMessage
    id="competitionScreenTranslations.confirmLineOne"
    description="Your prize has been reserved!"
    defaultMessage="Your prize has been reserved!"
  />,
  confirmLineTwo:
  <FormattedMessage
    id="competitionScreenTranslations.confirmLineTwo"
    description="Your prize has been reserved!"
    defaultMessage="Your prize has been reserved!"
  />,
  confirmLineThree:
  <FormattedMessage
    id="competitionScreenTranslations.confirmLineThree"
    description="Your prize has been reserved!"
    defaultMessage="Your prize has been reserved!"
  />,
  confirmLineFour:
  <FormattedMessage
    id="competitionScreenTranslations.confirmLineFour"
    description="Your prize has been reserved!"
    defaultMessage="Your prize has been reserved!"
  />,
  confirmLineFive:
  <FormattedMessage
    id="competitionScreenTranslations.confirmLineFive"
    description="Your prize has been reserved!"
    defaultMessage="Your prize has been reserved!"
  />,
  confirmLineSix:
  <FormattedMessage
    id="competitionScreenTranslations.confirmLineSix"
    description="Your prize has been reserved!"
    defaultMessage="Your prize has been reserved!"
  />,
  confirmLineSeven:
  <FormattedMessage
    id="competitionScreenTranslations.confirmLineSeven"
    description="Your prize has been reserved!"
    defaultMessage="Your prize has been reserved!"
  />,
  confirmLineEight:
  <FormattedMessage
    id="competitionScreenTranslations.confirmLineEight"
    description="Your prize has been reserved!"
    defaultMessage="Your prize has been reserved!"
  />,
  essentialTAndCOne:
  <FormattedMessage
    id="competitionScreenTranslations.essentialTAndCOne"
    description="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
    Prizes are not transferrable or redeemable as cash."
    defaultMessage="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
Prizes are not transferrable or redeemable as cash."
  />,
  essentialTAndCTwo:
  <FormattedMessage
    id="competitionScreenTranslations.essentialTAndCTwo"
    description="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
    Prizes are not transferrable or redeemable as cash."
    defaultMessage="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
Prizes are not transferrable or redeemable as cash."
  />,
  essentialTAndCThree:
  <FormattedMessage
    id="competitionScreenTranslations.essentialTAndCThree"
    description="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
    Prizes are not transferrable or redeemable as cash."
    defaultMessage="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
Prizes are not transferrable or redeemable as cash."
  />,
  essentialTAndCFour:
  <FormattedMessage
    id="competitionScreenTranslations.essentialTAndCFour"
    description="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
    Prizes are not transferrable or redeemable as cash."
    defaultMessage="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
Prizes are not transferrable or redeemable as cash."
  />,
  essentialTAndCFive:
  <FormattedMessage
    id="competitionScreenTranslations.essentialTAndCFive"
    description="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
    Prizes are not transferrable or redeemable as cash."
    defaultMessage="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
Prizes are not transferrable or redeemable as cash."
  />,
  essentialTAndCSix:
  <FormattedMessage
    id="competitionScreenTranslations.essentialTAndCSix"
    description="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
    Prizes are not transferrable or redeemable as cash."
    defaultMessage="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
Prizes are not transferrable or redeemable as cash."
  />,
  essentialTAndCSeven:
  <FormattedMessage
    id="competitionScreenTranslations.essentialTAndCSeven"
    description="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
    Prizes are not transferrable or redeemable as cash."
    defaultMessage="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
Prizes are not transferrable or redeemable as cash."
  />,
  essentialTAndCEight:
  <FormattedMessage
    id="competitionScreenTranslations.essentialTAndCEight"
    description="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
    Prizes are not transferrable or redeemable as cash."
    defaultMessage="To redeem the prize, customer has to attend dentist appointment between 25th Jan - 21st Feb and make first payment by 28th Feb.
Prizes are not transferrable or redeemable as cash."
  />
};

export const carousalIntroScreenTranslations = {
  carousalIntroNextBtn:
  <FormattedMessage
    id="carousalIntroScreenTranslations.carousalIntroNextBtn"
    description="Next"
    defaultMessage="Next"
  />,
  carousalIntroSkipBtn:
  <FormattedMessage
    id="carousalIntroScreenTranslations.carousalIntroSkipBtn"
    description="Skip"
    defaultMessage="Skip"
  />,
  titleStep1:
  <FormattedMessage
    id="carousalIntroScreenTranslations.titleStep1"
    description="Transform your Smile"
    defaultMessage="Transform your Smile"
  />,
  contentStep1:
  <FormattedMessage
    id="carousalIntroScreenTranslations.contentStep1"
    description="Take our free assessment to find out if ZenyumClear™ Aligners can help you achieve the smile you want. It only takes five minutes!"
    defaultMessage="Take our free assessment to find out if ZenyumClear™ Aligners can help you achieve the smile you want. It only takes five minutes!"
  />,
  titleStep2:
  <FormattedMessage
    id="carousalIntroScreenTranslations.titleStep2"
    description="Tell us more"
    defaultMessage="Tell us more"
  />,
  contentStep2:
  <FormattedMessage
    id="carousalIntroScreenTranslations.contentStep2"
    description="First, answer four quick questions to help us better understand your concerns."
    defaultMessage="First, answer four quick questions to help us better understand your concerns."
  />,
  titleStep3:
  <FormattedMessage
    id="carousalIntroScreenTranslations.titleStep3"
    description="Show us your smile"
    defaultMessage="Show us your smile"
  />,
  contentStep3:
  <FormattedMessage
    id="carousalIntroScreenTranslations.contentStep3"
    description="Then you'll need to share some pictures of your current smile for our AI Generator to accurately assess."
    defaultMessage="Then you'll need to share some pictures of your current smile for our AI Generator to accurately assess."
  />
};

export const cameraPreviewTranslations = {
  title:
  <FormattedMessage
    id="cameraPreviewTranslations.title"
    description="Smile for the camera!"
    defaultMessage="Smile for the camera!"
  />,
  subTitle:
  <FormattedMessage
    id="cameraPreviewTranslations.subTitle"
    description="Take four pictures of your teeth for us to assess if our aligners are a good match for you."
    defaultMessage="Take four pictures of your teeth for us to assess if our aligners are a good match for you."
  />,
  imageTitle:
  <FormattedMessage
    id="cameraPreviewTranslations.imageTitle"
    description="Use the guide to align your teeth"
    defaultMessage="Use the guide to align your teeth"
  />,
  next:
  <FormattedMessage
    id="cameraPreviewTranslations.next"
    description="Take your pictures now"
    defaultMessage="Take your pictures now"
  />
};

export const secondarySmileQuestionsTranslations = {
  title:
  <FormattedMessage
    id="secondarySmileQuestionsTranslations.title"
    description="Tell us more about your smile!"
    defaultMessage="Tell us more about your smile!"
  />,
  subTitle:
  <FormattedMessage
    id="secondarySmileQuestionsTranslations.subTitle"
    description="Your information will only be used by our dentists for this review."
    defaultMessage="Your information will only be used by our dentists for this review."
  />,
  specificGoal:
  <FormattedMessage
    id="secondarySmileQuestionsTranslations.specificGoal"
    description="Any specific treatment goals?"
    defaultMessage="Any specific treatment goals?"
  />,
  placeholderExample:
  <FormattedMessage
    id="secondarySmileQuestionsTranslations.placeholderExample"
    description="E.g. I want straighter teeth. I just want better alignment. I don’t want gaps in my teeth."
    defaultMessage="E.g. I want straighter teeth. I just want better alignment. I don’t want gaps in my teeth."
  />,
  next:
  <FormattedMessage
    id="secondarySmileQuestionsTranslations.next"
    description="Next"
    defaultMessage="Next"
  />,
  query1:
  <FormattedMessage
    id="secondarySmileQuestionsTranslations.query1"
    description="what age group do you belong to?"
    defaultMessage="what age group do you belong to?"
  />,
  query2:
  <FormattedMessage
    id="secondarySmileQuestionsTranslations.query2"
    description="How would you describe your teeth?"
    defaultMessage="How would you describe your teeth?"
  />,
  query3:
  <FormattedMessage
    id="secondarySmileQuestionsTranslations.query3"
    description="What are your concerns when it comes to clear aligners?"
    defaultMessage="What are your concerns when it comes to clear aligners?"
  />,
  query2Hint:
  <FormattedMessage
    id="secondarySmileQuestionsTranslations.query2Hint"
    description="You may select more than 1 option"
    defaultMessage="You may select more than 1 option"
  />,
  query3Hint:
  <FormattedMessage
    id="secondarySmileQuestionsTranslations.query3Hint"
    description="You may select more than 1 option"
    defaultMessage="You may select more than 1 option"
  />
};

export const photoResultScreenTranslations = {
  photoSummary:
  <FormattedMessage
    id="photoResultScreenTranslations.photoSummary"
    description="Photo Summary"
    defaultMessage="Photo Summary"
  />,
  photoSummaryObtAi:
  <FormattedMessage
    id="OBTAI_PhotoSummary_title"
    description="One last step!"
    defaultMessage="One last step!"
  />,
  retake:
  <FormattedMessage
    id="photoResultScreenTranslations.retake"
    description="Retake"
    defaultMessage="Retake"
  />,
  submit:
  <FormattedMessage
    id="photoResultScreenTranslations.submit"
    description="Complete assessment"
    defaultMessage="Complete assessment"
  />,
  photoSubmitAI:
  <FormattedMessage
    id="OBTAI_PhotoSummary_submitButton_label"
    description="Submit your assessment"
    defaultMessage="Submit your assessment"
  />,
  loaderMessage:
  <FormattedMessage
    id="OBTAI_PhotoSubmitAnimation_loaderMessage"
    description="Your assessment in progress..."
    defaultMessage="Your assessment in progress..."
  />
};

export const clinicSelectTranslations = {
  title:
  <FormattedMessage
    id="clinicSelectTranslations.title"
    description="Find a Zenyum partner clinic"
    defaultMessage="Find a Zenyum partner clinic"
  />,
  subTitle:
  <FormattedMessage
    id="clinicSelectTranslations.subTitle"
    description="Then make an appointment with the clinic of your choice to kickstart your ZenyumClear™ Journey!"
    defaultMessage="Then make an appointment with the clinic of your choice to kickstart your ZenyumClear™ Journey!"
  />
};

export const caseAcceptanceTranslations = {
  reviewProgress:
  <FormattedMessage
    id="caseAcceptanceTranslations.reviewProgress"
    description="Review in progress!"
    defaultMessage="Review in progress!"
  />,
  reviewProgressMsg:
  <FormattedMessage
    id="caseAcceptanceTranslations.reviewProgressMsg"
    description="Our doctors will assess your photos. We’ll get back to you in 2–3 days. Thanks for your patience. Speak soon! "
    defaultMessage="Our doctors will assess your photos. We’ll get back to you in 2–3 days. Thanks for your patience. Speak soon! "
  />,
  next:
  <FormattedMessage
    id="caseAcceptanceTranslations.next"
    description="Next"
    defaultMessage="Next"
  />,
  success:
  <FormattedMessage
    id="caseAcceptanceTranslations.success"
    description="Success"
    defaultMessage="Success"
  />,
  selectClinic:
  <FormattedMessage
    id="caseAcceptanceTranslations.selectClinic"
    description="Select Clinic"
    defaultMessage="Select Clinic"
  />,
  failed:
  <FormattedMessage
    id="caseAcceptanceTranslations.failed"
    description="Oh No!"
    defaultMessage="Oh No!"
  />,
  homeBtn:
  <FormattedMessage
    id="caseAcceptanceTranslations.homeBtn"
    description="Go to home"
    defaultMessage="Go to home"
  />,
  successMsg:
  <FormattedMessage
    id="caseAcceptanceTranslations.successMsg"
    description="We’re able to help you get the smile you want! Your treatment will last approximately 12 to 16 weeks."
    defaultMessage="We’re able to help you get the smile you want! Your treatment will last approximately 12 to 16 weeks."
  />,
  failMsg:
  <FormattedMessage
    id="caseAcceptanceTranslations.failMsg"
    description="Upon reviewing your photos, we have realised that we can’t help you achieve your best smile. We’re really sorry!"
    defaultMessage="Upon reviewing your photos, we have realised that we can’t help you achieve your best smile. We’re really sorry!"
  />
};

export const residenceErrorTranslations = {
  residenceTitle:
  <FormattedMessage
    id="residenceErrorTranslations.residenceTitle"
    description="Thank you for your interest in Zenyum."
    defaultMessage="Thank you for your interest in Zenyum."
  />,
  residenceSubTitle:
  <FormattedMessage
    id="residenceErrorTranslations.residenceSubTitle"
    description="We are currently only serving customers in Singapore."
    defaultMessage="We are currently only serving customers in Singapore."
  />
};

export const disclaimerScreenTranslations = {
  title:
  <FormattedMessage
    id="disclaimerScreenTranslations.title"
    description="Disclaimer"
    defaultMessage="Disclaimer"
  />,
  content:
  <FormattedMessage
    id="disclaimerScreenTranslations.content"
    description="For your convenience, Zenyum is conducting a screening for transparent orthodontic suitability This screening is not for the purpose of diagnosing or treating teeth, but only to save customers' time and money by checking in advance whether or not it is difficult to straighten using Zenyum transparent orthodontics. After the preliminary screening, you must visit the dentist for an accurate consultation before proceeding with orthodontic treatment."
    defaultMessage="For your convenience, Zenyum is conducting a screening for transparent orthodontic suitability This screening is not for the purpose of diagnosing or treating teeth, but only to save customers' time and money by checking in advance whether or not it is difficult to straighten using Zenyum transparent orthodontics. After the preliminary screening, you must visit the dentist for an accurate consultation before proceeding with orthodontic treatment."
  />
};
export const disclaimerMainlayoutTranslations = {
  title:
  <FormattedMessage
    id="disclaimerMainlayoutTranslations.title"
    description="Disclaimer"
    defaultMessage="Disclaimer"
  />,
  content:
  <FormattedMessage
    id="disclaimerMainlayoutTranslations.content"
    description="This software is not intended to be used for any medical purpose (such as the detection, diagnosis, monitoring, management or treatment of any medical condition or disease). Any health-related information provided by this device or software should not be treated as medical advice. Please consult a physician for any medical advice required."
    defaultMessage="This software is not intended to be used for any medical purpose (such as the detection, diagnosis, monitoring, management or treatment of any medical condition or disease). Any health-related information provided by this device or software should not be treated as medical advice. Please consult a physician for any medical advice required."
  />
};

export const isoTranslations = {
  content:
  <FormattedMessage
    id="isoTranslations.content"
    description="Zenyum Pte. Ltd. in Singapore is ISO 13845:2016 certified by British Standards Institution (BSI) under certificate number MD 755177"
    defaultMessage="Zenyum Pte. Ltd. in Singapore is ISO 13845:2016 certified by British Standards Institution (BSI) under certificate number MD 755177"
  />
};

export const translationMapper = (text) => {
  switch (text) {
    case 'Crowding teeth':
      return smileTranslations.crowdingTeeth;
    case 'Crowded teeth':
      return smileTranslations.crowdedTeeth;
    case 'Protruding teeth':
      return smileTranslations.protrudingTeeth;
    case 'Gaps in teeth':
      return smileTranslations.gapsInTeeth;
    case 'Crooked teeth':
      return smileTranslations.crookedTeeth;
    case 'I just want straighter teeth':
      return smileTranslations.straighterTeeth;
    case 'Price':
      return smileTranslations.price;
    case 'Treatment Process':
      return smileTranslations.treatmentProcess;
    case 'Duration':
      return smileTranslations.duration;
    case 'Treatment Duration':
      return smileTranslations.treatmentDuration;
    case 'Pain':
      return smileTranslations.pain;
    case 'None of the above':
      return smileTranslations.noneOfTheAbove;
    case 'Under 13':
      return smileTranslations.under13;
    case 'Under 15':
      return smileTranslations.under15;
    case 'Under 16':
      return smileTranslations.under16;
    case 'Under 18':
      return smileTranslations.under18;
    case 'Under 20':
      return smileTranslations.under20;
    case 'Above 45':
      return smileTranslations.above45;
    case 'Above 55':
      return smileTranslations.above55;
    case 'Above 50':
      return smileTranslations.above50;
    case 1:
      return cameraTranslations.instructionIndex1;
    case 2:
      return cameraTranslations.instructionIndex2;
    case 3:
      return cameraTranslations.instructionIndex3;
    case 4:
      return cameraTranslations.instructionIndex4;
    case 'Check Camera Settings':
      return cameraTranslations.checkCamera;
    case 'Switching Camera':
      return cameraTranslations.switchingCamera;
    case 'Please choose a valid file':
      return cameraTranslations.noFileMsg;
    case 'Choose only jpg jpeg or png files':
      return cameraTranslations.invalidFileMsg;
    case 'Weekends(Afternoon)':
      return smileTranslations.weekendTime;
    case 'Weekdays(9:30am-12pm)':
      return smileTranslations.weekdayMorningTime;
    case 'Weekdays(12pm-3pm)':
      return smileTranslations.weekdayAfternoonTime;
    case 'Weekdays(3pm-6:30pm)':
      return smileTranslations.weekdayEveningTime;
    case 'Weekdays(6:30pm-8pm)':
      return smileTranslations.weekdayEarlyNightTime;
    case 'Weekdays(8pm-9:30pm)':
      return smileTranslations.weekdayNightTime;
    case 'Other':
      return smileTranslations.otherOption;
    case 'Other Box':
      return smileTranslations.otherOption;
    case 'As soon as possible':
      return smileTranslations.treatmentStartOptionOne;
    case 'In the next 3 months':
      return smileTranslations.treatmentStartOptionTwo;
    case 'In the next 6 months':
      return smileTranslations.treatmentStartOptionThree;
    case 'Next Year':
      return smileTranslations.treatmentStartOptionFour;
    default:
      return text;
  }
};

export const translationMapperFooter = (country) => {
  switch (country) {
    case 'hk':
      return commonTranslations.companyHK;
    default:
      return commonTranslations.company;
  }
};
