import Immutable from 'seamless-immutable';
import {
  DATE_OF_BIRTH_UPDATE,
  DATE_OF_BIRTH_SUBMIT_INIT,
  DATE_OF_BIRTH_SUBMIT_SUCCESS,
  DATE_OF_BIRTH_SUBMIT_FAIL
} from './actions';

const defaultState = Immutable({
  dateofBirth: {
    year: null,
    month: null,
    date: null
  },
  pending: 0
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case DATE_OF_BIRTH_UPDATE:
      return Immutable.merge(state, { dateofBirth: { ...state.dateofBirth, [action.name]: action.value } });

    case DATE_OF_BIRTH_SUBMIT_INIT:
      return Immutable.merge(state, { pending: state.pending + 1 });

    case DATE_OF_BIRTH_SUBMIT_SUCCESS:
      return Immutable.merge(state, { pending: state.pending - 1 });

    case DATE_OF_BIRTH_SUBMIT_FAIL:
      return Immutable.merge(state, { pending: state.pending - 1 });

    default:
      return state;
  }
};
