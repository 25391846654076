const DATE_OF_BIRTH_SUBMIT_INIT = 'DATE_OF_BIRTH:SUBMIT:INIT';
const DATE_OF_BIRTH_SUBMIT_SUCCESS = 'DATE_OF_BIRTH:SUBMIT:SUCCESS';
const DATE_OF_BIRTH_SUBMIT_FAIL = 'DATE_OF_BIRTH:SUBMIT:FAIL';
const DATE_OF_BIRTH_UPDATE = 'DATE_OF_BIRTH:UPDATE';

export {
  DATE_OF_BIRTH_SUBMIT_INIT,
  DATE_OF_BIRTH_SUBMIT_SUCCESS,
  DATE_OF_BIRTH_SUBMIT_FAIL,
  DATE_OF_BIRTH_UPDATE
};
