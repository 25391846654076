import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const Select = (props) => {
  const {
    countryCode,
    defaultValue,
    imageSrcDownChevron,
    isFlagSelect,
    isSelectNewUI,
    onListItemClick,
    selectedItem,
    selectOptions
  } = props;

  const flagSelectClassName = isFlagSelect ? 'flag-select' : 'old-select';
  const selectOptionsNewUIClassName = isSelectNewUI ? 'new-select-option new-date-select' : '';
  const isNewSelect = isFlagSelect || isSelectNewUI;
  const selectClassName = isNewSelect ? 'new-select' : 'old-select';

  const [selectOpen, setSelectOpen] = useState(false);
  const selectWrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (selectWrapperRef.current && !selectWrapperRef.current.contains(event.target)) {
      setSelectOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="selectWrapper" ref={selectWrapperRef}>
      <div
        className={`select ${selectClassName}`}
        onClick={() => setSelectOpen(!selectOpen)}
        role="presentation"
      >
        {isFlagSelect && <div className={`iti-flag ${countryCode}`} />}
        <span className={`${isSelectNewUI && selectedItem === null ? 'default-value' : 'selected-item'}`}>
          {selectedItem && Object.keys(selectedItem).length !== 0 ? selectedItem.itemName : defaultValue}
        </span>
        <img
          src={imageSrcDownChevron}
          alt="up-arrow"
          height={`${isNewSelect ? '24px' : '8px'}`}
          style={{ transform: `rotate(${!selectOpen ? 0 : 180}deg)`, alignSelf: 'center' }}
        />
      </div>
      {selectOpen && (
        <div className={`select-option-wrapper ${flagSelectClassName} ${selectOptionsNewUIClassName}`}>
          {selectOptions && selectOptions.length > 0 && selectOptions.map((item) => {
            const selectedOptionHighlightClassName = countryCode === item.countryCode ? 'highlight-new' : '';
            return (
              <div
                key={`${defaultValue}${item.id || item?.countryCode}`}
                onClick={() => {
                  onListItemClick(item);
                  setSelectOpen(false);
                }}
                role="presentation"
                className={`select-options ${selectClassName} ${selectedOptionHighlightClassName}`}
                style={{ backgroundColor: selectedItem && selectedItem.itemName === item.itemName && '#D9F7FE' }}
              >
                {
                  isFlagSelect
                    ? (
                      <>
                        <div className={`iti-flag ${item?.countryCode}`} />
                        <span>{item?.countryName}</span>
                      </>
                    )
                    : item?.itemName
                }
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

Select.propTypes = {
  countryCode: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  isFlagSelect: PropTypes.bool,
  isSelectNewUI: PropTypes.bool,
  onListItemClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({
    itemName: PropTypes.any
  }),
  selectOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  imageSrcDownChevron: PropTypes.string.isRequired
};

Select.defaultProps = {
  countryCode: '',
  isFlagSelect: false,
  isSelectNewUI: false,
  selectedItem: {
    itemName: ''
  }
};

export default Select;
