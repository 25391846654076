import React from 'react';

import { MainLayout } from '../layouts';

import ErrorScreen from '../containers/error-screen';

const SomethingWentWrongPage = () => (
  <MainLayout type="error-page" hasFooter={false} errorFooter={true}>
    <ErrorScreen type="default_error" />
  </MainLayout>
);

export default SomethingWentWrongPage;
