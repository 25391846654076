import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const ZenyumLogoLoader = (props) => {
  const {
    isLoading,
    isWhiteBackGround,
    loaderMessage,
    logoSrc,
    showText
  } = props;

  if (isLoading) {
    return (
      <>
        <div
          className={!isWhiteBackGround ? `lnd-cnt` : `lnd-cnt whiteBackGround`}
        />
        <div
          className={`loader-content-wrapper ${
            isWhiteBackGround && 'old-loader'
          }`}
        >
          <div className="loader-gif-wrapper">
            <img src={logoSrc} alt="option" className="loader-gif" />
          </div>
          {showText && !isWhiteBackGround && (
            <span className="loader-message">{loaderMessage}</span>
          )}
        </div>
      </>
    );
  }
  return null;
};

ZenyumLogoLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isWhiteBackGround: PropTypes.bool,
  loaderMessage: PropTypes.string,
  logoSrc: PropTypes.string,
  showText: PropTypes.bool
};

ZenyumLogoLoader.defaultProps = {
  isWhiteBackGround: false,
  loaderMessage: '',
  logoSrc: `${process.env.PUBLIC_URL}/images/loading-animation.gif`,
  showText: false
};

export default ZenyumLogoLoader;
