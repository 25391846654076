import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const ProgressLine = (props) => {
  const {
    activePageCount,
    circleCount,
    popUpBooth
  } = props;

  const circles = (index) => (
    <div
      key={index}
      className={`outer-circle ${popUpBooth && 'large-outer-circle'} ${
        activePageCount === index + 1 ? 'active-circle' : 'inactive-circle'
      }`}
    >
      <div className={`circle ${popUpBooth && 'large-inner-circle'}`} />
    </div>
  );
  return (
    <div
      className={`progress-line-wrapper ${popUpBooth && 'progress-wrapper'}`}
    >
      <div className="progress-line-container">
        <div className="line" />
        <div className="circle-wrapper">
          {[...Array(circleCount).keys()].map((item) => circles(item))}
        </div>
      </div>
    </div>
  );
};

ProgressLine.propTypes = {
  activePageCount: PropTypes.number,
  circleCount: PropTypes.number,
  popUpBooth: PropTypes.bool
};

ProgressLine.defaultProps = {
  activePageCount: 1,
  circleCount: 3,
  popUpBooth: false
};

export default ProgressLine;
