import React from 'react';
import registry from 'app-registry';
import PropTypes from 'prop-types';

import './styles.scss';

const getLayoutClass = (type) => {
  switch (type) {
    case 'primary': return 'root-layout-primary';
    case 'secondary': return 'root-layout-secondary';
    case 'camera-theme': return 'root-layout-camera-theme';
    default: return 'root-layout-default';
  }
};

const RootLayout = (props) => {
  const { popUpBooth } = props;
  const obtMetaData = JSON.parse(registry.get('storage').getStorage('obt-zenyum-metaData'));
  const { languageCode } = obtMetaData;
  return (
    <div className={`${getLayoutClass(props.type)} root-layout}`} lang={languageCode}>
      <div className={`root-layout-content ${popUpBooth && 'full-screen'}`}>
        {props.children}
      </div>
    </div>
  );
};

RootLayout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  popUpBooth: PropTypes.bool
};

RootLayout.defaultProps = {
  type: '',
  popUpBooth: false
};

export default RootLayout;
