export const CountryConfig = {
  showLanguageSwitch: {
    sg: true,
    my: true,
    th: true,
    hk: true,
    mo: false,
    id: false,
    vn: true,
    tw: false,
    kr: true,
    jp: true
  },
  zenyumWhiteLogo: {
    sg: 'logo.svg',
    my: 'logo.svg',
    th: 'logo.svg',
    hk: 'logo.svg',
    mo: 'logo.svg',
    id: 'logo.svg',
    vn: 'logo.svg',
    tw: 'zenyum-tw-white.png',
    kr: 'logo.svg',
    jp: 'logo.svg'
  },
  zenyumBlueLogo: {
    sg: 'logo-blue.svg',
    my: 'logo-blue.svg',
    th: 'logo-blue.svg',
    hk: 'logo-blue.svg',
    mo: 'logo-blue.svg',
    id: 'logo-blue.svg',
    vn: 'logo-blue.svg',
    tw: 'zenyum-tw-blue.png',
    kr: 'logo-blue.svg',
    jp: 'logo-blue.svg'
  },
  showContactDetails: {
    sg: false,
    my: false,
    th: false,
    hk: false,
    mo: false,
    id: false,
    vn: true,
    tw: false,
    kr: false,
    jp: false
  },
  commercialLaws: {
    sg: false,
    my: false,
    th: false,
    hk: false,
    mo: false,
    id: false,
    vn: false,
    tw: false,
    kr: false,
    jp: true
  },
  showFooterIcons: {
    sg: false,
    my: false,
    th: false,
    hk: false,
    mo: false,
    id: false,
    vn: false,
    tw: false,
    kr: false,
    jp: { line: 'https://lin.ee/kcqgnJj', email: 'mailto: smile.japan@zenyum.com' }
  },
  countryLocaleSwitch: {
    'sg-en': 'sg-en',
    'sg-zh': 'sg-en',
    'my-en': 'my-en',
    'my-zh': 'my-en',
    'my-ms': 'my-en',
    'mo-zh': 'hk-zh',
    'vn-en': 'sg-en',
    'vn-vi': 'vn-vi',
    'hk-zh': 'hk-zh',
    'hk-en': 'hk-en',
    'th-en': 'sg-en',
    'th-th': 'th-th',
    'tw-zh': 'tw-zh',
    'id-id': 'id-id',
    'jp-en': 'jp-ja',
    'jp-ja': 'jp-ja'
  },
  zenyumLogoClickSwitch: {
    'sg-en': 'sg-en',
    'sg-zh': 'sg-en',
    'my-en': 'my-en',
    'my-zh': 'my-en',
    'my-ms': 'my-en',
    'mo-zh': 'sg-en',
    'vn-en': 'vn-vi',
    'vn-vi': 'vn-vi',
    'hk-zh': 'hk-zh',
    'hk-en': 'hk-en',
    'th-en': 'th-th',
    'th-th': 'th-th',
    'tw-zh': 'tw-zh',
    'id-id': 'id-id',
    'jp-ja': 'jp-ja'
  },
  countryLanguageList: {
    'sg-en': { country: 'Singapore', language: 'English' },
    'sg-zh': { country: 'Singapore', language: 'Mandarin' },
    'my-en': { country: 'Malaysia', language: 'English' },
    'my-zh': { country: 'Malaysia', language: 'Mandarin' },
    'my-ms': { country: 'Malaysia', language: 'Bahasa Malay' },
    'mo-zh': { country: 'Macau', language: 'Mandarin' },
    'vn-en': { country: 'Việt Nam', language: 'English' },
    'vn-vi': { country: 'Việt Nam', language: 'Tiếng Việt' },
    'hk-zh': { country: ' 香港', language: '繁體中文' },
    'hk-en': { country: 'Hong Kong', language: 'English' },
    'th-en': { country: 'ประเทศไทย', language: 'English' },
    'th-th': { country: 'ประเทศไทย', language: 'ไทย' },
    'tw-zh': { country: '台湾', language: '繁體中文' },
    'id-id': { country: 'Indonesia', language: 'Indonesian' },
    'jp-ja': { country: '日本', language: '日本語' }
  },
  supportedLanguages: {
    my: [
      {
        title: 'EN',
        countryCode: 'my',
        languageCode: 'en'
      },
      {
        title: '中文',
        countryCode: 'my',
        languageCode: 'zh'
      },
      {
        title: 'MELAYU',
        countryCode: 'my',
        languageCode: 'ms'
      }
    ],
    sg: [
      {
        title: 'EN',
        countryCode: 'sg',
        languageCode: 'en'
      },
      {
        title: '中文',
        countryCode: 'sg',
        languageCode: 'zh'
      }
    ],
    th: [
      {
        title: 'EN',
        countryCode: 'th',
        languageCode: 'en'
      },
      {
        title: 'ไทย',
        countryCode: 'th',
        languageCode: 'th'
      }
    ],
    hk: [
      {
        title: 'EN',
        countryCode: 'hk',
        languageCode: 'en'
      },
      {
        title: '繁體中文',
        countryCode: 'hk',
        languageCode: 'zh'
      }
    ],
    id: [
      {
        title: 'EN',
        countryCode: 'id',
        languageCode: 'en'
      },
      {
        title: 'Bahasa',
        countryCode: 'id',
        languageCode: 'id'
      }
    ],
    vn: [
      {
        title: 'EN',
        countryCode: 'vn',
        languageCode: 'en'
      },
      {
        title: 'VI',
        countryCode: 'vn',
        languageCode: 'vi'
      }
    ],
    kr: [
      {
        title: 'EN',
        countryCode: 'kr',
        languageCode: 'en'
      },
      {
        title: '한국어',
        countryCode: 'kr',
        languageCode: 'ko'
      }
    ],
    jp: [
      {
        title: 'EN',
        countryCode: 'jp',
        languageCode: 'en'
      },
      {
        title: '日本語',
        countryCode: 'jp',
        languageCode: 'ja'
      }
    ]
  }
};

export const OBT_CONFIG = {
  showIsoLogo: {
    OBT1: true,
    OBT2: false,
    OBT3: false,
    OBT4: false,
    OBTAI: false
  }
};
