import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const ToastBox = (props) => {
  const { fullWidth, iconSrc, message, newToastIcon, severity, style } = props;

  const alertLevels = {
    success: {
      src: `${newToastIcon ? 'tick-new.svg' : 'tick.svg'}`,
      className: 'successMessage'
    },
    error: {
      src: 'cross.svg',
      className: 'errorMessage'
    }
  };

  return (
    <div
      style={style}
      className={`toastMessage ${
        alertLevels[severity].className || 'successMessage'
      } ${fullWidth ? 'toastMessageFullWidth' : ''}`}
    >
      <img
        src={`${iconSrc}${alertLevels[severity].src || 'tick.svg'}`}
        alt={`${severity}`}
        className={`toastIcon ${newToastIcon ? 'newToastIcon' : ''}`}
      />
      <span>{message}</span>
    </div>
  );
};

ToastBox.propTypes = {
  fullWidth: PropTypes.bool,
  iconSrc: PropTypes.string,
  message: PropTypes.string,
  newToastIcon: PropTypes.bool,
  severity: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any)
};

ToastBox.defaultProps = {
  fullWidth: false,
  iconSrc: '',
  message: '',
  newToastIcon: false,
  severity: 'success',
  style: {}
};

export default ToastBox;
