import { connect } from 'react-redux';

import ErrorScreen from './errorScreen';

const mapStateToProps = (state) => ({
  countryCode: state.app?.metaData?.countryCode,
  languageCode: state.app?.metaData?.languageCode
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorScreen);
