import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './styles.scss';

const LineAppLink = (props) => {
  const { placeHolder, countryCode, link, intl } = props;
  const { formatMessage } = intl;

  useEffect(() => {
    highlightText(`Line @zenyum${countryCode}`);
  }, []);

  const highlightText = (text) => {
    const inputText = document.getElementsByClassName('line-link');
    const { innerHTML } = inputText[0];
    const index = innerHTML.indexOf(text);
    if (index >= 0) {
      const newHTML = `${innerHTML.substring(
        0,
        index
      )}<a class="highlight" target="_blank" href="${link}">${innerHTML.substring(
        index,
        index + text.length
      )}</a>${innerHTML.substring(index + text.length)}`;
      inputText[0].innerHTML = newHTML;
    }
  };

  const createMarkup = () => ({ __html: formatMessage(placeHolder.props) });

  return (
    <div className="line-account-link">
      <div
        className="line-link"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={createMarkup()}
      />
    </div>
  );
};

LineAppLink.propTypes = {
  countryCode: PropTypes.string,
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  link: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired
};

LineAppLink.defaultProps = {
  countryCode: 'th'
};

export default injectIntl(LineAppLink);
