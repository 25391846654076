import React from 'react';
import { FormattedMessage } from 'react-intl';

export const languages = [
  {
    name: (
      <FormattedMessage
        id="Language.vietnamese"
        description="Vietnamese"
        defaultMessage="Vietnamese"
      />
    ),
    locale: 'vi'
  },
  {
    name: (
      <FormattedMessage
        id="Language.thai"
        description="Thai"
        defaultMessage="Thai"
      />
    ),
    locale: 'th'
  },
  {
    name: (
      <FormattedMessage
        id="Language.bahasa"
        description="Bahasa"
        defaultMessage="Bahasa"
      />
    ),
    locale: 'id'
  },
  {
    name: (
      <FormattedMessage
        id="Language.cantonese"
        description="Cantonese"
        defaultMessage="Cantonese"
      />
    ),
    locale: 'yue'
  },
  {
    name: (
      <FormattedMessage
        id="Language.Chinese"
        description="Chinese"
        defaultMessage="Chinese"
      />
    ),
    locale: 'zh'
  },
  {
    name: (
      <FormattedMessage
        id="Language.english"
        description="English"
        defaultMessage="English"
      />
    ),
    locale: 'en'
  },
  {
    name: (
      <FormattedMessage
        id="Language.korean"
        description="Korean"
        defaultMessage="Korean"
      />
    ),
    locale: 'ko'
  },
  {
    name: (
      <FormattedMessage
        id="Language."
        description="japanese"
        defaultMessage="japanese"
      />
    ),
    locale: 'ja'
  }
];
export default languages;
