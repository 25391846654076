const HELLO_FORM_SUBMIT_INIT = 'HELLO_FORM:SUBMIT:INIT';
const HELLO_FORM_SUBMIT_SUCCESS = 'HELLO_FORM:SUBMIT:SUCCESS';
const HELLO_FORM_SUBMIT_FAIL = 'HELLO_FORM:SUBMIT:FAIL';
const HELLO_SCREEN_DETAILS_UPDATE = 'HELLO_SCREEN:DETAILS:UPDATE';

const APP_STATUS_UPDATE = 'APP:STATUS:UPDATE';
const APP_EMAIL_SET = 'APP:EMAIL:SET';

const HELLO_SCREEN_GET_DETAILS_REQUEST = 'HELLO_SCREEN_GET_DETAILS_REQUEST';
const HELLO_SCREEN_GET_DETAILS_SUCCESS = 'HELLO_SCREEN_GET_DETAILS_SUCCESS';
const HELLO_SCREEN_GET_DETAILS_FAIL = 'HELLO_SCREEN_GET_DETAILS_FAIL';

const HELLO_SCREEN_TEENS_OBT_CHECK = 'HELLO_SCREEN:TEENS:OBT:CHECK';

export {
  APP_EMAIL_SET,
  APP_STATUS_UPDATE,
  HELLO_FORM_SUBMIT_INIT,
  HELLO_FORM_SUBMIT_SUCCESS,
  HELLO_FORM_SUBMIT_FAIL,
  HELLO_SCREEN_DETAILS_UPDATE,
  HELLO_SCREEN_GET_DETAILS_REQUEST,
  HELLO_SCREEN_GET_DETAILS_SUCCESS,
  HELLO_SCREEN_GET_DETAILS_FAIL,
  HELLO_SCREEN_TEENS_OBT_CHECK
};
