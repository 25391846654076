import React from 'react';

import './styles.scss';

import PropTypes from 'prop-types';

const RadioGroup = (props) => {
  const { checked, label, onChange } = props;
  return (
    <div className="radio-group-wrapper">
      <input
        type="radio"
        onChange={(event) => onChange(event.target.checked)}
        checked={checked}
      />
      <span className="radio-group-label">{label}</span>
    </div>
  );
};

RadioGroup.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

RadioGroup.propTypes = {
  label: PropTypes.string.isRequired
};

export default RadioGroup;
