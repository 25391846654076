import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './styles.scss';

const CustomCheckbox = (props) => {
  const {
    checked,
    highlight,
    intl,
    label,
    name,
    onBlur,
    onChange,
    setHtmlDangerously
  } = props;

  const id = `${name}-${Math.floor(Math.random() * 1000)}`;
  const { formatMessage } = intl;

  useEffect(() => {
    if (!setHtmlDangerously && highlight.length > 0) {
      highlight.forEach((element) => {
        const highlighted = document.getElementsByClassName('highlight');
        if (highlighted.length < highlight.length) { highlightText(element.text, element.link); }
      });
    }
  }, [highlight]);

  const highlightText = (text, link) => {
    const inputText = document.getElementsByClassName('check-txt');
    const { innerHTML } = inputText[0];
    const index = innerHTML.indexOf(text);

    if (index >= 0) {
      const newHTML = `${innerHTML.substring(0, index)
      }<a class="highlight" target="_blank" href="${
        link
      }">${
        innerHTML.substring(index, index + text.length)
      }</a>${
        innerHTML.substring(index + text.length)}`;
      inputText[0].innerHTML = newHTML;
    }
  };

  const createMarkup = () => ({ __html: formatMessage(label.props) });

  return (
    <div className="cust-checkbox-root">
      <label htmlFor={id} className="checkBox">
        <input
          name={name}
          onChange={(event) => onChange(event.target.checked)}
          id={id}
          checked={checked}
          type="checkbox"
          onBlur={onBlur}
        />
        <span className="cust-checkbox" />
        {setHtmlDangerously ? (
          // eslint-disable-next-line react/no-danger
          <div className="check-txt" dangerouslySetInnerHTML={createMarkup()} />
        ) : (
          <div className="check-txt">{formatMessage(label.props)}</div>
        )}
      </label>
    </div>
  );
};

CustomCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  formatMessage: PropTypes.func,
  highlight: PropTypes.arrayOf(PropTypes.any),
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  setHtmlDangerously: PropTypes.bool
};

CustomCheckbox.defaultProps = {
  formatMessage: () => {},
  highlight: [],
  label: '',
  name: 'default',
  onBlur: () => {},
  setHtmlDangerously: false
};

export default injectIntl(CustomCheckbox);
