/* eslint-disable camelcase */
import Immutable from 'seamless-immutable';
import registry from 'app-registry';

import * as actions from '../actions';
import { FINAL_FORM_SUBMIT_SUCCESS } from '../containers/all-set-screen/actions';
import { HELLO_SCREEN_GET_DETAILS_SUCCESS } from '../containers/hello-screen/actions';
import { TRANSLATION_FETCH_SUCCESS } from '../translation/actions';

const defaultState = Immutable({
  metaData: {
    countryCode: null,
    phoneCode: null,
    languageCode: null,
    email: null,
    crmId: null,
    status: {
      form1: false,
      form2: false,
      assessment: false,
      done: false
    },
    utmData: {},
    facebookAdData: {}
  },
  bannerData: [],
  savedImages: [],
  csOwner: null
});

const setLocalStorage = (data) => {
  registry
    .get('storage')
    .setStorage(
      'obt-zenyum-metaData',
      JSON.stringify({
        ...data
      })
    );
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.APP_INIT: {
      const metaData = action.payload.metaData ? { ...action.payload.metaData } : {};
      return Immutable.merge(
        state,
        { ...metaData }
      );
    }
    case actions.APP_STATUS_UPDATE: {
      const newState = Immutable.setIn(
        state,
        ['metaData', 'status', action.label],
        action.value
      );
      setLocalStorage(newState.metaData);
      return newState;
    }
    case actions.SAVE_UTM_DATA: {
      const newState = Immutable.setIn(
        state,
        ['metaData', 'utmData'],
        action.data
      );
      const excludingBanners = Immutable.setIn(
        state.metaData,
        ['metaData', 'utmData'],
        action.data
      );
      registry
        .get('storage')
        .setStorage(
          'obt-zenyum-metaData',
          JSON.stringify({
            ...excludingBanners
          })
        );
      return Immutable.merge(state, newState);
    }
    case actions.APP_STATUS_CLEAR: {
      const newState = Immutable.setIn(
        state,
        ['metaData', 'status'],
        defaultState.metaData.status
      );
      setLocalStorage(newState.metaData);
      return newState;
    }
    case TRANSLATION_FETCH_SUCCESS: {
      const newState = Immutable.merge(
        state,
        {
          metaData:
            { ...action.newMetaData }
        },
        { deep: true }
      );
      setLocalStorage(newState.metaData);
      return newState;
    }
    case actions.APP_METADATA_SET: {
      const newMetaData = {
        ...state.metaData,
        ...action.metaData
      };
      const newState = Immutable.merge(state, {
        metaData: newMetaData
      });
      setLocalStorage(newState.metaData);
      return newState;
    }
    case actions.APP_USER_ID_SET: {
      const newState = Immutable.setIn(state, ['metaData', 'crmId'], action.id);
      setLocalStorage(newState.metaData);
      return newState;
    }
    case 'SET:COUNTRY_CODE': {
      const newMetaData = {
        ...state.metaData,
        countryCode: action.data.countryCode,
        languageCode: action.data.languageCode
      };
      const newState = Immutable.merge(state, {
        metaData: newMetaData
      });
      setLocalStorage(newState.metaData);
      return newState;
    }
    case actions.SAVE_LINE_DATA: {
      const newState = Immutable.merge(state, {
        lineData: action.data
      });
      return newState;
    }
    case actions.SAVE_REFERRAL_DATA: {
      const newState = Immutable.merge(state, {
        referral: action.text
      });
      return newState;
    }
    case actions.SAVE_TAGS_DATA: {
      const newState = Immutable.merge(state, {
        tags: action.tags
      });
      return newState;
    }
    case actions.SAVE_GOOGLE_ID: {
      const newMetaData = {
        ...state.metaData,
        gclid: action.gclid
      };
      const newState = Immutable.merge(state, {
        metaData: newMetaData
      });
      setLocalStorage(newState.metaData);
      return newState;
    }
    case actions.SAVE_COUPON_CODE: {
      const newState = Immutable.merge(state, {
        couponCode: action.couponCode
      });
      return newState;
    }
    case actions.SET_PRIZE_WON: {
      const newState = Immutable.merge(state, {
        prizeWon: action.prizeWon
      });
      return newState;
    }
    case actions.SET_SOURCE_OBT: {
      const newState = Immutable.merge(state, {
        srcOrigin: action.srcOrigin
      });
      return newState;
    }
    case actions.OBT_BANNER_DATA_FETCH_SUCCESS: {
      const newState = Immutable.merge(state, {
        bannerData: action.data?.obt_list
      });
      return newState;
    }
    case actions.OBT_BANNER_DATA_FETCH__FAIL: {
      const newState = Immutable.merge(state, {
        bannerData: []
      });
      return newState;
    }
    case actions.USER_PHOTO_SAVE_REQUEST: {
      const newState = Immutable.merge(state, {
        savedImages: action.data
      });
      return newState;
    }
    case actions.USER_IMAGE_RETAKE_REQUEST: {
      const newState = Immutable.merge(state, {
        savedImages: { ...state.savedImages, [`photo${action.imageIndex}`]: '' }
      });
      return newState;
    }
    case actions.SAVE_FACEBOOK_AD_DATA: {
      const newState = Immutable.setIn(
        state,
        ['metaData', 'facebookAdData'],
        action.facebookAdData
      );
      registry
        .get('storage')
        .setStorage(
          'obt-zenyum-metaData',
          JSON.stringify({
            ...newState
          })
        );
      return Immutable.merge(state, newState);
    }

    case HELLO_SCREEN_GET_DETAILS_SUCCESS:
    case FINAL_FORM_SUBMIT_SUCCESS:
      return Immutable.merge(state, { csOwner: action.data?.data?.cs_owner });

    default:
      return state;
  }
};
