import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const ProgressBar = (props) => {
  const [progressItems, setProgressItems] = useState([]);

  const {
    activeItem,
    items
  } = props;

  useEffect(() => {
    if (items) {
      const array = [];
      [...Array(items).keys()].forEach((item) => {
        array.push(item + 1);
      });
      setProgressItems(array);
    }
  }, [items]);

  return (
    <div className="progress-bar">
      {progressItems.map((step, index) => (
        <div key={`${index + 1}`} className={`progress-step ${activeItem === index + 1 && 'is-active'}`}>
          <div className="step-count" />
        </div>
      ))}
    </div>
  );
};

ProgressBar.propTypes = {
  activeItem: PropTypes.number.isRequired,
  items: PropTypes.number.isRequired
};

ProgressBar.defaultProps = {};

export default ProgressBar;
