import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button } from '../../components';
import storage from '../../services/storage';
import { errorTranslations } from '../../translation/commonTranslations';

import ERROR_CONFIG from './errorScreenConfig';

import './styles.scss';

const ErrorScreen = (props) => {
  const { countryCode, languageCode, popUpBooth, type } = props;

  const obtVersion = process.env.REACT_APP_OBT_VERSION;
  const obtUi = storage.getStorage('obt_ui');

  const [errorMsg, setErrorMsg] = useState({
    content: errorTranslations.somethingWentWrong,
    buttonLabel: errorTranslations.goHome
  });

  useEffect(() => {
    getErrorMsg();
  }, []);

  const onButtonClick = (errorType) => {
    let path = '';
    if (popUpBooth) {
      path = '/smile-capture';
    } else {
      if (errorType === 'error_404') { path = ERROR_CONFIG.error404RedirectionPath[obtUi][obtVersion]; }
      if (errorType === 'photo_error') { path = ERROR_CONFIG.photoErrorRedirectionPath[obtUi][obtVersion]; }
    }
    window.location.href = `/${countryCode}-${languageCode}${path}`;
  };

  const getErrorMsg = () => {
    switch (type) {
      case 'photo_error': {
        setErrorMsg({
          content: errorTranslations.photoError,
          buttonLabel: errorTranslations.retakePhoto
        });
        return errorMsg;
      }
      case 'error_404':
        setErrorMsg({
          ...errorMsg,
          content: errorTranslations.urlNotFound
        });
        return errorMsg;
      case 'default_error':
        return errorMsg;
      default:
        return errorMsg;
    }
  };

  return (
    <div className="error-page-wrapper">
      <img
        className="error-page-image"
        src={`${process.env.PUBLIC_URL}/images/error-screen/404.png`}
        alt="404-error"
      />
      <text className="error-page-heading">{errorTranslations.fourOhFour}</text>
      <text className="error-page-subheading">
        {errorTranslations.pageContent}
      </text>
      <div className="error-page-button-wrapper">
        <Button
          type="submit"
          className="error-page-submit-button"
          onClick={() => onButtonClick(type)}
          languageCode={languageCode}
        >
          {errorTranslations.buttonLabel}
        </Button>
      </div>
    </div>
  );
};

ErrorScreen.propTypes = {
  countryCode: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  popUpBooth: PropTypes.bool,
  type: PropTypes.string
};

ErrorScreen.defaultProps = {
  popUpBooth: false,
  type: 'default_error'
};

export default withRouter(ErrorScreen);
