import cameraReducer from '../containers/camera-screen/reducer';
import helloScreenReducer from '../containers/hello-screen/reducer';
import intl from '../translation/reducers';
import remindMe from '../containers/remind-me-page/reducer';
import rootReducer from './rootReducer';
import smileQuestionsReducer from '../containers/smile-questions/reducer';
import notifierReducer from '../containers/notifier/reducers';

export default {
  app: rootReducer,
  form1: helloScreenReducer,
  form2: smileQuestionsReducer,
  form3: cameraReducer,
  intl,
  notifierReducer,
  remindMe
};
