import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const CameraLoader = (props) => {
  const { isLoading, label } = props;

  if (isLoading) {
    return (
      <>
        <div className="lnd-cnt" />
        <div className="lnd-spin" />
        <p className="lnd-txt">{label}</p>
      </>
    );
  }

  return null;
};

CameraLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
};


CameraLoader.defaultProps = {};

export default CameraLoader;
