import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const Step = (props) => {
  const {
    altText,
    content,
    hasLine,
    hideContent,
    step,
    textAlignTop,
    tips,
    title,
    type,
    imageStepSrc
  } = props;

  return (
    <div>
      <div className={`obt-step-root ${textAlignTop && `text-align-top`}`}>
        <img
          className={`obt-step-img ${type === 'secondary' && 'large-image'}`}
          alt={altText}
          src={imageStepSrc}
        />
        <div className="obt-step-text-wrapper">
          <div className="obt-step-label">
            <div className={`obt-step-title ${type === 'secondary' && 'step-heading'}`}>
              {type === 'secondary' && (
                <span className="step-count">
                  {`${step}. `}
                </span>
              )}
              {title}
            </div>
            <div className={`obt-step-content ${type === 'secondary' && 'step-details'}`}>
              {!hideContent && (<>{content}</>)}
            </div>
          </div>
          {tips && (
            <div className="obt-step-tips">
              {tips}
            </div>)}
        </div>
      </div>
      {hasLine && (<div className="obt-step-line" />)}
    </div>
  );
};

Step.propTypes = {
  altText: PropTypes.string,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  hasLine: PropTypes.bool,
  hideContent: PropTypes.bool,
  step: PropTypes.shape(PropTypes.string),
  textAlignTop: PropTypes.bool,
  tips: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  imageStepSrc: PropTypes.string.isRequired
};

Step.defaultProps = {
  altText: 'image',
  hasLine: false,
  hideContent: false,
  step: '',
  textAlignTop: false,
  tips: false,
  title: '',
  type: 'primary'
};

export default Step;
