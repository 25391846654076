
// Homepage
export const HOMEPAGE_DUMMY_DATA_FETCH_REQUEST = 'HOMEPAGE:DUMMY_DATA:FETCH:REQUEST';
export const HOMEPAGE_DUMMY_DATA_FETCH_SUCCESS = 'HOMEPAGE:DUMMY_DATA:FETCH:SUCCESS';
export const HOMEPAGE_DUMMY_DATA_FETCH_FAIL = 'HOMEPAGE:DUMMY_DATA:FETCH:FAIL';

// CameraScreen
export const CAMERA_SCREEN_PHOTO_SAVE_REQUEST = 'CAMERA_SCREEN:PHOTO:SAVE:REQUEST';
export const CAMERA_SCREEN_PHOTO_SAVE_SUCCESS = 'CAMERA_SCREEN:PHOTO:SAVE:SUCCESS';
export const CAMERA_SCREEN_PHOTO_SAVE_FAIL = 'CAMERA_SCREEN:PHOTO:SAVE:FAIL';
export const CAMERA_SCREEN_PENDING_SET = 'CAMERA_SCREEN:PENDING:SET';
export const CAMERA_SCREEN_PHOTOS_RESET = 'CAMERA_SCREEN:PHOTOS:RESET';
export const CAMERA_SCREEN_RETAKE_PHOTO = 'CAMERA_SCREEN:RETAKE:PHOTO';
export const CAMERA_VERSION_INITIALIZATION = 'CAMERA:VERSION_INITIALIZATION';

export const USER_FORM_SCREEN_PHOTO_SAVE_REQUEST = 'USER_FORM:PHOTO:SAVE:REQUEST';
export const USER_FORM_SCREEN_PHOTO_SAVE_SUCCESS = 'USER_FORM:PHOTO:SAVE:SUCCESS';
export const USER_FORM_SCREEN_PHOTO_SAVE_FAIL = 'USER_FORM:PHOTO:SAVE:FAIL';

export const HELLO_SCREEN_EMAIL_FETCH_REQUEST = 'HELLO_SCREEN:EMAIL:FETCH:REQUEST';
export const HELLO_SCREEN_EMAIL_FETCH_SUCCESS = 'HELLO_SCREEN:EMAIL:FETCH:SUCCESS';
export const HELLO_SCREEN_EMAIL_FETCH_FAIL = 'HELLO_SCREEN:EMAIL:FETCH:FAIL';
export const TEENS_EMAIL_FETCH_REQUEST = 'TEENS:EMAIL:FETCH:REQUEST';
export const TEENS_EMAIL_FETCH_SUCCESS = 'TEENS:EMAIL:FETCH:SUCCESS';
export const TEENS_EMAIL_FETCH_FAIL = 'TEENS:EMAIL:FETCH:FAIL';

// Smile questions screen
export const SMILEQUESTIONS_SET_AGE_CATEGORY = 'SMILEQUESTIONS:SET:AGE_CATEGORY';
export const SMILEQUESTIONS_SET_TEETH_CONDITION = 'SMILEQUESTIONS:SET:TEETH_CONDITION';
export const SMILEQUESTIONS_SET_CONCERNS = 'SMILEQUESTIONS:SET:CONCERNS';
export const SMILEQUESTIONS_SET_TREATMENT_GOALS = 'SMILEQUESTIONS:SET:TREATMENT_GOALS';
export const SMILEQUESTIONS_SET_CALL_TIME = 'SMILEQUESTIONS:SET:CALL_TIME';
export const SMILEQUESTIONS_SET_YOUTUBE_ANSWER = 'SMILEQUESTIONS:SET:YOUTUBE_ANSWER';
export const SMILEQUESTIONS_RESET = 'SMILE_QUESTIONS:RESET';
export const SMILE_QUESTIONS_FORM_DETAILS_UPDATE = 'SMILE_QUESTIONS:FORM_DETAILS:UPDATE';

export const SMILEQUESTIONS_SUBMIT_REQUEST = 'SMILEQUESTIONS:SUBMIT:REQUEST';
export const SMILEQUESTIONS_SUBMIT_SUCCESS = 'SMILEQUESTIONS:SUBMIT:SUCCESS';
export const SMILEQUESTIONS_SUBMIT_FAIL = 'SMILEQUESTIONS:SUBMIT:FAIL';
export const SMILEQUESTIONS_SET_PARENT_PHONE_NO = 'SMILEQUESTIONS:SET:PARENT_PHONE_NO';
export const SMILEQUESTIONS_SET_DATE_OF_BIRTH = 'SMILEQUESTIONS:SET:DATE_OF_BIRTH';
export const SMILEQUESTIONS_SET_TEEN_EMAIL = 'SMILEQUESTIONS:SET:TEEN_EMAIL';

// Smile Capture screen
export const USER_PHOTO_SAVE_REQUEST = 'SMILE_CAPTURE_SCREEN:PHOTO:SAVE:REQUEST';
export const USER_IMAGE_RETAKE_REQUEST = 'USER_FORM_SCREEN:IMAGE:RETAKE:REQUEST';

export const SAVE_UTM_DATA = 'SAVE:UTM_DATA';

export const SAVE_TAGS_DATA = 'SAVE:TAGS_DATA';

export const SAVE_REFERRAL_DATA = 'SAVE:REFERRAL_DATA';

export const SAVE_GOOGLE_ID = 'SAVE_GOOGLE_ID';

export const SAVE_COUPON_CODE = 'SAVE:COUPON_CODE';

export const SAVE_LINE_DATA = 'SAVE:LINE_DATA';

export const SET_PRIZE_WON = 'SET_PRIZE_WON';

export const SET_SOURCE_OBT = 'SET:SOURCE_OBT';

export const SAVE_FACEBOOK_AD_DATA = 'SAVE:SAVE_FACEBOOK_AD_DATA';

export const SET_COUNTRY_CODE = 'SET:COUNTRY_CODE';

/* General app actions */
export const APP_INIT = 'APP:INIT';
export const APP_STATUS_UPDATE = 'APP:STATUS:UPDATE';
export const APP_STATUS_CLEAR = 'APP:STATUS:CLEAR';

/* Lead fetch */
export const LEAD_FETCH_INIT = 'LEAD:FETCH:INIT';
export const LEAD_FETCH_SUCCESS = 'LEAD:FETCH:SUCCESS';
export const LEAD_FETCH_FAIL = 'LEAD:FETCH:FAIL';

/* set MetaData */
export const APP_METADATA_SET = 'APP:METADATA:SET';
export const APP_EMAIL_SET = 'APP:EMAIL:SET';
export const APP_EMAIL_TEENS_SET = 'APP:EMAIL:TEENS:SET';
export const APP_USER_ID_SET = 'APP:USER_ID:SET';

/* fetch Banner and carousel data */
export const OBT_BANNER_DATA_FETCH_SUCCESS = 'OBT:BANNER:DATA:FETCH:SUCCESS';
export const OBT_BANNER_DATA_FETCH__FAIL = 'OBT:BANNER:DATA:FETCH:FAIL';

/* Book Appointment */
export const ZENCAL_GET_USER_REQUEST = 'ZENCAL:GET_USER:REQUEST';
export const ZENCAL_GET_USER_SUCCESS = 'ZENCAL:GET_USER:SUCCESS';
export const ZENCAL_GET_USER_FAIL = 'ZENCAL:GET_USER:FAIL';

/* QrCode fetch */
export const D2M_QR_CODE_FETCH_REQUEST = 'D2M:QR:CODE:FETCH:REQUEST';
export const D2M_QR_CODE_FETCH_SUCCESS = 'D2M:QR:CODE:FETCH:SUCCESS';
export const D2M_QR_CODE_FETCH_FAIL = 'D2M:QR:CODE:FETCH:FAIL';

/* Case Acceptance */
export const CASE_ACCEPTANCE_SUBMIT_SUCCESS = 'CASE_ACCEPTANCE:SUBMIT:SUCCESS';
export const CASE_ACCEPTANCE_SUBMIT_FAIL = 'CASE_ACCEPTANCE:SUBMIT:FAIL';
export const TEENS_CASE_ACCEPTANCE_SUBMIT_SUCCESS = 'TEENS:CASE_ACCEPTANCE:SUBMIT:SUCCESS';
export const TEENS_CASE_ACCEPTANCE_SUBMIT_FAIL = 'TEENS:CASE_ACCEPTANCE:SUBMIT:FAIL';

/* Line Id Submit */
export const LIFF_SUBMIT_SUCCESS = 'LIFF_SUBMIT_SUCCESS';
export const LIFF_SUBMIT_FAIL = 'LIFF_SUBMIT_FAIL';
