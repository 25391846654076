import React from 'react';

import PropTypes from 'prop-types';

import { remindMeScreenTranslations } from '../../translation/commonTranslations';
import Select from '../select/select';

import MONTH_LIST from './months';

import './styles.scss';

const Datepicker = (props) => {
  const {
    className,
    dmy,
    errorDob,
    type,
    onListItemClick,
    selectedDate
  } = props;

  const { year, date, month } = selectedDate;
  const isOutlined = type === 'outlined';
  const today = new Date();
  const currentYear = today.getFullYear();
  const yearsList = Array.from(Array(100), (x, index) => ({
    id: index,
    itemName: currentYear - index
  }));

  const numberOfDates = new Date(
    year && Object.keys(year).length !== 0 && year.itemName,
    month && Object.keys(month).length !== 0 && month.id,
    0
  ).getDate();

  const formatSingleDigits = (digit) => {
    const number = digit && digit.toString();
    if (digit) {
      return number.padStart(2, 0);
    }
    return number;
  };

  const imagePath = isOutlined ? 'drop-down-arrow-thin.svg' : 'drop-down-arrow.png';
  const dateList = Array.from(Array(numberOfDates), (x, index) => ({
    id: index,
    itemName: formatSingleDigits(index + 1)
  }));

  return (
    <div className={`date-picker-wrapper ${type} ${className}`}>
      <div
        className={`${isOutlined && 'date-of-birth'} ${
          errorDob && 'error-select'
        }`}
      >
        <Select
          selectOptions={dmy ? dateList : yearsList}
          defaultValue={
            dmy
              ? remindMeScreenTranslations.date
              : remindMeScreenTranslations.year
          }
          onListItemClick={(item) => onListItemClick(item, dmy ? 'date' : 'year')}
          selectedItem={dmy ? date : year}
          isSelectNewUI={isOutlined}
          imageSrcDownChevron={`${process.env.PUBLIC_URL}/images/${imagePath}`}
        />
      </div>
      <div
        className={`${isOutlined && 'date-of-birth'} ${
          errorDob && 'error-select'
        }`}
        style={{ width: `${!isOutlined && '30%'}` }}
      >
        <Select
          selectOptions={MONTH_LIST}
          defaultValue={remindMeScreenTranslations.month}
          onListItemClick={(item) => onListItemClick(item, 'month')}
          selectedItem={month}
          isSelectNewUI={isOutlined}
          imageSrcDownChevron={`${process.env.PUBLIC_URL}/images/${imagePath}`}
        />
      </div>
      <div
        className={`${isOutlined && 'date-of-birth'} ${
          errorDob && 'error-select'
        }`}
      >
        <Select
          selectOptions={dmy ? yearsList : dateList}
          defaultValue={
            dmy
              ? remindMeScreenTranslations.year
              : remindMeScreenTranslations.date
          }
          onListItemClick={(item) => onListItemClick(item, dmy ? 'year' : 'date')}
          selectedItem={dmy ? year : date}
          isSelectNewUI={isOutlined}
          imageSrcDownChevron={`${process.env.PUBLIC_URL}/images/${imagePath}`}
        />
      </div>
    </div>
  );
};

Datepicker.propTypes = {
  className: PropTypes.string,
  dmy: PropTypes.bool,
  errorDob: PropTypes.string,
  type: PropTypes.string,
  onListItemClick: PropTypes.func.isRequired,
  selectedDate: PropTypes.shape({
    year: PropTypes.shape({
      itemName: PropTypes.number
    }),
    month: PropTypes.shape({
      id: PropTypes.string
    }),
    date: PropTypes.shape({})
  })
};

Datepicker.defaultProps = {
  className: '',
  dmy: false,
  errorDob: '',
  type: '',
  selectedDate: {
    date: {},
    month: {},
    year: {}
  }
};

export default Datepicker;
