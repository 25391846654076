/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


const installGTM = () => {
  const gtmId = process.env.REACT_APP_GTM_ID;
  if (!gtmId) return;
  const tagManagerArgs = {
    gtmId
  };

  TagManager.initialize(tagManagerArgs);
  // eslint-disable-next-line no-console
  console.log('GTM initialized');
};

installGTM();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations()
    .then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
      if ('caches' in window) {
        caches.keys()
          .then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
      }
    });
}

serviceWorker.unregister();
