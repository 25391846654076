import React from 'react';

import PropTypes from 'prop-types';

import { translationMapper } from '../../translation/commonTranslations';
import './styles.scss';

const Option = (props) => {
  const {
    class: className,
    count,
    fullWidth,
    image,
    isTranslatable,
    label,
    popUpBooth,
    selected,
    styles
  } = props;

  return (
    <div
      className={`option-btn-wrapper ${className}
      ${popUpBooth && (count % 2 !== 0 ? 'left-option' : 'right-option')} 
       ${
         popUpBooth
         && (fullWidth ? 'pop-up-option-full-width' : 'pop-up-option')
       }
        ${selected && 'selectedOption'}`}
      style={styles}
    >
      {image && (
        <img
          src={`${process.env.PUBLIC_URL}/${image}`}
          alt="option"
          className="option-img"
        />
      )}
      {isTranslatable ? translationMapper(label) : label}
    </div>
  );
};

Option.propTypes = {
  class: PropTypes.string,
  count: PropTypes.number,
  fullWidth: PropTypes.bool,
  image: PropTypes.string,
  isTranslatable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  popUpBooth: PropTypes.bool,
  selected: PropTypes.bool,
  styles: PropTypes.shape()
};

Option.defaultProps = {
  class: '',
  count: 0,
  fullWidth: false,
  image: '',
  isTranslatable: true,
  popUpBooth: false,
  selected: false,
  styles: {}
};

export default Option;
