import React from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';


import './styles.scss';
import { translationMapper } from '../../translation/commonTranslations';


const StepCarousel = (props) => {
  const { countryCode, selected, instructionArray, instructionBoxStyle, countRequired, baseImagePath } = props;
  return (
    <div>
      <Carousel
        selectedItem={selected}
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        onSwipeMove={false}
        width={350}
        className="wrapper"
      >
        {instructionArray.map((step, index) => (
          <div key={`${step.instruction}`} className="instructionBox" style={instructionBoxStyle}>
            <img
              id="instructionImage"
              alt="img"
              src={`${baseImagePath}${step.image.path[countryCode]}`}
            />
            <div className="textWrapper">
              {countRequired && <div className="textWrapper-title">{translationMapper(index + 1)}</div>}
              <div>
                {step.instruction}
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

StepCarousel.propTypes = {
  countryCode: PropTypes.string,
  selected: PropTypes.number.isRequired,
  instructionArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  instructionBoxStyle: PropTypes.shape(),
  countRequired: PropTypes.bool,
  baseImagePath: PropTypes.string.isRequired
};

StepCarousel.defaultProps = {
  countryCode: 'sg',
  instructionBoxStyle: {},
  countRequired: true
};

export default StepCarousel;
