import registry from 'app-registry';
import Immutable from 'seamless-immutable';

import {
  APP_EMAIL_TEENS_SET,
  SMILE_QUESTIONS_FORM_DETAILS_UPDATE,
  SMILEQUESTIONS_RESET,
  SMILEQUESTIONS_SET_AGE_CATEGORY,
  SMILEQUESTIONS_SET_CALL_TIME,
  SMILEQUESTIONS_SET_CONCERNS,
  SMILEQUESTIONS_SET_DATE_OF_BIRTH,
  SMILEQUESTIONS_SET_PARENT_PHONE_NO,
  SMILEQUESTIONS_SET_TEEN_EMAIL,
  SMILEQUESTIONS_SET_TEETH_CONDITION,
  SMILEQUESTIONS_SET_TREATMENT_GOALS,
  SMILEQUESTIONS_SET_YOUTUBE_ANSWER,
  SMILEQUESTIONS_SUBMIT_FAIL,
  SMILEQUESTIONS_SUBMIT_REQUEST,
  SMILEQUESTIONS_SUBMIT_SUCCESS,
  TEENS_EMAIL_FETCH_SUCCESS,
  TEENS_CASE_ACCEPTANCE_SUBMIT_SUCCESS
} from '../../actions';
import storageUtils from '../../services/storage';

const defaultState = Immutable({
  ageCategory: '',
  callTime: '',
  concerns: [],
  dateofBirth: {
    date: null,
    month: null,
    year: null
  },
  parentPhoneNo: {},
  guardianPhoneNumber: {
    number: '',
    country: ''
  },
  guardianEmail: '',
  pending: 0,
  teensEmail: '',
  teethCondition: [],
  treatmentGoals: '',
  youtubeAnswer: ''
});

export default (state = defaultState, action) => {
  let newState;
  switch (action.type) {
    case SMILEQUESTIONS_RESET: {
      registry
        .get('storage')
        .setStorage(
          'obt-zenyum-form2',
          JSON.stringify(defaultState)
        );
      return defaultState; }

    case APP_EMAIL_TEENS_SET: {
      return Immutable.merge(
        state,
        { teensEmail: action.email }
      );
    }
    case SMILEQUESTIONS_SET_AGE_CATEGORY:
      return Immutable.merge(state, { ageCategory: action.data });
    case SMILEQUESTIONS_SET_CALL_TIME:
      return Immutable.merge(state, { callTime: action.data });
    case SMILEQUESTIONS_SET_YOUTUBE_ANSWER:
      return Immutable.merge(state, { youtubeAnswer: action.data });
    case SMILEQUESTIONS_SET_TEETH_CONDITION:
      return Immutable.merge(state, { teethCondition: action.data });
    case SMILEQUESTIONS_SET_CONCERNS:
      return Immutable.merge(state, { concerns: action.data });
    case SMILEQUESTIONS_SET_TREATMENT_GOALS:
      return Immutable.merge(state, { treatmentGoals: action.data });
    case SMILEQUESTIONS_SUBMIT_REQUEST:
      return Immutable.merge(state, { pending: state.pending + 1 });
    case SMILEQUESTIONS_SUBMIT_SUCCESS:
      return Immutable.merge(state, { pending: state.pending - 1 });
    case SMILEQUESTIONS_SUBMIT_FAIL:
      return Immutable.merge(state, { pending: state.pending - 1 });
    case SMILEQUESTIONS_SET_PARENT_PHONE_NO:
      return Immutable.merge(state, { parentPhoneNo: action.data });
    case SMILEQUESTIONS_SET_DATE_OF_BIRTH:
      return Immutable.merge(state, { dateofBirth: { ...state.dateofBirth, [action.name]: action.value } });
    case SMILEQUESTIONS_SET_TEEN_EMAIL:
      return Immutable.merge(state, { teensEmail: action.data });
    case SMILE_QUESTIONS_FORM_DETAILS_UPDATE:
      return Immutable.merge(state, { [action.name]: action.value });
    case TEENS_EMAIL_FETCH_SUCCESS:
      newState = Immutable.merge(state, { teensEmail: action.data.data });
      storageUtils.setStorage('obt-zenyum-form2',
        JSON.stringify({ teensEmail: newState.teensEmail }));
      return newState;
    case TEENS_CASE_ACCEPTANCE_SUBMIT_SUCCESS:
      return Immutable.merge(
        state, {
          parentPhoneNo: {
            number: action.data.data?.userDetails?.phone,
            dialCode: action.data.data?.userDetails?.isdCode
          }
        }
      );
    case 'APP:INIT': {
      const data = action.payload.form2 ? { ...action.payload.form2 } : {};
      return Immutable.merge(
        state,
        { ...data }
      );
    }
    default:
      return state;
  }
};
