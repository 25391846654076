import { connect } from 'react-redux';
import Notifier from './notifier';

import { APP_NOTIFIER_NOTIFICATION_HIDE } from '../../actions/appActions';

function mapStateToProps(state) {
  return {
    notification: state.notifierReducer.notification
  };
}
const mapDispatchToProps = (dispatch) => ({
  clearNotifier: () => {
    dispatch({ type: APP_NOTIFIER_NOTIFICATION_HIDE });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);
