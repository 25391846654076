import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const DotLoader = (props) => {
  const { isLoading } = props;
  if (isLoading) {
    return (
      <div className="dot-loader-div">
        <div className="dot-loader">
          <span className="dot" />
          <div className="dots">
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

DotLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

DotLoader.defaultProps = {};

export default DotLoader;
