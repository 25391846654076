import React from 'react';

import PropTypes from 'prop-types';

import { translationMapper } from '../../translation/commonTranslations';

import './styles.scss';

const PillOption = (props) => {
  const { popUpBooth, count, onClick, image, selected, isTranslatable, label } = props;

  return (
    <div
      className={`pill-wrapper
    ${popUpBooth && (count % 2 !== 0 ? 'left-option' : 'right-option')} 
     ${popUpBooth && 'pop-up-booth-option'} option-btn-wrapper ${
        selected && 'selectedOptionPill'
      }`}
      onClick={onClick}
      role="presentation"
    >
      {image && (
        <img
          src={`${process.env.PUBLIC_URL}/${image}`}
          alt="option"
          className="option-img-pill"
        />
      )}
      {isTranslatable ? translationMapper(label) : label}
    </div>
  );
};

PillOption.propTypes = {
  count: PropTypes.number,
  image: PropTypes.string,
  isTranslatable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  popUpBooth: PropTypes.bool,
  selected: PropTypes.bool
};

PillOption.defaultProps = {
  count: 0,
  image: '',
  isTranslatable: true,
  onClick: () => {},
  popUpBooth: false,
  selected: false
};

export default PillOption;
