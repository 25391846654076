/* eslint-disable no-param-reassign */
import { updateIntl } from 'react-intl-redux';

import { store } from '../store';
import { addLocaleData, getLocaleMessages } from './index';
import { showNotification } from '../utils';

import storage from '../services/storage';
import * as ACTIONS from './actions';

export default function fetchTranslation(countryCode, locale) {
  store.dispatch({ type: ACTIONS.TRANSLATION_FETCH });
  try {
    addLocaleData(locale);
    store.dispatch(
      updateIntl({
        locale,
        messages: getLocaleMessages(countryCode, locale)
      })
    );

    store.dispatch(
      {
        type: ACTIONS.TRANSLATION_FETCH_SUCCESS,
        locale,
        newMetaData: { utmData: (JSON.parse(storage.getStorage('obt-zenyum-metaData')))?.utmData || {},
          facebookAdData: (JSON.parse(storage.getStorage('obt-zenyum-metaData')))?.facebookAdData || {},
          gclid: (JSON.parse(storage.getStorage('obt-zenyum-metaData')))?.gclid || '' }
      }
    );
  } catch (err) {
    showNotification(
      'Failed to translations',
      'error',
      'OBT',
      5000
    );
    store.dispatch({
      type: ACTIONS.TRANSLATION_FETCH_FAIL
    });
  }
}
