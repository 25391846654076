import Immutable from 'seamless-immutable';
import * as actions from '../../actions/appActions';

const defaultState = Immutable({
  notification: {
    title: null,
    message: null,
    type: null,
    sleepTime: null
  }
});

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.APP_NOTIFIER_NOTIFICATION_SHOW: {
      return Immutable.set({ notification: action.notification });
    }
    case actions.APP_NOTIFIER_NOTIFICATION_HIDE:
      return defaultState;
    default:
      return state;
  }
};

export default reducer;
