import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { segmentPageCall } from '../../utils';
import Button from '../button/button';

import './styles.scss';

const Modal = (props) => {
  const { header, content, buttons, segmentData, image } = props;

  useEffect(() => {
    if (segmentData?.title) {
      segmentPageCall(segmentData);
    }
  }, []);

  return (
    <div className="modal-wrapper modal-card">
      <div className="modal-content-wrapper">
        <div className="modal-image">
          {image && <img src={`${image}`} alt="" />}
        </div>
        <div className="header-content-wrapper">
          <div className="modal-header">{header}</div>
          <div className="modal-content">{content}</div>
        </div>
        <div className="button-wrapper">
          {buttons
            && buttons.map((button) => (
              <Button
                key={button.text}
                className={button?.class}
                onClick={button?.function}
              >
                {button.text}
              </Button>
            ))}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape()),
  content: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  image: PropTypes.string,
  segmentData: PropTypes.shape()
};

Modal.defaultProps = {
  buttons: [],
  image: '',
  segmentData: {}
};

export default Modal;
