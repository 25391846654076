import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';

import { segmentEventTrack } from '../../utils';

const PopoverComponent = (props) => {
  const {
    align,
    children,
    containerClass,
    content,
    contentClass,
    positions,
    segmentData
  } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handlePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
    if (segmentData?.title) {
      segmentEventTrack(segmentData);
    }
  };
  return (
    <Popover
      isOpen={isPopoverOpen}
      onClickOutside={() => setIsPopoverOpen(!isPopoverOpen)}
      align={align}
      containerClassName={containerClass}
      content={
        <div className={contentClass}>
          {content.map((item) => (
            <div key={item.heading} className="heading-content">
              <div className="heading">{item?.heading}</div>
              <div className="content">{item?.content}</div>
            </div>
          ))}
        </div>
      }
      positions={positions}
    >
      <div onClick={handlePopover} role="presentation">
        {children}
      </div>
    </Popover>
  );
};

PopoverComponent.propTypes = {
  align: PropTypes.string,
  children: PropTypes.element.isRequired,
  containerClass: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  contentClass: PropTypes.string.isRequired,
  positions: PropTypes.arrayOf(PropTypes.string),
  segmentData: PropTypes.shape()
};

PopoverComponent.defaultProps = {
  align: 'center',
  positions: ['top'],
  segmentData: {}
};

export default PopoverComponent;
