import registry from 'app-registry';
import Immutable from 'seamless-immutable';

import * as actions from '../../actions';

const defaultState = Immutable({
  photos: {
    photo1: null,
    photo2: null,
    photo3: null,
    photo4: null
  },
  photoData: {
    photo1: {
      trialNo: 0,
      passed: false
    },
    photo2: {
      trialNo: 0,
      passed: false
    },
    photo3: {
      trialNo: 0,
      passed: false
    },
    photo4: {
      trialNo: 0,
      passed: false
    }
  },
  retakeImageIndex: 0,
  pending: 0
});

export default (state = defaultState, action) => {
  let newState;
  switch (action.type) {
    case actions.APP_INIT: {
      const updatedPhotoData = {};
      const storagePhotoData = action.payload.form3?.photoData || {};
      Object.keys(storagePhotoData).forEach((photo) => {
        updatedPhotoData[photo] = {
          passed: storagePhotoData[photo].passed,
          trialNo: 0
        };
      });
      const data = action.payload.form3
        ? {
          photos: { ...action.payload.form3.photos },
          photoData: updatedPhotoData,
          version: action.payload.form3.version
        }
        : {};

      newState = Immutable.merge(state, { ...data });

      registry
        .get('storage')
        .setStorage('obt-zenyum-form3', JSON.stringify(newState));

      return newState;
    }

    case actions.CAMERA_VERSION_INITIALIZATION: {
      return Immutable.merge(state, { version: action.payload });
    }

    case actions.CAMERA_SCREEN_PHOTO_SAVE_SUCCESS: {
      const { data, extraPayload } = action || {};
      const { s3Urls = {}, msdPhaseTwoResponse = {} } = data?.data || {};
      const photoData = Object.values(s3Urls)[0];
      const index = extraPayload?.imageIndex;
      const trialNo = state.photoData[`photo${index + 1}`]?.trialNo || 0;

      const accepted = photoData.message !== 'Missing teeth'
        && (photoData.status !== 'Failed' || trialNo + 1 >= 2);
      const updatedPhotos = accepted
        ? { ...state.photos, [`photo${index + 1}`]: Object.values(s3Urls)[0] }
        : { ...state.photos };
      const updatedPhotoData = {
        ...state.photoData,
        [`photo${index + 1}`]: {
          trialNo:
            photoData.message !== 'Missing teeth' ? trialNo + 1 : trialNo,
          passed:
            photoData.status !== 'Failed'
            && photoData.message !== 'MSD Phase 1 API Failed'
        }
      };

      newState = Immutable.merge(state, {
        photos: updatedPhotos,
        pending: state.pending - 1,
        photoData: updatedPhotoData,
        ...(index === 0 && { zenPlusResult: msdPhaseTwoResponse.zenplus })
      });

      registry
        .get('storage')
        .setStorage('obt-zenyum-form3', JSON.stringify(newState));

      return newState;
    }

    case actions.CAMERA_SCREEN_PHOTO_SAVE_FAIL:
      return Immutable.merge(state, { pending: state.pending - 1 });

    case actions.CAMERA_SCREEN_PHOTO_SAVE_REQUEST:
      return Immutable.merge(state, { pending: state.pending + 1 });

    case actions.CAMERA_SCREEN_PHOTOS_RESET: {
      newState = Immutable.merge(state, {
        photos: defaultState.photos,
        pending: 0,
        photoData: defaultState.photoData,
        ...(action.value && action.value)
      });

      registry
        .get('storage')
        .setStorage('obt-zenyum-form3', JSON.stringify(newState));

      return newState;
    }

    case actions.CAMERA_SCREEN_RETAKE_PHOTO: {
      const updatedPhotos = {
        ...state.photos,
        [`photo${action.imageIndex}`]: null
      };
      const updatedPhotoData = {};
      Object.keys(state.photoData).forEach((photo) => {
        updatedPhotoData[photo] = {
          passed:
            photo === `photo${action.imageIndex}`
              ? false
              : state.photoData[photo].passed,
          trialNo: 0
        };
      });

      newState = Immutable.merge(state, {
        photos: updatedPhotos,
        photoData: updatedPhotoData,
        retakeImageIndex: action.imageIndex
      });

      registry
        .get('storage')
        .setStorage('obt-zenyum-form3', JSON.stringify(newState));

      return newState;
    }

    case actions.USER_FORM_SCREEN_PHOTO_SAVE_REQUEST:
      return Immutable.merge(state, { pending: state.pending + 1 });

    case actions.USER_FORM_SCREEN_PHOTO_SAVE_SUCCESS:
      return Immutable.merge(state, { pending: state.pending - 1 });

    case actions.USER_FORM_SCREEN_PHOTO_SAVE_FAIL:
      return Immutable.merge(state, { pending: state.pending - 1 });

    default:
      return state;
  }
};
