import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const UploadProgressBar = (props) => {
  const { uploadPercentage } = props;

  return (
    <div className="upload-progress-bar-wrapper">
      <div className="parent-bar">
        <div
          className="child-bar"
          style={{ width: `${uploadPercentage * 100}%` }}
        />
      </div>
    </div>
  );
};

UploadProgressBar.propTypes = {
  uploadPercentage: PropTypes.number.isRequired
};

export default UploadProgressBar;
