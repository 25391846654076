/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';

import SomethingWentWrongPage from './something-went-wrong-page';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <SomethingWentWrongPage />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
