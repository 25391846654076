import React from 'react';
import registry from 'app-registry';
import PropTypes from 'prop-types';

import { commonTranslations, translationMapperFooter } from '../../translation/commonTranslations';
import { CountryConfig } from '../main-layout/config';

import './styles.scss';

const getLayoutClass = (type) => {
  switch (type) {
    case 'primary': return 'plain-layout-primary';
    case 'secondary': return 'plain-layout-secondary';
    default: return 'plain-layout-default';
  }
};

const DualSectionLayout = (props) => {
  const obtMetaData = JSON.parse(registry.get('storage').getStorage('obt-zenyum-metaData'));
  const { countryCode, languageCode } = obtMetaData;
  return (
    <div className={`${getLayoutClass(props.type)} plain-layout`} lang={languageCode}>
      {
        props.header !== 'none'
        && (
          <div className="plain-layout-header">
            <img
              className="plain-layout-logo"
              src={`${process.env.PUBLIC_URL}/${CountryConfig.zenyumWhiteLogo[countryCode]}`}
              alt="logo"
              width={countryCode === 'tw' ? '155' : '125'}
            />
          </div>
        )
      }
      <div className={`plain-layout-content ${props.fullWidth && 'fullScreen'}`}>
        {props.children}
      </div>
      {props.footer !== 'none' && (
        <div className="plain-layout-footer">
          <div id="label">
            <div>
              © {translationMapperFooter(countryCode)}
            </div>
            <div>
              <span>
                <a
                  href={`https://www.zenyum.com/${countryCode}-${languageCode}/website-terms-and-conditions/`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="mail-text"
                >
                  {commonTranslations.termsAndConditions}
                </a>
              </span>
              <span> | </span>
              <span>
                <a
                  href={`https://www.zenyum.com/${countryCode}-${languageCode}/privacy/`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="mail-text"
                >
                  {commonTranslations.privacyPolicy}
                </a>
              </span>
            </div>
          </div>
        </div>)}
    </div>
  );
};

DualSectionLayout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  header: PropTypes.string,
  footer: PropTypes.string,
  fullWidth: PropTypes.bool
};

DualSectionLayout.defaultProps = {
  type: '',
  header: '',
  footer: '',
  fullWidth: false
};

export default DualSectionLayout;
