import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const Slider = (props) => {
  const {
    containerClass,
    content,
    defaultLabelColor,
    defaultUnderLineColor,
    isSliderClickable,
    labelClass,
    selectedLabelColor,
    selectedLabelIndex,
    selectedUnderLineColor,
    sliderClickHandler,
    underLineClass,
    wrapperClass
  } = props;

  const handleSliderClick = (index) => {
    if (isSliderClickable) {
      sliderClickHandler(index);
    }
  };

  return (
    <div className={`slider-container ${containerClass}`}>
      {content && content.map((data, index) => (
        <div
          key={`${data}${index + 1}`}
          className={`slider-section-wrapper ${wrapperClass} ${data.class && 'active-page-cursor'}`}
          onClick={() => handleSliderClick(index)}
          role="presentation"
        >
          <div
            style={{ color: (selectedLabelIndex === index ? selectedLabelColor : defaultLabelColor) }}
            className={`slider-label ${labelClass} ${data.class && 'active-page-color'}`}
          >
            {data.label}
          </div>
          <div
            className={`slider-underline ${underLineClass} ${data.class && 'active-page-background'}`}
            style={{ backgroundColor: selectedLabelIndex === index ? selectedUnderLineColor : defaultUnderLineColor }}
          />
        </div>
      ))}
    </div>
  );
};

Slider.propTypes = {
  containerClass: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.any).isRequired,
  defaultLabelColor: PropTypes.string,
  defaultUnderLineColor: PropTypes.string,
  isSliderClickable: PropTypes.bool,
  labelClass: PropTypes.string,
  selectedLabelColor: PropTypes.string,
  selectedLabelIndex: PropTypes.number,
  selectedUnderLineColor: PropTypes.string,
  sliderClickHandler: PropTypes.func,
  underLineClass: PropTypes.string,
  wrapperClass: PropTypes.string
};

Slider.defaultProps = {
  containerClass: '',
  defaultLabelColor: '#707070',
  defaultUnderLineColor: '#707070',
  labelClass: '',
  isSliderClickable: true,
  selectedLabelColor: '#FFFFFF',
  selectedLabelIndex: 0,
  selectedUnderLineColor: '#FFFFFF',
  sliderClickHandler: () => {},
  underLineClass: '',
  wrapperClass: ''
};

export default Slider;
