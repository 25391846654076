const ERROR_CONFIG = {
  error404RedirectionPath: {
    new: {
      OBT1: '/hello-form',
      OBT2: '/hello-form',
      OBT3: '/hello-form',
      OBT4: '/launch',
      OBTAI: '/hello-form'
    },
    old: {
      OBT1: '/launch',
      OBT2: '/launch',
      OBT3: '/launch',
      OBT4: '/launch',
      OBTAI: '/launch'
    }
  },
  photoErrorRedirectionPath: {
    new: {
      OBT1: '/photo-capture',
      OBT2: '/photo-capture',
      OBT3: '/photo-capture',
      OBT4: '/cameraD2M',
      OBTAI: '/photo-capture'
    },
    old: {
      OBT1: '/camera',
      OBT2: '/camera',
      OBT3: '/camera',
      OBT4: '/cameraD2M',
      OBTAI: '/photo-capture'
    }
  }
};

export default ERROR_CONFIG;
