import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './styles.scss';

const CheckboxInput = (props) => {
  const { checked, intl, label, name, onBlur, onChange, setHtmlDangerously } = props;
  const id = `${name}-${Math.floor(Math.random() * 1000)}`;
  const { formatMessage } = intl;

  const createMarkup = () => ({ __html: formatMessage(label.props) });

  return (
    <div className="checkbox-root">
      <label htmlFor={id} className="checkBox">
        <input
          name={name}
          onChange={(event) => onChange(event.target.checked)}
          id={id}
          checked={checked}
          type="checkbox"
          onBlur={onBlur}
        />
        <span />
        {setHtmlDangerously ? (
          <div
            className="check-link-txt"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={createMarkup()}
          />
        ) : (
          <div className="check-txt">{formatMessage(label.props)}</div>
        )}
      </label>
    </div>
  );
};

CheckboxInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  formatMessage: PropTypes.func,
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  setHtmlDangerously: PropTypes.bool
};

CheckboxInput.defaultProps = {
  formatMessage: () => {},
  label: '',
  name: 'default',
  onBlur: () => {},
  setHtmlDangerously: false
};

export default injectIntl(CheckboxInput);
