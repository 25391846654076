import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { flagOptions } from '../../constants';
import { isValidPhoneNumber } from '../../utils';
import Input from '../input/input';
import Select from '../select/select';

import './styles.scss';

const PhoneInput = (props) => {
  const {
    countryCode,
    defaultValue,
    dialCode,
    errorBackgroundColorChange,
    handleBlur,
    handlePhoneNumberChange,
    isFlagSelect,
    name,
    placeholder,
    selectedItem,
    styleVariant,
    value
  } = props;

  const imagePath = isFlagSelect || styleVariant ? 'drop-down-arrow-thin.svg' : 'drop-down-arrow.png';
  const isSelectNewUI = styleVariant === 'variant';

  const onSelectChange = (option) => {
    handlePhoneNumberChange(
      value,
      {
        countryCode: option?.countryCode,
        dialCode: option?.dialCode
      },
      name
    );
  };

  const onInputChange = (e) => {
    const num = e.target.value;
    if (num === '' || isValidPhoneNumber(num)) {
      handlePhoneNumberChange(num, { countryCode, dialCode }, name);
    } // Not allow spaces or decimals or non-numerical characters
  };

  return (
    <>
      <div className={`phone-number ${styleVariant}`}>
        <div
          className={`flag-box ${styleVariant} ${
            errorBackgroundColorChange && 'error-select'
          }`}
        >
          <Select
            selectOptions={flagOptions}
            defaultValue={defaultValue}
            onListItemClick={onSelectChange}
            countryCode={countryCode}
            isFlagSelect={isFlagSelect}
            selectedItem={selectedItem}
            errorBackgroundColorChange={errorBackgroundColorChange}
            isSelectNewUI={isSelectNewUI}
            imageSrcDownChevron={`${process.env.PUBLIC_URL}/images/${imagePath}`}
          />
        </div>
        <div className="phone-input">
          <Input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={onInputChange}
            onBlur={() => handleBlur(name)}
            errorBackgroundColorChange={errorBackgroundColorChange}
          />
        </div>
      </div>
    </>
  );
};

PhoneInput.propTypes = {
  countryCode: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  dialCode: PropTypes.string.isRequired,
  errorBackgroundColorChange: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handlePhoneNumberChange: PropTypes.func.isRequired,
  isFlagSelect: PropTypes.bool.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.objectOf(PropTypes.any),
  selectedItem: PropTypes.shape({
    itemName: PropTypes.any
  }).isRequired,
  styleVariant: PropTypes.string,
  value: PropTypes.string.isRequired
};

PhoneInput.defaultProps = {
  errorBackgroundColorChange: false,
  name: 'phone',
  placeholder: '',
  styleVariant: 'base'
};

export default injectIntl(PhoneInput);
