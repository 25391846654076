import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const PlainHeader = (props) => {
  const { src } = props;
  return (
    <div className="page-header">
      <img
        className="page-header-logo"
        src={src}
        alt="logo"
      />
    </div>
  );
};

PlainHeader.propTypes = {
  src: PropTypes.string
};

PlainHeader.defaultProps = {
  src: ''
};

export default PlainHeader;
