import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './styles.scss';

const Input = ({
  intl,
  placeholder,
  type,
  errorBackgroundColorChange,
  onChange,
  onBlur,
  min,
  max,
  label,
  value
}) => {
  const { formatMessage } = intl;

  return (
    <input
      label={label}
      placeholder={formatMessage(placeholder.props)}
      className={`input input-${type} ${
        errorBackgroundColorChange && 'error-input'
      }`}
      type={type}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      max={type === 'number' && max}
      min={type === 'number' && min}
    />
  );
};

Input.propTypes = {
  errorBackgroundColorChange: PropTypes.bool,
  formatMessage: PropTypes.func,
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  value: PropTypes.string
};

Input.defaultProps = {
  errorBackgroundColorChange: false,
  formatMessage: () => {},
  label: '',
  max: 3000,
  min: 0,
  onBlur: () => {},
  onChange: () => {},
  placeholder: '',
  type: 'text',
  value: ''
};

export default injectIntl(Input);
