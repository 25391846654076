import React from 'react';

import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './styles.scss';

const TacticalBanner = (props) => {
  const { imageArray } = props;
  return (
    <div className="carousal-container">
      <Carousel
        infiniteLoop={imageArray?.length > 1}
        autoPlay={true}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        swipeable={true}
        selectedItem={0}
        showIndicators={(imageArray?.length > 1)}
      >
        {imageArray.map((imageUrl) => (
          <img
            key={imageUrl}
            src={imageUrl}
            alt="banner"
          />
        ))}
      </Carousel>
    </div>
  );
};

TacticalBanner.propTypes = {
  imageArray: PropTypes.arrayOf(PropTypes.string)
};

TacticalBanner.defaultProps = {
  imageArray: []
};

export default TacticalBanner;
